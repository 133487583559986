<template>
  <warning-modal
    name="skin-sensitive-warning-modal"
    class="skin-sensitive-warning-modal"
    title="dashboard.microneedling.warning.notice"
    @confirm="onConfirm"
  >
    <template #main-content>
      <div class="content">
        <i18n tag="span" path="dashboard.modal.sensitiveSkin.mainText">
          <b>
            {{ $t('dashboard.modal.verySensitiveSkin') }}
          </b>
        </i18n>
      </div>
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';

export default {
  name: 'SkinSensitiveWarningModal',
  components: { WarningModal },
  methods: {
    onConfirm() {
      this.$modal.hide('skin-sensitive-warning-modal');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.content {
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  color: $text-color;

  &__bold {
    font-weight: 600;
  }
}
</style>
