<template>
  <modal
    name="ingredient-info-modal"
    class="ingredient-info-modal"
    :classes="['modal-window']"
    scrollable
    transition="scale"
    :width="modalWidth"
    height="auto"
    @before-open="beforeOpen"
  >
    <section v-if="ingredientName" class="modal-content flex-column--align-center small-modal-text">
      <div class="modal-content-header flex--space-between--center">
        <div class="modal-title modal-content-title">
          {{ $t(`dashboard.ingredients.${ingredientName}`) }}
        </div>
        <div class="icon-close custom-icon" @click="closeModal"></div>
      </div>
      <div class="ingredient-info-modal__content ie-text-modal full-width">
        <div class="modal-content-description full-width flex-column">
          <template v-if="hasRecommendations">
            <div class="modal-content-description__title">
              {{ ingredientRecommendationTitle }}
            </div>
            <div class="modal-content-description__text">
              {{ ingredientRecommendationDescription }}
            </div>
          </template>
          <div v-else class="modal-content-description__text">
            {{ ingredientTextDescription }}
          </div>
        </div>
        <div v-if="primaryAction" class="primary-color-action flex-column">
          <div class="primary-color-action__title">
            {{ $t('dashboard.label.primaryColorAction') }}
          </div>
          <div class="primary-color-action__content flex">
            <div class="primary-color-action__color" :class="primaryAction.color"></div>
            <div class="primary-color-action__name">
              {{ $t(`dashboard.diagnostic.${primaryAction.name}`) }}
            </div>
          </div>
        </div>
        <div v-if="secondaryActions" class="secondary-color-action">
          <div class="secondary-color-action__title">
            {{ $t('dashboard.label.secondaryColorsAction') }}
          </div>
          <div class="secondary-color-action__content">
            <ul class="secondary-color-action__list">
              <li
                v-for="action in secondaryActions"
                :key="action.name"
                class="secondary-color-action__list-item flex"
              >
                <div
                  class="secondary-color-action__color"
                  :class="[`secondary-color-action__color_${action.blockSize}`, action.color]"
                ></div>
                <div class="secondary-color-action__name">
                  {{ $t(`dashboard.diagnostic.${action.name}`) }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="complementaryInformation" class="complementary-information">
          <div class="complementary-information__title">
            {{ $t('dashboard.label.complementaryInformation') }}
          </div>
          <div class="complementary-information__content">
            <ul class="complementary-information__list">
              <li
                v-for="active in complementaryInformation"
                :key="active"
                class="complementary-information__list-item"
              >
                • {{ $t(`dashboard.actives.${active}`) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, isEmpty, first, tail } from 'lodash';

import IngredientDescription from '@/modules/dashboard/components/create-treatment-tab/IngredientDescription';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { TREATMENT_SECTION_NAME } from '@/modules/dashboard/api/constants';

export default {
  name: 'IngredientInfoModal',
  data() {
    return {
      sectionName: null,
      ingredientName: null,
      ingredientDescription: null,
      modalWidth: null
    };
  },
  computed: {
    ...mapGetters({
      treatFaceRecommendation: dashTypes.getters.TREAT_FACE_RECOMMENDATION,
      treatEyesRecommendation: dashTypes.getters.TREAT_EYES_RECOMMENDATION
    }),
    recommendationBySection() {
      return this.sectionName === TREATMENT_SECTION_NAME.TREAT_FACE
        ? this.treatFaceRecommendation
        : this.treatEyesRecommendation;
    },
    ingredientRecommendation() {
      return get(this.recommendationBySection, `activesSelection.${this.ingredientName}.details`);
    },
    hasRecommendations() {
      return !isEmpty(this.ingredientRecommendation);
    },
    ingredientRecommendationTitle() {
      return first(this.ingredientRecommendation);
    },
    ingredientRecommendationDescription() {
      return tail(this.ingredientRecommendation).join(' ');
    },
    ingredientTextDescription() {
      return get(this.ingredientDescription, 'description');
    },
    primaryAction() {
      return get(this.ingredientDescription, 'actions.primaryAction');
    },
    secondaryActions() {
      return get(this.ingredientDescription, 'actions.secondaryActions');
    },
    complementaryInformation() {
      return get(this.ingredientDescription, 'complementaryInformation');
    }
  },
  created() {
    this.modalWidth = isMobileDevice() ? 300 : 560;
  },
  methods: {
    beforeOpen({ params: { sectionName, ingredientName } }) {
      this.sectionName = sectionName;
      this.ingredientName = ingredientName;
      this.ingredientDescription = new IngredientDescription(ingredientName, this.$t);
    },
    closeModal() {
      this.$modal.hide('ingredient-info-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/text';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.ingredient-info-modal {
  .modal-content {
    padding: 25px 20px;
  }

  .modal-content-header {
    width: 100%;
    font-size: 15px;
    line-height: 17px;
    color: $active-title-color;
    margin-bottom: 28px;
  }

  .modal-content-description {
    padding: 20px;
    border: 1px solid $dim-white;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 25px;

    &__title {
      font-size: 15px;
      line-height: 17px;
      color: $active-title-color;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 13px;
      line-height: 150%;
      color: $text-color;
    }
  }

  .primary-color-action,
  .secondary-color-action {
    width: 100%;
    margin-bottom: 20px;

    &__title {
      font-size: 13px;
      line-height: 15px;
      color: $active-title-color;
      margin-bottom: 15px;
    }

    &__content {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }

    &__color {
      width: 80px;
      height: 20px;
      border-radius: 3px;
      margin-right: 15px;

      &_big {
        width: 80px;
      }

      &_medium {
        width: 60px;
      }

      &_small {
        width: 40px;
      }

      &.pink-color {
        background-color: $pink-color;
      }

      &.pale-brown-color {
        background-color: $pale-brown-color;
      }

      &.anthracite-color {
        background-color: $anthracite-color;
      }

      &.purple-color {
        background-color: $purple-color;
      }

      &.mouse-gray {
        background-color: $mouse-gray;
      }

      &.blue-steel-color {
        background-color: $blue-steel-color;
      }

      &.chocolate-color {
        background-color: $chocolate-color;
      }

      &.camouflage-green-color {
        background-color: $camouflage-green-color;
      }
    }

    &__name {
      font-size: 15px;
      line-height: 17px;
      color: $text-color;
    }
  }

  .secondary-color-action {
    padding-bottom: 25px;
    border-bottom: 1px solid $dim-white;

    &__list {
      padding: 0;
      margin: 0;
    }

    &__list-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .complementary-information {
    width: 100%;

    &__title {
      font-size: 13px;
      line-height: 15px;
      color: $active-title-color;
      margin-bottom: 15px;
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__list-item {
      font-size: 15px;
      line-height: 17px;
      color: $text-color;
    }
  }
}

@media (max-width: 767px) {
  .ingredient-info-modal {
    .modal-content {
      padding-top: 17px;
    }

    &__content {
      max-height: 450px;
      overflow: auto;
    }

    .modal-content-title,
    .primary-color-action__name,
    .secondary-color-action__name,
    .complementary-information__list-item {
      font-size: 13px;
      line-height: 15px;
    }

    .complementary-information__title {
      font-size: 12px;
      margin-bottom: 12px;
    }

    .modal-content-header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 0 17px;
      margin-bottom: 20px;
      border-bottom: 1px solid $dim-white;

      .icon-close {
        margin-right: 20px;
      }
    }

    .modal-content-description {
      padding: 15px;
      margin-bottom: 20px;

      &__text {
        font-size: 12px;
      }
    }

    .primary-color-action__title,
    .secondary-color-action__title {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 12px;
    }
  }
}
</style>
