<template>
  <modal
    name="active-ingredients-mobile-selector-modal"
    :class="['active-selector-modal']"
    transition="scale"
    scrollable
    width="100%"
    height="100%"
    :pivot-y="0"
    @before-open="beforeOpen"
    @opened="opened"
  >
    <div class="active-selector modal-container flex-column--align-center">
      <div class="active-selector__header flex--align-center">
        <div class="custom-icon icon-close" @click="closeModal"></div>
        <span class="title-text ml15">{{ $t('dashboard.action.addAnIngredient') }}</span>
      </div>
      <div class="active-selector__ingredients-list ingredient-list">
        <template v-for="listElement in ingredientListOptions">
          <ingredients-group
            v-if="hasIngredientsWithSelection"
            :key="listElement.selection"
            class="ingredient-list__list-group"
            :group="listElement.selection"
            :grouped-ingredients="groupedIngredients"
            :disabled-ingredients="disabledIngredients"
            @show-ingredient-info="ingredientInfoModalShow"
            @select-ingredient="selectIngredient"
          />
          <div v-else :key="listElement.name" class="ingredient-list__list-element">
            <ingredient-list-element
              :ingredient="listElement"
              :disabled-ingredients="disabledIngredients"
              @show-ingredient-info="ingredientInfoModalShow"
              @click.native="selectIngredient(listElement)"
            />
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import IngredientsGroup from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientsGroup';
import IngredientListElement from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientListElement';

export default {
  name: 'ActiveIngredientsMobileSelectorModal',
  components: { IngredientListElement, IngredientsGroup },
  data() {
    return {
      sectionName: null,
      ingredientSelectResolver: null,
      ingredientListOptions: null,
      hasIngredientsWithSelection: false,
      groupedIngredients: null,
      disabledIngredients: [],
      activeIngredientsScrollbar: null
    };
  },
  methods: {
    beforeOpen({
      params: {
        sectionName,
        ingredientSelectResolver,
        ingredientListOptions,
        hasIngredientsWithSelection,
        groupedIngredients,
        disabledIngredients
      }
    }) {
      this.sectionName = sectionName;
      this.ingredientSelectResolver = ingredientSelectResolver;
      this.ingredientListOptions = ingredientListOptions;
      this.hasIngredientsWithSelection = hasIngredientsWithSelection;
      this.groupedIngredients = groupedIngredients;
      this.disabledIngredients = disabledIngredients;
    },
    opened() {
      this.activeIngredientsScrollbar = new PerfectScrollbar('.active-selector__ingredients-list', {
        suppressScrollX: true,
        wheelPropagation: false
      });
    },
    selectIngredient(ingredient) {
      const disabledIngredients = this.disabledIngredients || [];
      const isIngredientDisabled = !!disabledIngredients.find(
        ({ name }) => name === ingredient.name
      );

      if (isIngredientDisabled) {
        return;
      }

      this.ingredientSelectResolver(ingredient);
      this.hideModal();
    },
    ingredientInfoModalShow(ingredientName) {
      this.$modal.show('ingredient-info-modal', { sectionName: this.sectionName, ingredientName });
    },
    closeModal() {
      this.ingredientSelectResolver(null);
      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('active-ingredients-mobile-selector-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/text';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.active-selector {
  width: 100%;
  height: 100%;

  &__header {
    width: 100%;
    height: 46px;
    padding-left: 17px;

    background-color: $background;
  }

  &__input {
    width: 280px;
    height: 30px;

    padding: 0 15px;
    margin: 20px 0 15px;

    border-radius: 15px;
  }

  &__ingredients-list {
    overflow: hidden;
    position: relative;

    width: 100%;

    margin-top: 16px;
    padding: 0 20px;

    flex: 1;
  }
}

.ingredient-list {
  &__list-element {
    padding: 0 10px;
    margin-bottom: 12px;
  }

  &__list-group {
    margin-bottom: 16px;
  }
}
</style>
