<template>
  <div class="summary-treatment">
    <mobile-header class="summary-treatment__header">
      <span slot="header-title" class="summary-treatment__header-title">
        {{ $t('dashboard.label.createTreatmentTab') }}
      </span>
    </mobile-header>
    <summary-info class="onlyXS mb30" label-key="dashboard.label.newTreatmentFor" />
    <div class="summary-treatment__details flex-column">
      <div class="summary-treatment__details-container">
        <div class="summary-treatment__details-paid flex--align-center">
          <div class="checkbox">
            <input
              id="treatmentPaidCheckbox"
              v-model="isTreatmentPaid"
              name="treatmentPaidCheckbox"
              type="checkbox"
            />
            <label for="treatmentPaidCheckbox" class="checkbox--with-border p0"></label>
          </div>
          <span class="treatment-details__treatment-paid treatment-details__content-text">
            {{ $t('dashboard.label.treatmentPaid') }}
          </span>
          <span
            v-tooltip="{
              content: $t('dashboard.hint.treatmentPaymentInformation'),
              classes: 'treatment-detail-tooltip'
            }"
            class="info__icon custom-icon icon-info"
          ></span>
        </div>
        <loyalty-participant-select
          :selected-participant="currentParticipant"
          :disabled="isPastTreatmentDateSelected"
          :is-past-treatment="isPastTreatmentDateSelected"
          class="mb20"
          @select-participant="onParticipantSelect"
        />
        <div class="divider"></div>
        <div class="summary-treatment__details-date flex--align-center mt20">
          <div class="treatment-details__content-text">
            {{ $t('dashboard.createTreatment.treatmentStarts') }}
          </div>
          <span
            v-tooltip="{
              content: $t('dashboard.hint.treatmentStartDay'),
              classes: 'treatment-detail-tooltip'
            }"
            class="info__icon custom-icon icon-info"
          ></span>
          <div class="summary-treatment__pick-date flex--align-center">
            <div class="radio-container flex--align-center">
              <input
                id="todayDate"
                v-model="isCustomDateSelected"
                class="radio-button"
                type="radio"
                name="treatmentStartDate"
                checked="checked"
                :value="false"
              />
              <label for="todayDate" class="radio-button-label">
                {{ $t('dashboard.label.today') }}
              </label>
            </div>
            <div class="radio-container flex--align-center">
              <input
                id="customDate"
                v-model="isCustomDateSelected"
                class="radio-button"
                type="radio"
                name="treatmentStartDate"
                :value="true"
              />
              <label for="customDate" class="radio-button-label">
                <date-picker
                  v-model="date"
                  lang="en"
                  class="date-start-datepicker"
                  type="date"
                  placeholder=""
                  :format="dateFormat"
                  :disabled="!isCustomDateSelected"
                  @input="selectCustomDate"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="summary-treatment__details-buttons flex-column--align-center">
        <button
          class="rounded-button-orange rounded-button-orange--filled"
          @click="onNextButtonClick"
        >
          {{ nextButtonText }}
        </button>
        <span v-if="isPastTreatmentDateSelected" class="recap-messages-hint">
          <i>{{ $t('dashboard.pastTreatment.treatmentRecapMessagesNotSent') }}</i>
        </span>
      </div>
    </div>
    <mobile-fixed-footer
      class="result__footer flex--center--center"
      :class="{ 'footer-with-hint': isPastTreatmentDateSelected }"
    >
      <div slot="footer" class="flex-column--align-center">
        <button
          class="rounded-button-orange rounded-button-orange--filled"
          @click="onNextButtonClick"
        >
          {{ nextButtonText }}
        </button>
        <span v-if="isPastTreatmentDateSelected" class="recap-messages-hint">
          <i>{{ $t('dashboard.pastTreatment.treatmentRecapMessagesNotSent') }}</i>
        </span>
      </div>
    </mobile-fixed-footer>

    <past-treatment-created-modal class="onlyXS" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import { get } from 'lodash';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import SummaryInfo from '@/modules/dashboard/components/create-treatment-tab/SummaryInfo';
import LoyaltyParticipantSelect from '@/modules/dashboard/components/create-treatment-tab/LoyaltyParticipantSelect';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import PastTreatmentCreatedModal from '@/modules/dashboard/components/create-treatment-tab/PastTreatmentCreatedModal';

import treatmentPreviewMixin from '@/modules/dashboard/components/mixins/treatmentPreviewMixin';

import { createTreatmentPreview, finishCreatingTreatmentProcess } from '@/modules/dashboard/api';
import { isMobileDevice } from '@/modules/dashboard/utils';
import { getTreatmentType } from '@/modules/dashboard/utils/create-treatment-utils';
import { getDifferenceInDays } from '@/modules/dashboard/utils/date-utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DATE_FORMAT, REVIEW_TREATMENT_MESSAGE_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'SummaryTreatmentDetails',
  components: {
    MobileHeader,
    SummaryInfo,
    LoyaltyParticipantSelect,
    MobileFixedFooter,
    DatePicker,
    PastTreatmentCreatedModal
  },
  mixins: [treatmentPreviewMixin],
  data() {
    return {
      dateFormat: DATE_FORMAT,
      date: '',
      isCustomDateSelected: false,
      isTreatmentPaid: false,
      currentParticipant: null
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      patientLocale: dashTypes.getters.CURRENT_PATIENT_LOCALE,
      treatmentId: dashTypes.getters.SUMMARY_TREATMENT_ID,
      summaryTreatmentId: dashTypes.getters.SUMMARY_TREATMENT_ID,
      recommendationDiagnostic: dashTypes.getters.RECOMMENDATION_DIAGNOSTIC,
      treatmentDirectoryRecommendations: dashTypes.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS
    }),
    isPastTreatmentDateSelected() {
      const isPastDate = getDifferenceInDays(new Date(), this.date) > 0;

      return this.isCustomDateSelected && isPastDate;
    },
    nextButtonText() {
      const keyPostfix = this.isPastTreatmentDateSelected ? 'createTreatment' : 'next';

      return this.$t(`dashboard.action.${keyPostfix}`);
    }
  },
  watch: {
    isPastTreatmentDateSelected(newValue, prevValue) {
      if (!prevValue && newValue) {
        this.currentParticipant = null;
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      addRequestError: dashTypes.actions.ADD_ERROR,
      setSummaryTreatmentId: dashTypes.actions.SET_SUMMARY_TREATMENT_ID
    }),
    onParticipantSelect(value) {
      this.currentParticipant = value;
    },
    closeModal() {
      this.$modal.hide('summary-treatment-modal');
    },
    selectCustomDate() {
      this.isCustomDateSelected = true;
    },
    onNextButtonClick() {
      if (this.isPastTreatmentDateSelected) {
        this.createPastTreatment();

        return;
      }

      this.continueSummary();
    },
    async createPastTreatment() {
      await this.createTreatmentPreview();

      await finishCreatingTreatmentProcess({
        patientId: this.currentPatientId,
        languageTag: this.patientLocale,
        treatmentId: this.treatmentId,
        messageTemplates: []
      });

      this.$modal.show('past-treatment-created-modal');
      this.closeModal();
    },
    async continueSummary() {
      await this.createTreatmentPreview();

      if (isMobileDevice()) {
        this.$router.push({
          name: 'ReviewPatientInviteTabs',
          params: {
            id: this.currentPatientId,
            templateType: REVIEW_TREATMENT_MESSAGE_TYPE.CREATE_TREATMENT
          }
        });

        return;
      }

      this.$modal.show('review-message-modal');
      this.closeModal();
    },
    async createTreatmentPreview() {
      this.setLoading(true);

      try {
        const participantId = get(this.currentParticipant, 'value', null);
        const diagnosticId = get(this.recommendationDiagnostic, 'id', null);

        const type = getTreatmentType({
          isTreatmentDirectory: !!this.treatmentDirectoryRecommendations,
          diagnosticId
        });

        const treatmentDirectoryId = get(
          this.treatmentDirectoryRecommendations,
          'treatmentRecommendationId',
          null
        );

        const treatment = {
          ...this.getTreatmentForPreview(),
          paid: this.isTreatmentPaid,
          startDate: this.isCustomDateSelected && this.date ? this.date : new Date(),
          started: !this.isCustomDateSelected,
          patientId: this.currentPatientId,
          treatmentDirectoryId,
          participantId,
          diagnosticId,
          type
        };

        const { data: treatmentId } = await createTreatmentPreview(
          this.currentPatientId,
          treatment
        );

        await this.setSummaryTreatmentId(treatmentId);
      } catch (error) {
        this.addRequestError('Treatment is not updated');
      } finally {
        this.setLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/checkbox';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/css/common/datepicker.css';

.summary-treatment {
  &__details {
    width: 100%;
  }

  &__details-container {
    margin-bottom: 30px;
    padding: 30px 20px;
    background: $background;
    border-radius: 8px;
  }

  &__details-paid {
    padding-bottom: 25px;
  }

  &__details-date {
    flex-wrap: wrap;
  }

  &__pick-date {
    margin-top: 15px;
    width: 100%;
  }

  &__details-buttons {
    .rounded-button-orange {
      min-width: 100px;
    }
  }

  .radio-container {
    margin-right: 30px;

    .radio-button-label:before {
      top: 50%;
      transform: translateY(-50%);
    }

    .date-start-datepicker {
      width: 108px;
    }
  }
}

.treatment-details {
  &__content-text {
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;
    color: $text-color;
  }
}

.radio-button {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

.radio-button-label {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  color: $grey-color;
  letter-spacing: 0.05em;

  padding-left: 30px;
}

.radio-button + .radio-button-label:before {
  content: '';
  border: 1px solid $light-blue;
  background-color: $white;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20px;
  height: 20px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: none;
}

.radio-button + .radio-button-label:before {
  border-radius: 100%;
}

.radio-button:checked + .radio-button-label:before {
  border: 1px solid $light-blue;
  background-color: $orange-color;
  background-clip: content-box;
  box-shadow: inset 0 0 0 6px $white;
}

.radio-button + .radio-button-label {
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
  color: $active-title-color;
}

.radio-button:checked + .radio-button-label {
  color: $main-title-color;
}

.radio-button + .radio-button-label:after {
  display: none;
}

.result__footer {
  display: none;
  height: 70px;

  background-color: $white;
  border-top: 1px solid $dim-white;
  box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
}

.footer-with-hint {
  height: 80px;
}

.recap-messages-hint {
  padding-top: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: $grey-color;
}

@media (max-width: 767px) {
  .summary-treatment {
    padding: 60px 0 140px;

    .summary-treatment__header {
      background-color: $background;

      &-title {
        font-size: 13px;
        font-weight: 400;
        color: $text-color;
      }
    }

    &__details-container {
      margin-bottom: 0;
      padding: 0 20px;
    }
  }

  .result__footer {
    display: flex;
  }

  .radio-button + .radio-button-label {
    font-size: 16px;
    line-height: 19px;
  }

  .treatment-details {
    padding: 0;
    margin-bottom: 40px;
    background: transparent;

    &__treatment-paid {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .mx-datepicker {
    margin-left: auto;
  }

  .summary-treatment {
    &__details-buttons {
      display: none;
    }

    &__pick-date {
      .radio-container {
        margin-right: 20px;
      }
    }
  }

  .radio-button + .radio-button-label:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .recap-messages-hint {
    padding-top: 10px;
    font-size: 10px;
    line-height: 12px;
  }
}
</style>
