<template>
  <section class="treatment-type-buttons__container full-width">
    <div class="treatment-type-buttons">
      <div class="diagnostic-list-dropdown__container dropdown">
        <div
          class="treatment-type-buttons__button full-width flex--center--center"
          tabindex="-1"
          @click="selectFromDiagnosticOption"
          @blur="disableDiagnosticListDropdownVisibility"
        >
          {{ $t('dashboard.createTreatmentTab.fromDiagnostic') }}
        </div>
        <diagnostics-list
          v-show="isDiagnosticListDropdownVisible"
          class="diagnostic-list-dropdown dropdown-menu"
          :diagnostics="diagnostics"
          @select-diagnostic="selectDiagnosticForRecommendation"
          @start-new-photo-analysis="onStartNewPhotoAnalysis"
        />
      </div>
      <button class="treatment-type-buttons__button" @click="selectTreatmentFromTreatmentDirectory">
        {{ $t('dashboard.createTreatmentTab.fromTreatmentsDirectory') }}
      </button>
      <button class="treatment-type-buttons__button" @click="selectTreatmentFromScratchOption">
        {{ $t('dashboard.createTreatmentTab.fromScratch') }}
      </button>
    </div>
  </section>
</template>

<script>
import DiagnosticsList from '@/modules/dashboard/components/create-treatment-tab/DiagnosticsList';

export default {
  name: 'CreateTreatmentTypeButtons',
  components: { DiagnosticsList },
  props: {
    diagnostics: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDiagnosticListDropdownVisible: false
    };
  },
  methods: {
    selectFromDiagnosticOption() {
      this.isDiagnosticListDropdownVisible = !this.isDiagnosticListDropdownVisible;
    },
    disableDiagnosticListDropdownVisibility() {
      this.isDiagnosticListDropdownVisible = false;
    },
    selectDiagnosticForRecommendation(diagnostic) {
      this.$emit('select-diagnostic-option', diagnostic);
    },
    onStartNewPhotoAnalysis() {
      this.$emit('start-new-photo-analysis');
    },
    selectTreatmentFromTreatmentDirectory() {
      this.$emit('select-treatment-option');
    },
    selectTreatmentFromScratchOption() {
      this.$emit('select-scratch-option');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.treatment-type-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;

  &__button {
    min-height: 40px;
    height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background: $white;

    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(148, 166, 176, 0.25);

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-color;

    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all 0.1s;

    &:hover,
    &:active {
      color: $orange-color;
    }
  }
}

.diagnostic-list-dropdown {
  display: block;
  top: 100%;
  left: 0;

  padding: 10px;
  white-space: nowrap;

  &__container {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .treatment-type-buttons {
    grid-template-columns: auto;
    grid-row-gap: 10px;

    &__container {
      display: flex;
      justify-content: center;
    }

    &__button {
      min-height: 30px;
      font-size: 13px;
      line-height: 15px;
      padding: 5px 15px;
    }
  }

  .diagnostic-list-dropdown {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
