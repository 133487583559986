<template>
  <div class="patient-photo__container">
    <div v-if="isStoredPhoto">
      <div class="patient-photo" :style="patientPhotoStyles" @click="zoomPhoto"></div>
      <i class="patient-photo__hint cursor-default">
        {{ $t('dashboard.label.clickPhotoToZoom') }}
      </i>
    </div>
    <div v-else class="empty-photo flex-column--align-center">
      <div class="empty-photo__icon custom-icon icon-empty-photo"></div>
      <span v-if="isDiagnosticHasPhoto" class="empty-photo__description">
        {{ $t('dashboard.label.photoWasntSaved') }}
      </span>
      <span v-else class="empty-photo__description">{{ $t('dashboard.label.noPhotoAdded') }}</span>
    </div>
    <photo-zoom-modal class="onlyXS" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import { fetchPatientPhotoByPhotoId } from '@/modules/dashboard/api';

import { buildImageUrl, buildPatientPhotoStyles } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'PatientDiagnosticPhoto',
  components: { PhotoZoomModal },
  props: {
    diagnostic: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      photo: null
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    patientPhotoStyles() {
      return buildPatientPhotoStyles(this.photo);
    },
    patientPhotoUrl() {
      return buildImageUrl(this.photo);
    },
    isStoredPhoto() {
      return this.photo && this.photo.storedPhoto;
    },
    isDiagnosticHasPhoto() {
      return !!this.diagnostic && this.diagnostic.photoId;
    }
  },
  async mounted() {
    const { photoId } = this.diagnostic;

    if (!photoId) {
      return;
    }
    const { data } = await fetchPatientPhotoByPhotoId(this.currentPatient.id, photoId);
    this.photo = data;
  },
  methods: {
    zoomPhoto() {
      this.$modal.show('photo-zoom-modal', { photoUrl: this.patientPhotoUrl });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';

.patient-photo,
.empty-photo {
  width: 280px;
  height: 280px;

  border-radius: 10px;
}

.patient-photo {
  background-size: cover;
  background-position: center top;
  cursor: pointer;

  &__hint {
    width: 100%;
    display: inline-block;
    margin-top: 10px;

    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: $grey-color;

    text-align: center;
  }
}

.empty-photo {
  background: $background;

  &__icon {
    margin: 57px 0 40px;
    opacity: 0.3;
  }

  &__description {
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;

    opacity: 0.7;

    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .patient-photo,
  .empty-photo {
    width: 260px;
    height: 260px;

    border-radius: 8px;
  }

  .patient-photo {
    &__hint {
      font-size: 10px;
      line-height: 12px;
    }
  }
}
</style>
