<template>
  <modal
    name="empty-serum-confirmation-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="280"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="empty-serum-confirmation">
        <div class="empty-serum-confirmation__description">
          {{ $t('dashboard.warning.serumWithoutActive') }}
        </div>
        <div class="empty-serum-confirmation__question">
          {{ $t('dashboard.warning.wantProceed') }}
        </div>
        <div class="empty-serum-confirmation__answer-buttons answer-buttons flex--center--center">
          <button class="cancel-button mr20" @click="chooseCancel">
            {{ $t('dashboard.action.cancel') }}
          </button>
          <button class="answer-buttons__yes rounded-button-white" @click="chooseYes">
            {{ $t('dashboard.medicalData.yes') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

export default {
  name: 'EmptySerumConfirmationModal',
  data() {
    return {
      answerResolver: null
    };
  },
  methods: {
    beforeOpen({ params: { answerResolver } }) {
      this.answerResolver = answerResolver;
    },
    chooseCancel() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.CANCEL);

      this.closeModal();
    },
    chooseYes() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.YES);

      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('empty-serum-confirmation-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.empty-serum-confirmation {
  padding: 30px 21px;

  &__description,
  &__question {
    font-weight: 300;
    color: $text-color;
  }

  &__description {
    padding: 12px 15px 11px;

    font-size: 11px;
    line-height: 16px;

    border-radius: 8px;
    background: $beige;
  }

  &__question {
    margin: 30px 0;

    font-size: 13px;
    line-height: 19px;
  }
}

.answer-buttons {
  &__yes {
    min-width: 100px;
  }
}
</style>
