<template>
  <div class="dropdown">
    <div
      class="dropdown__icon dropdown-icon flex--center--center pointer"
      :class="iconClasses"
      tabindex="-1"
      @click="toggleDropdownVisibility"
      @blur="disableDropdownVisibility"
    >
      <slot name="dropdown-icon" :disableDropdownVisibility="disableDropdownVisibility"></slot>
    </div>
    <ul
      v-show="isDropdownVisible"
      class="dropdown__content dropdown-menu flex-column--align-center"
    >
      <slot name="dropdown-options"></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TreatmentDropdown',
  props: {
    microneedling: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropdownVisible: false
    };
  },
  computed: {
    iconClasses() {
      return {
        'dropdown__icon--microneedling': this.microneedling,
        'dropdown__icon--disabled': this.isReadonly
      };
    }
  },
  methods: {
    toggleDropdownVisibility() {
      if (this.isReadonly) {
        return;
      }

      this.isDropdownVisible = !this.isDropdownVisible;
    },
    disableDropdownVisibility() {
      this.isDropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/helpers/colors';

.dropdown {
  &__icon {
    height: 28px;
    min-width: 28px;

    padding: 5px 3px;

    background: $hovered-button-color;

    border: 0.5px dashed $grey-color;
    border-radius: 5px;

    outline: none;

    &--microneedling {
      width: 42px;
      height: 20px;
      color: $orange-color;
    }

    &--disabled {
      cursor: default;
      background-color: transparent;
      border: none;
      color: $grey-color;
    }
  }

  &-menu {
    padding: 0;
  }
}

@media (max-width: 1080px) {
  .dropdown {
    &__content {
      min-width: 90px;
      width: 90px;
    }

    &__options {
      width: 70px;
    }

    &-menu {
      min-width: 90px;
    }
  }
}
</style>
