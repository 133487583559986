<template>
  <div class="treatment-directory-modals">
    <treatment-selection-modal />
    <treatment-directory-modal />
  </div>
</template>

<script>
import TreatmentSelectionModal from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentSelectionModal';
import TreatmentDirectoryModal from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentDirectoryModal';

export default {
  name: 'TreatmentDirectoryModals',
  components: { TreatmentDirectoryModal, TreatmentSelectionModal }
};
</script>
