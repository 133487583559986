import { mapGetters } from 'vuex';

import { getTreatmentActionScores } from '@/modules/dashboard/api/scores';
import { getTreatmentFromSummaryTreatment } from '@/modules/dashboard/utils/create-treatment-utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

const treatmentPreviewMixin = {
  computed: {
    ...mapGetters({
      summaryTreatment: dashTypes.getters.SUMMARY_TREATMENT,
      skinCharacteristics: dashTypes.getters.SKIN_CHARACTERISTICS_BY_RECOMMENDATION_DIAGNOSTIC,
      cleanseProducts: dashTypes.getters.CLEANSE_PRODUCTS,
      moisturizeProducts: dashTypes.getters.MOISTURIZE_PRODUCTS,
      protectProducts: dashTypes.getters.PROTECT_PRODUCTS,
      getProductPriceByReference: dashTypes.getters.PRODUCT_PRICE_BY_REFERENCE
    })
  },
  methods: {
    getTreatmentForPreview() {
      const actionScores = getTreatmentActionScores(
        this.summaryTreatment.treatFace,
        this.skinCharacteristics
      );

      return getTreatmentFromSummaryTreatment(
        this.summaryTreatment,
        {
          cleanseProducts: this.cleanseProducts,
          moisturizeProducts: this.moisturizeProducts,
          protectProducts: this.protectProducts,
          actionScores
        },
        this.getProductPriceByReference
      );
    }
  }
};

export default treatmentPreviewMixin;
