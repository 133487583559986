<template>
  <modal
    name="empty-eye-contour-serum-warning-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="280"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="empty-eye-contour-serum-warning">
        <div class="empty-eye-contour-serum-warning__description">
          {{ $t('dashboard.serumWithoutIngredientsNotification.p0') }}
        </div>
        <div class="empty-eye-contour-serum-warning__request">
          {{ $t('dashboard.serumWithoutIngredientsNotification.p1') }}
        </div>
        <div class="flex--center--center">
          <button class="ok-button rounded-button-white" @click="onOkClick">
            {{ $t('dashboard.button.ok') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'EmptyEyeContourSerumWarningModal',
  data() {
    return {
      answerResolver: null
    };
  },
  methods: {
    beforeOpen({ params: { answerResolver } }) {
      this.answerResolver = answerResolver;
    },
    onOkClick() {
      this.answerResolver();
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('empty-eye-contour-serum-warning-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.empty-eye-contour-serum-warning {
  padding: 30px 21px;

  &__description,
  &__request {
    font-weight: 300;
    color: $text-color;
  }

  &__description {
    padding: 12px 15px 11px;

    font-size: 11px;
    line-height: 16px;

    border-radius: 8px;
    background: $beige;
  }

  &__request {
    margin: 30px 0;

    font-size: 13px;
    line-height: 19px;
  }
}

.ok-button {
  min-width: 80px;
}
</style>
