<template>
  <default-template-message class="mb30">
    <div class="treatment-completed-email-template default-email-message-template">
      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__title"
          field-name="greeting"
          :value="templateData.greeting"
          :max-length="fieldsMaxLength.greeting"
          @field-input="onTreatmentCompletedFieldInput"
          @field-change="onTreatmentCompletedFieldChange"
        />

        <email-message-template-text-area
          class="incomplete-questionnaire-email-template__body-part-one-paragraph default-email-message-template__paragraph"
          field-name="bodyPartOne"
          :value="templateData.bodyPartOne"
          :max-length="fieldsMaxLength.bodyPartOne"
          @field-input="onTreatmentCompletedFieldInput"
          @field-change="onTreatmentCompletedFieldChange"
        />

        <email-message-template-text-area
          class="default-email-message-template__additional-info"
          field-name="bodyPartTwo"
          :value="templateData.bodyPartTwo"
          :max-length="fieldsMaxLength.bodyPartTwo"
          @field-input="onTreatmentCompletedFieldInput"
          @field-change="onTreatmentCompletedFieldChange"
        />
      </div>

      <email-message-template-link-section
        class="default-email-message-template__section--with-top-border"
        :link-name="templateData.linkName"
      />
      <email-message-template-signature-section :template-data="templateData" />
    </div>
  </default-template-message>
</template>

<script>
import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';
import EmailMessageTemplateTextArea from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateTextArea';
import EmailMessageTemplateLinkSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateLinkSection';
import EmailMessageTemplateSignatureSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateSignatureSection';

export default {
  name: 'TreatmentCompletedEmailTemplate',
  components: {
    EmailMessageTemplateSignatureSection,
    EmailMessageTemplateLinkSection,
    EmailMessageTemplateTextArea,
    DefaultTemplateMessage
  },
  props: {
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    }
  },
  methods: {
    onTreatmentCompletedFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    onTreatmentCompletedFieldChange(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';

.treatment-completed-email-template {
  & &__body-part-one-paragraph {
    margin-bottom: 30px;
  }
}
</style>
