<template>
  <div ref="diagnosticsList" class="diagnostics-list">
    <div class="new-photo-diagnostic-button__container full-width">
      <button
        class="new-photo-diagnostic-button rounded-button-gray full-width"
        @mousedown="onNewPhotoAnalysisClick"
      >
        {{ $t('dashboard.createTreatmentTab.newPhotoAnalysisDiagnostic') }}
      </button>
    </div>
    <template v-if="hasDiagnostics">
      <diagnostic
        v-for="diagnostic in diagnostics"
        :key="diagnostic.id"
        class="diagnostics-list__diagnostic"
        :diagnostic="diagnostic"
        display-diagnostic-date
        @mousedown.native="selectDiagnostic(diagnostic)"
      />
    </template>
    <div v-else class="diagnostics-placeholder flex--center--center mt15">
      <span class="diagnostics-placeholder__text">
        {{ $t('dashboard.label.noDiagnosticsFound') }}
      </span>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';

import Diagnostic from '@/modules/dashboard/components/diagnostic-tab/Diagnostic';

export default {
  name: 'DiagnosticsList',
  components: { Diagnostic },
  props: {
    diagnostics: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      diagnosticsListScroll: null
    };
  },
  computed: {
    hasDiagnostics() {
      return !isEmpty(this.diagnostics);
    }
  },
  mounted() {
    if (this.$refs.diagnosticsList) {
      this.diagnosticsListScroll = new PerfectScrollbar(this.$refs.diagnosticsList, {
        wheelPropagation: false
      });
    }
  },
  methods: {
    selectDiagnostic(diagnostic) {
      this.$emit('select-diagnostic', diagnostic);
    },
    onNewPhotoAnalysisClick() {
      this.$emit('start-new-photo-analysis');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.diagnostics-list {
  max-height: 275px;
  overflow: hidden;

  &__diagnostic {
    height: 55px;
    padding: 0 10px;
  }
}

.diagnostics-placeholder {
  height: 55px;
  background: $dim-background;

  &__text {
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;

    color: $grey-color;
  }
}

.new-photo-diagnostic-button {
  color: $text-color;

  &__container {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .diagnostics-list {
    max-height: 250px;

    &__diagnostic {
      height: 48px;
      padding: 0;
    }
  }

  .diagnostics-placeholder {
    width: 100%;
  }
}
</style>
