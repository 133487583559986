<template>
  <div class="dropdown">
    <treatment-dropdown>
      <template slot="dropdown-icon" slot-scope="{ disableDropdownVisibility }">
        <span
          class="custom-icon"
          :class="`${usageIconClasses[usage]}-active`"
          tabindex="-1"
          @blur="disableDropdownVisibility"
        ></span>
      </template>
      <template slot="dropdown-options">
        <treatment-product-options :value="usage" :options="options" @select-option="selectOption">
          <template slot="option" slot-scope="{ option }">
            <span
              class="option__content custom-icon"
              :class="[usageIconClasses[option], { 'option__content--active': usage === option }]"
            ></span>
          </template>
        </treatment-product-options>
      </template>
    </treatment-dropdown>
  </div>
</template>

<script>
import TreatmentDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentDropdown';
import TreatmentProductOptions from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductOptions';

import { TREATMENT_USAGE_ICON_CLASSES } from '@/modules/dashboard/api/constants';

export default {
  name: 'TimeUsageDropdown',
  components: { TreatmentDropdown, TreatmentProductOptions },
  props: {
    usage: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDropdownVisible: false,
      usageIconClasses: TREATMENT_USAGE_ICON_CLASSES
    };
  },
  methods: {
    selectOption(usage) {
      this.$emit('select-usage', usage);
    }
  }
};
</script>

<style lang="scss" scoped></style>
