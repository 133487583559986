<template>
  <div class="diagnostic flex--space-between--center pointer" :class="{ active: isActive }">
    <div class="diagnostic__title title flex--align-center">
      <p class="first-letter" :class="{ 'diagnostic__photo-number': isPhotoAnalysisDiagnostic }">
        {{ diagnosticNumberLabel }}
      </p>
      <span class="title__label">{{ diagnosticLabel }}</span>
      <div
        v-if="diagnostic.hasValidationsOrAnomalies && !hideValidationWarnIcon"
        class="title__icon"
      >
        <span
          class="fas fa-exclamation-triangle error-message"
          :class="{ validated: diagnostic.validated }"
        ></span>
      </div>
    </div>
    <div class="diagnostic__detail detail flex--align-center">
      <div class="detail__icon custom-icon ml20" :class="diagnosticIconClass"></div>
      <span v-if="hasOpenIcon" class="fas fa-chevron-right more-info-icon"></span>
      <span v-if="displayDiagnosticDate" class="detail__date">
        {{ diagnostic.date | formatDate('DD-MMM-YYYY') }}
      </span>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

import { DIAGNOSTIC_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'Diagnostic',
  props: {
    diagnostic: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    hasOpenIcon: {
      type: Boolean,
      default: false
    },
    displayDiagnosticDate: {
      type: Boolean,
      default: false
    },
    hideValidationWarnIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPhotoAnalysisDiagnostic() {
      return this.diagnostic.type === DIAGNOSTIC_TYPES.PHOTO_BASED;
    },
    diagnosticNumber() {
      return get(this.diagnostic, 'number', '');
    },
    diagnosticNumberLabel() {
      return `D${this.diagnosticNumber}`;
    },
    diagnosticLabel() {
      return `${this.$t('dashboard.details.diagnostic')} ${this.diagnosticNumber}`;
    },
    diagnosticIconClass() {
      const diagnosticIconClasses = [{ 'detail__icon--without-warn': this.hideValidationWarnIcon }];

      if (this.isPhotoAnalysisDiagnostic) {
        diagnosticIconClasses.push('icon-portrait');
      } else {
        diagnosticIconClasses.push(
          this.diagnostic.hasTreatment ? 'icon-clipboard-grey' : 'icon-clipboard'
        );
      }

      return diagnosticIconClasses;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/first-letter';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/errors.css';
@import '../../../../assets/css/common/icons.css';

.diagnostic {
  &__photo-number {
    background-color: $cream-color;
  }
}

.title {
  &__label {
    font-size: 15px;
    font-weight: bold;
    line-height: 19px;
    margin-left: 10px;
    margin-right: 15px;
    color: $main-title-color;
  }
}

.detail {
  &__date {
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;

    color: $text-color;

    margin-left: 11px;
  }

  &__icon {
    &--without-warn {
      margin-left: 0;
    }
  }
}

.more-info-icon {
  color: $light-grey-color;
  margin-left: 30px;
  font-size: 12px;
}

.validated {
  color: $icon-dim-grey-color;
}

@media (max-width: 991px) {
  .title {
    &__label {
      margin-right: 10px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .more-info-icon {
    margin-left: 15px;
  }

  .detail {
    &__icon {
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .active {
    background-color: $white;
    border-radius: 8px 0 0 8px;
  }
}

@media (max-width: 767px) {
  .title {
    &__label {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .first-letter {
    font-size: 12px;
  }

  .more-info-icon {
    margin-left: 12px;
  }

  .detail {
    &__date {
      font-size: 12px;
      line-height: 18px;

      margin-left: 10px;
    }
  }
}
</style>
