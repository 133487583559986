<template>
  <treatment-recommendation-basis>
    <span slot="header-title" class="recommendation-title label-text">
      <span>{{ $t('dashboard.createTreatmentTab.treatmentBasedOnTreatmentsDirectory') }} —</span>
      <b class="mr-2">{{ recommendationName }}</b>
      <span v-if="recommendationClarifyingInformation" class="recommendation-title__clarifying">
        /{{ recommendationClarifyingInformation }}
      </span>
    </span>
  </treatment-recommendation-basis>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

import TreatmentRecommendationBasis from '@/modules/dashboard/components/create-treatment-tab/TreatmentRecommendationBasis';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'TreatmentDirectoryRecommendation',
  components: { TreatmentRecommendationBasis },
  computed: {
    ...mapGetters({
      treatmentDirectoryRecommendations: dashTypes.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS
    }),
    recommendationName() {
      return get(this.treatmentDirectoryRecommendations, 'name');
    },
    recommendationClarifyingInformation() {
      return get(this.treatmentDirectoryRecommendations, 'clarifyingInformation');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/text';

.recommendation-title {
  flex-grow: 1;
  width: 100%;

  &__clarifying {
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .recommendation-title {
    padding-bottom: 10px;
  }
}
</style>
