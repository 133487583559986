<template>
  <div class="default-serum flex-column--align-center justify-content--center pointer">
    <div class="custom-icon" :class="defaultIcon"></div>
    <div class="default-serum__add-button add-button rounded-button-gray flex--align-center">
      <div class="add-button__icon custom-icon icon-plus-active"></div>
      {{ $t('dashboard.action.addFaceSerum') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultSerumCard',
  props: {
    type: {
      type: String,
      default: 'morning'
    }
  },
  computed: {
    defaultIcon() {
      return `icon-${this.type}-usage`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons';

.default-serum {
  width: calc(50% - 10px);
  height: 180px;

  border: 1px dashed $border-light-grey-color;
  border-radius: 8px;

  &__add-button {
    margin-top: 15px;
  }
}

.add-button {
  padding: 8px 20px 8px 17px;

  &__icon {
    width: 15px;
    height: 15px;

    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  .default-serum {
    width: 310px;
    height: 180px;
  }
}
</style>
