<template>
  <div class="treatment-recommendation-basis flex-column justify-content--center">
    <div class="treatment-recommendation-basis__header flex--center--center">
      <slot name="header-title">
        <span class="recommendation-header__text label-text">
          {{ $t('dashboard.label.treatmentRecommendationBased') }}
        </span>
      </slot>
      <slot name="header-detail"></slot>
    </div>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  name: 'TreatmentRecommendationBasis'
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/text';

.treatment-recommendation-basis {
  display: flex;
  padding: 0 20px;
  border-radius: 8px;
  background-color: $background;

  &__header {
    min-height: 60px;
  }
}

@media (max-width: 767px) {
  .treatment-recommendation-basis {
    width: 100%;
    padding: 15px 25px 5px;
    margin-top: 60px;

    border-radius: 8px;
    background-color: $dim-white;

    &__header {
      flex-direction: column;
      min-height: auto;
    }
  }
}
</style>
