<template>
  <modal
    name="past-treatment-created-modal"
    class="modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="modalWidth"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="content">
          <div class="content__text pre-wrap">{{ contentText }}</div>
        </div>
        <button class="rounded-button-white" @click="confirmTreatmentCreation">
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'PastTreatmentCreatedModal',
  data() {
    return {
      modalWidth: null
    };
  },
  computed: {
    ...mapGetters({
      patientId: dashTypes.getters.CURRENT_PATIENT_ID,
      patientFirstName: dashTypes.getters.CURRENT_PATIENT_FIRST_NAME
    }),
    contentText() {
      return this.$t('dashboard.label.pastTreatmentCreated', [this.patientFirstName]);
    }
  },
  created() {
    this.modalWidth = isMobileDevice() ? 300 : 500;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      finishCreateTreatmentProcess: dashTypes.actions.FINISH_CREATE_TREATMENT_PROCESS,
      resetCreateTreatmentSelections: dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS
    }),
    async confirmTreatmentCreation() {
      this.setLoading(true);
      await this.finishCreateTreatmentProcess();
      this.setLoading(false);

      this.goToTreatmentHistory();
      await this.resetCreateTreatmentSelections();
    },
    goToTreatmentHistory() {
      if (isMobileDevice()) {
        this.$router.push({
          name: 'TreatmentHistory',
          params: {
            id: this.patientId
          }
        });
      }

      this.setActivePatientTab(PATIENT_TAB_NAMES.TREATMENT_HISTORY);
      this.$modal.hide('past-treatment-created-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.modal-container {
  padding: 30px 70px;
}

.content {
  margin-bottom: 20px;
  padding: 20px 15px;

  background-color: $beige;
  border-radius: 8px;

  text-align: left;

  &__text {
    font-size: 13px;
    font-weight: 200;
    line-height: 19px;
    color: $text-color;
  }
}

@media (max-width: 767px) {
  .modal-container {
    padding: 20px;
  }

  .content {
    &__text {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
