import { mapGetters } from 'vuex';

import saveMarketingTemplateMixin from '@/modules/dashboard/components/mixins/saveTemplateMixin';

import { types } from '@/modules/dashboard/store/types';
import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

const templateUnsavedChangesMixin = {
  mixins: [saveMarketingTemplateMixin],
  computed: {
    ...mapGetters({
      isMessageTemplateUpdated: types.getters.IS_MESSAGE_TEMPLATE_UPDATED,
      isCurrentMessageTemplateTitleEmpty: types.getters.IS_CURRENT_MESSAGE_TEMPLATE_TITLE_EMPTY
    })
  },
  methods: {
    async onTemplateClose() {
      if (!this.isMessageTemplateUpdated) {
        const unsavedChangesModalInput = await this.getUnsavedChangesModalInput();

        switch (unsavedChangesModalInput) {
          case UNSAVED_CHANGES_INPUTS.YES: {
            if (this.isCurrentMessageTemplateTitleEmpty) {
              return false;
            }

            await this.updateTemplate();

            return true;
          }

          case UNSAVED_CHANGES_INPUTS.NO: {
            return true;
          }

          default: {
            return false;
          }
        }
      }

      return true;
    },
    getUnsavedChangesModalInput() {
      return new Promise(resolve => {
        this.$modal.show('unsaved-changes-modal', { resolveAnswer: resolve });
      });
    }
  }
};

export default templateUnsavedChangesMixin;
