<template>
  <modal
    name="offer-composition-modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="800"
    height="auto"
  >
    <div class="modal-container">
      <modal-header-with-previous
        current-modal-name="offer-composition-modal"
        previous-modal-name="send-offer-modal"
      >
        <span slot="header-title">{{ $t('dashboard.label.reviewOfferComposition') }}</span>
      </modal-header-with-previous>
      <offer-composition />
    </div>
  </modal>
</template>

<script>
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import OfferComposition from '@/modules/dashboard/components/create-treatment-tab/send-offer/OfferComposition';

export default {
  name: 'OfferCompositionModal',
  components: { ModalHeaderWithPrevious, OfferComposition }
};
</script>
