<template>
  <modal
    name="moisturize-usage-selection-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="280"
    height="auto"
    @before-open="beforeOpen"
  >
    <main class="modal-container modal-window">
      <section class="modal__section mb30">
        {{ $t('dashboard.warning.onlyOneMoisturizer') }}
      </section>
      <div class="modal__question mb30">
        <i18n path="dashboard.warning.moisturizer.wantSelect" tag="span">
          <span class="bold">{{ $t(productTitle) }}</span>
          <span>{{ $t(`dashboard.label.${productTimeUsage}`) }}</span>
        </i18n>
      </div>
      <div class="modal__buttons flex--center--center">
        <div class="cancel-button pointer mr20" @click="onCancel">
          {{ $t('dashboard.action.cancel') }}
        </div>
        <button class="rounded-button-white" @click="onSelect">
          {{ $t('dashboard.action.moisturizer.select') }}
        </button>
      </div>
    </main>
  </modal>
</template>

<script>
import { PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP } from '@/modules/dashboard/api/constants';

export default {
  name: 'MoisturizeUsageSelectionModal',
  data() {
    return {
      productName: null,
      productTimeUsage: null,
      resolveAnswer: null
    };
  },
  computed: {
    productTitle() {
      return PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP[this.productName];
    }
  },
  methods: {
    beforeOpen({ params: { productName, productTimeUsage, resolver } }) {
      this.productName = productName;
      this.productTimeUsage = productTimeUsage;
      this.resolveAnswer = resolver;
    },
    onCancel() {
      this.resolveAnswer(false);
      this.closeModal();
    },
    onSelect() {
      this.resolveAnswer(true);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('moisturize-usage-selection-modal');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../../../assets/css/common/text';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.modal {
  &-container {
    padding: 30px 20px;

    font-weight: 300;
    color: $text-color;
  }

  &__section {
    padding: 10px 15px;

    background: $beige;
    border-radius: 8px;

    font-size: 11px;
    line-height: 16px;
  }

  &__question {
    font-size: 13px;
    line-height: 19px;
  }

  &__info {
    font-size: 11px;
    line-height: 16px;
  }
}

.info {
  &__link {
    color: $links-color;
  }
}
</style>
