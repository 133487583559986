<template>
  <warning-modal
    class="treatment-warning"
    name="treatment-warning-modal"
    transition="scale"
    adaptive
    title="dashboard.microneedling.warning.notice"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <div v-if="hasIngredients" class="warning-text-section__ingredients">
        <i18n :path="ingredientsTranslationPath" tag="span">
          <b>{{ firstIngredientsTranslationPart }}</b>
          <b>{{ secondIngredientsTranslationPart }}</b>
        </i18n>
      </div>

      <div v-if="hasCheckListStatements" class="warning-text-section__check-list">
        <div>{{ $t('treatmentDirectory.warning.makeSure') }}</div>
        <div v-for="check in checkList" :key="check">
          • {{ $t(`treatmentDirectory.warning.${check}`) }}
        </div>
      </div>

      <div v-if="hasRecommendation" class="warning-text-section__spf-warning">
        {{ $t(`treatmentDirectory.warning.${recommendation}`) }}
      </div>
    </template>

    <template #actions>
      <confirmation-modal-actions @cancel="handleClose(false)" @confirm="handleClose(true)" />
    </template>
  </warning-modal>
</template>

<script>
import { isEmpty } from 'lodash';

import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'TreatmentWarningModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return {
      modalWidth: '500px',
      ingredients: [],
      firstIngredientsTranslationPart: '',
      secondIngredientsTranslationPart: '',
      checkList: [],
      recommendation: '',
      onClose: null
    };
  },
  computed: {
    hasIngredients() {
      return !isEmpty(this.ingredients);
    },
    hasCheckListStatements() {
      return !isEmpty(this.checkList);
    },
    hasRecommendation() {
      return !!this.recommendation;
    },
    ingredientsTranslationPath() {
      return this.secondIngredientsTranslationPart
        ? 'treatmentDirectory.warning.formulaContainsMoreThanOneIngredient'
        : 'treatmentDirectory.warning.formulaContainsOneIngredient';
    }
  },
  created() {
    if (isMobileDevice()) {
      this.modalWidth = '280px';
    }
  },
  methods: {
    onBeforeOpen({ params: { treatmentWarning, onClose } }) {
      const { ingredients, checkList, recommendation } = treatmentWarning;
      const { firstPart, secondPart } = this.getIngredientsInTwoTranslationParts(ingredients);

      this.onClose = onClose;
      this.ingredients = ingredients;
      this.checkList = checkList;
      this.recommendation = recommendation;
      this.firstIngredientsTranslationPart = firstPart;
      this.secondIngredientsTranslationPart = secondPart;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    },
    getIngredientsInTwoTranslationParts(ingredients) {
      return ingredients.reduce(
        (ingredientsTranslationParts, ingredient, index) => {
          const ingredientTranslation = this.$t(`dashboard.ingredients.${ingredient}`);

          if (index === 0) {
            return { firstPart: ingredientTranslation };
          }

          if (index === ingredients.length - 1) {
            return {
              ...ingredientsTranslationParts,
              secondPart: ingredientTranslation
            };
          }

          return {
            firstPart: `${ingredientsTranslationParts.firstPart}, ${ingredientTranslation}`
          };
        },
        { firstPart: '' }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';

.treatment-warning {
  &__title {
    font-size: 15px;
    line-height: 17px;
    color: $text-color;
  }

  &__warning-text {
    margin: 30px 0;
    padding: 25px 25px 0;

    background: $beige;
    border-radius: 8px;
  }
}

.warning-text-section {
  &__ingredients,
  &__check-list,
  &__spf-warning {
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
    color: $text-color;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .treatment-warning {
    &__title {
      font-size: 13px;
      line-height: 15px;
    }

    &__warning-text {
      margin: 25px 0;
      padding: 12px 15px 2px;
    }
  }

  .warning-text-section {
    &__ingredients,
    &__check-list,
    &__spf-warning {
      font-size: 11px;
      margin-bottom: 10px;
    }
  }
}
</style>
