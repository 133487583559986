<template>
  <section class="radio-question flex-column--align-center">
    <span class="radio-question__text main-text ie-text-modal text-center">
      {{ questionText }}
    </span>
    <div class="flex">
      <radio-button-with-label
        v-for="binding in valueBindings"
        :key="binding.value"
        class="radio-question__value"
        :name="name"
        :label="$t(binding.translationLabel)"
        :value="binding.value"
        :current-value="value"
        required
        @input="updateAnswer"
      />
    </div>
  </section>
</template>

<script>
import RadioButtonWithLabel from '@/modules/dashboard/components/dashboard/common/RadioButtonWithLabel';

export default {
  name: 'RadioButtonQuestion',
  components: { RadioButtonWithLabel },
  inject: ['$validator'],
  props: {
    question: {
      type: String,
      required: true
    },
    valueBindings: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    isQuestionKey: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    questionText() {
      return this.isQuestionKey ? this.$t(this.question) : this.question;
    }
  },
  methods: {
    updateAnswer(answer) {
      this.$emit('input', answer);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/css/common/errors.css';

.radio-question {
  &__text {
    margin-bottom: 20px;
  }

  &__value {
    margin-right: 25px;
  }

  &__value:last-child {
    margin-right: 0;
  }
}
</style>
