<template>
  <modal
    name="summary-treatment-modal"
    class="summary-treatment-modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="800"
    height="auto"
  >
    <div class="modal-container">
      <div class="summary-treatment">
        <header class="summary-treatment__header flex--space-between--center">
          <span class="summary-treatment__header-text">
            {{ $t('dashboard.label.createTreatmentTab') }}
          </span>
          <div class="icon-close custom-icon" @click="closeModal"></div>
        </header>
        <summary-treatment />
        <footer class="summary-treatment__footer flex--center--center">
          <button class="otherXS cancel-button" @click="closeModal">
            {{ $t('dashboard.action.cancel') }}
          </button>
        </footer>
      </div>
    </div>
  </modal>
</template>

<script>
import SummaryTreatment from '@/modules/dashboard/components/create-treatment-tab/SummaryTreatment';

export default {
  name: 'SummaryTreatmentModal',
  components: { SummaryTreatment },
  methods: {
    closeModal() {
      this.$modal.hide('summary-treatment-modal');
      this.$emit('after-modal-close', { cancel: true });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.summary-treatment {
  &__header {
    height: 50px;
    padding: 0 20px;
    border-bottom: 0.5px solid $dim-white;
  }

  &__header-text {
    font-size: 15px;
    color: $text-color;
  }

  &__footer {
    height: 70px;
    border-top: 1px solid $dim-white;
  }
}

@media (max-width: 991px) {
  .summary-treatment-modal /deep/ .modal-window {
    max-width: 700px;
    max-height: 700px;
    margin: 0 auto;
    left: unset !important;
  }
}

@media (max-width: 991px) and (max-height: 750px) {
  .summary-treatment-modal /deep/ .modal-window {
    max-height: 100vh;
  }
}
</style>
