<template>
  <div class="section-header__container">
    <div class="section-header flex--align-center">
      <div class="section-header__info flex">
        <span class="section-header__title">{{ title }}</span>
        <div
          v-if="hasActiveTime"
          class="section-header__details details flex-column--align-center"
          :class="{ 'details--from-scratch': !canRecalculate }"
        >
          <span class="details__active-time active-time">
            {{ $t(firstActiveTime) }}
            <span v-if="firstActiveTime && secondActiveTime" class="active-time__divider">
              &nbsp;&bull;&nbsp;
            </span>
            {{ $t(secondActiveTime) }}
          </span>
          <span v-if="duration && price" class="details__duration">
            {{ treatmentDurationLabel }}&nbsp;({{ pricePerMonthLabel }})
          </span>
        </div>
      </div>
      <div
        class="section-header__recalculate recalculate flex justify-content--flex-end"
        :class="{
          'recalculate--from-scratch': !canRecalculate,
          'recalculate--no-diagnostic': !hasActiveTime
        }"
      >
        <div
          v-if="canRecalculate"
          v-tooltip="{ content: recalculateHint }"
          class="recalculate__button recalculate-button rounded-button-gray flex--align-center"
          @click="recalculate"
        >
          <span class="recalculate-button__icon custom-icon icon-recalculate"></span>
          {{ recalculateButtonLabel }}
        </div>
      </div>
    </div>
    <div class="section-header__divider divider otherXS"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { TREAT_SECTIONS } from '@/modules/dashboard/api/constants';

const RECALCULATE_HINT_TRANSLATION_POSTFIX = {
  [TREAT_SECTIONS.FACE_SECTION]: 'recalculateTreatFaceSerum',
  [TREAT_SECTIONS.EYE_CONTOUR]: 'resetTreatEyeSerum'
};

const RECALCULATE_BUTTON_LABEL_TRANSLATION_POSTFIX = {
  [TREAT_SECTIONS.FACE_SECTION]: 'recalculate',
  [TREAT_SECTIONS.EYE_CONTOUR]: 'resetTreatEyeSerum'
};

export default {
  name: 'TreatSectionHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    firstActiveTime: {
      type: String,
      default: ''
    },
    secondActiveTime: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    },
    canRecalculate: {
      type: Boolean,
      default: false
    },
    sectionName: {
      type: String,
      default: TREAT_SECTIONS.FACE_SECTION
    }
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY
    }),
    hasActiveTime() {
      return this.firstActiveTime || this.secondActiveTime;
    },
    treatmentDurationLabel() {
      return this.duration > 1
        ? this.$t('dashboard.label.durationMonths', [this.duration])
        : this.$t('dashboard.label.durationMonth', [this.duration]);
    },
    pricePerMonthLabel() {
      return this.$t('dashboard.label.pricePerMonth', [
        formatPrice({ currency: this.currency, price: this.price })
      ]);
    },
    recalculateHint() {
      return this.$t(`dashboard.hint.${RECALCULATE_HINT_TRANSLATION_POSTFIX[this.sectionName]}`);
    },
    recalculateButtonLabel() {
      return this.$t(
        `dashboard.action.${RECALCULATE_BUTTON_LABEL_TRANSLATION_POSTFIX[this.sectionName]}`
      );
    }
  },
  methods: {
    recalculate() {
      return this.$emit('recalculate');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/css/common/icons.css';

.section-header {
  padding: 15px;
  height: 70px;

  &__info {
    width: 66%;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    color: $text-color;
  }

  &__title,
  &__details,
  &__recalculate {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.details {
  &__active-time {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;

    color: $text-color;

    margin-bottom: 3px;
    white-space: nowrap;
  }

  &__duration {
    font-size: 14px;
    line-height: 16px;

    color: $grey-color;
  }
}

.active-time {
  &__divider {
    color: $grey-color;
  }
}

.recalculate-button {
  color: $text-color;

  &__icon {
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  .section-header {
    flex-wrap: wrap;
    align-items: center;
    height: auto;

    padding: 13px 15px 0;

    &__info {
      width: 100%;
      min-height: 36px;

      align-items: flex-start;
    }

    &__title {
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;

      text-align: left;
      flex-basis: 50%;

      margin-bottom: 10px;
    }

    &__details {
      flex-basis: 50%;
    }

    &__divider {
      margin: 8px 15px 0;
    }

    &__recalculate {
      flex-grow: 1;
      justify-content: center;
      margin: 18px 0 8px;
    }
  }

  .details {
    align-items: flex-end;

    &--from-scratch {
      align-items: flex-end;
    }

    &__duration {
      font-size: 12px;
      line-height: 14px;
    }

    &__active-time {
      font-size: 12px;
      line-height: 19px;
      font-weight: 500;
    }
  }

  .recalculate {
    &--from-scratch {
      display: none;
    }

    &--no-diagnostic {
      justify-content: center;
      flex-grow: 1;
    }
  }

  .recalculate-button {
    padding: 6px 15px 6px 12px;
  }
}

@media (max-width: 400px) {
  .details {
    &__active-time {
      white-space: normal;
      text-align: right;
    }
  }
}
</style>
