<template>
  <warning-modal
    class="inform-modal"
    name="inaccessible-universkin-m-inform-modal"
    :title="$t('dashboard.modal.unavailableUniverskinM.title')"
    large-screen-width="460"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <p>
        {{ $t('dashboard.modal.unavailableUniverskinM.description') }}
      </p>
    </template>

    <template #actions>
      <button class="rounded-button-white" @click="handleClose(true)">
        {{ $t('dashboard.button.ok') }}
      </button>
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';

export default {
  name: 'InaccessibleUniverskinMInformModal',
  components: { WarningModal },
  data() {
    return { onClose: null };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons.scss';
</style>
