<template>
  <div class="select-question flex-column--align-center">
    <span class="select-question__label main-text text-center mb20">
      {{ questionText }}
    </span>
    <q-select
      label="label"
      :allow-empty="false"
      :value="value"
      :options="valueBindings"
      :placeholder="$t(placeholderKey)"
      class="select-question__select-element custom--with-border"
      :class="{ 'custom--error': isSelectInvalid }"
      track-by="label"
      @input="onSelectInput"
    />
  </div>
</template>

<script>
import { get } from 'lodash';

import QSelect from '@/modules/dashboard/components/common/Select';

export default {
  name: 'SelectQuestion',
  components: { QSelect },
  inject: ['$validator'],
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return get(this.value, 'value');
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    valueBindings: {
      type: Array,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isQuestionKey: {
      type: Boolean,
      default: false
    },
    placeholderKey: {
      type: String,
      default: 'dashboard.medicalData.selectOption'
    }
  },
  computed: {
    questionText() {
      return this.isQuestionKey ? this.$t(this.question) : this.question;
    },
    isSelectInvalid() {
      return this.errors.has(this.name);
    }
  },
  methods: {
    onSelectInput(answer) {
      this.$emit('input', answer);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/text';

.select-question {
  &__select-element {
    width: 240px;
  }
}
</style>
