<template>
  <div class="serum__container">
    <div
      v-if="value.isDuplicated"
      class="serum serum--duplicate"
      :class="duplicateSerumClasses"
    ></div>
    <section class="serum flex">
      <div class="serum__actions flex-column--align-center">
        <span class="custom-icon icon-close" @click="deleteSerum"></span>
        <span
          v-if="isMicroneedlingAvailable"
          v-tooltip="{
            content: $t('dashboard.microneedling.applyProcedure'),
            trigger: tooltipTrigger
          }"
          class="custom-icon mt20"
          :class="microneedlingIconClasses"
          @click="toggleMicroneedling(!isMicroneedlingActiveForBottle)"
        ></span>
        <span
          v-if="canBeDuplicated"
          v-tooltip="{ content: $t('dashboard.action.duplicateSerum'), trigger: tooltipTrigger }"
          class="custom-icon icon-copy-serum mt20"
          :class="{ 'icon-copy-serum--active': value.isDuplicated }"
          @click="toggleDuplication(value.isDuplicated)"
        ></span>
      </div>
      <div class="serum__options options">
        <div class="options__header header flex--align-center">
          <div class="header__icon product-header-icon mr10">
            P
          </div>
          <div class="header__info info flex-column">
            <div class="flex--align-center">
              <span class="info__text">Universkin Serum P</span>
              <span
                v-tooltip="{
                  content: $t('dashboard.label.serumPTooltip'),
                  classes: 'custom-tooltip serum-tooltip',
                  trigger: tooltipTrigger
                }"
                class="info__icon custom-icon icon-info"
              ></span>
            </div>
            <div v-if="isRecommended" class="flex--align-center">
              <span class="custom-icon icon-check-green mr5"></span>
              <i class="recommended-text">{{ $t('dashboard.label.recommended') }}</i>
            </div>
          </div>
        </div>
        <div class="options__actives">
          <active-ingredients-dropdown
            v-for="ingredient in activeIngredients"
            :key="ingredient"
            class="mb10"
            :value="value[ingredient]"
            :can-change-concentration="canChangeConcentration"
            :disabled-ingredients="disabledIngredients"
            :section="section"
            @update-ingredient="updateIngredient(ingredient, $event)"
          />
        </div>
      </div>
      <div class="serum__usage usage flex-column--align-center space-between">
        <time-usage-dropdown
          :usage="value.usage"
          :options="activeTimeUsage.options"
          @select-usage="selectUsage"
          @click.native="onDropdownClick($event, 'usage', value.usage, activeTimeUsage.options)"
        />
        <div class="usage__bottle bottle flex-column--align-center">
          <span class="custom-icon icon-universkin-serum-p-active cursor-default mb5"></span>
          <span class="bottle__capacity">{{ serumSize }}</span>
        </div>
      </div>
      <microneedling-serum-badge
        v-if="isMicroneedlingActiveForBottle"
        class="serum__microneedling-badge"
        :needle-size="microneedlingConfig.needleSize"
        :volume="microneedlingConfig.volume"
        :is-needle-size-editable="isNeedleSizeEditable"
        @select-size="onNeedleSizeChange"
        @close="toggleMicroneedling(false)"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { compact, some, get } from 'lodash';

import ActiveIngredientsDropdown from '@/modules/dashboard/components/create-treatment-tab/ActiveIngredientsDropdown';
import TimeUsageDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TimeUsageDropdown';
import DropdownConfig from '@/modules/dashboard/components/create-treatment-tab/treatments/DropdownConfig';
import MicroneedlingSerumBadge from '@/modules/dashboard/components/microneedling/MicroneedlingSerumBadge';

import { microneedlingValidations } from '@/modules/dashboard/mixins/microneedling/microneedlingValidations';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types } from '@/modules/dashboard/store/types';
import {
  DEFAULT_COMPOSITION_BOTTLE_VALUES,
  TIME_USAGE_DROPDOWN_OPTIONS,
  SERUM_USAGE_KEYS,
  TREATMENT_SECTION_NAME,
  ACTIVE_INGREDIENTS_KEYS,
  SERUM_BOTTLE_NUMBER
} from '@/modules/dashboard/api/constants';

import {
  validateNeedleSize,
  validateActiveIngredient,
  getIsNeedleSizeEditable
} from '@/modules/dashboard/business-logic/microneedling';

const TIME_USAGE_DROPDOWN_CONFIG = new DropdownConfig(
  SERUM_USAGE_KEYS.MORNING_ONLY,
  TIME_USAGE_DROPDOWN_OPTIONS
);

export default {
  name: 'Serum',
  components: {
    TimeUsageDropdown,
    ActiveIngredientsDropdown,
    MicroneedlingSerumBadge
  },
  mixins: [microneedlingValidations],
  props: {
    value: {
      type: Object,
      default: () => ({
        ...DEFAULT_COMPOSITION_BOTTLE_VALUES
      })
    },
    bottleNumber: {
      type: String,
      default: SERUM_BOTTLE_NUMBER.FIRST,
      validator: value => Object.values(SERUM_BOTTLE_NUMBER).includes(value)
    },
    canBeDuplicated: {
      type: Boolean,
      default: true
    },
    canChangeConcentration: {
      type: Boolean,
      default: true
    },
    isRecommended: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: TREATMENT_SECTION_NAME.TREAT_FACE
    }
  },
  data() {
    return {
      activeTimeUsage: TIME_USAGE_DROPDOWN_CONFIG,
      activeIngredients: ACTIVE_INGREDIENTS_KEYS,
      usageOptions: SERUM_USAGE_KEYS
    };
  },
  computed: {
    ...mapGetters({
      isMicroneedlingAvailable: types.getters.IS_MICRONEEDLING_AVAILABLE,
      currentPatientId: types.getters.CURRENT_PATIENT_ID
    }),
    disabledIngredients() {
      const { actif1, actif2, actif3 } = this.value;

      return compact([actif1, actif2, actif3]);
    },
    serumSize() {
      return this.value.isDuplicated ? '15+15ml' : '15 ml';
    },
    isMicroneedlingActiveForBottle() {
      return this.isMicroneedlingAvailable && this.value.microneedling;
    },
    microneedlingIconClasses() {
      return this.isMicroneedlingActiveForBottle
        ? 'icon-microneedling-circle-active'
        : 'icon-microneedling-circle';
    },
    duplicateSerumClasses() {
      return { 'serum--duplicate-with-microneedling': this.isMicroneedlingActiveForBottle };
    },
    microneedlingConfig() {
      return this.value.microneedling || {};
    },
    isNeedleSizeEditable() {
      return getIsNeedleSizeEditable({ sectionName: this.section });
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  methods: {
    ...mapActions({
      setMicroneedlingOn: types.actions.SET_MICRONEEDLING_ON,
      setMicroneedlingOff: types.actions.SET_MICRONEEDLING_OFF
    }),
    async onDropdownClick(event, dropdownType, value, options) {
      if (!isMobileDevice()) {
        return;
      }

      const selectedOption = await this.showOptionsModalOnMobile(dropdownType, value, options);

      this.selectUsage(selectedOption);

      event.stopPropagation();
    },
    showOptionsModalOnMobile(type, value, options) {
      return new Promise(resolve => {
        this.$modal.show('treatment-product-dropdown-modal', {
          type,
          value,
          options,
          optionResolver: resolve
        });
      });
    },
    async validateActiveIngredient(activeIngredient, isMicroneedling) {
      const {
        shouldReceiveConsent: isForbiddenIngredient,
        shouldRejectOnReceivingConsent
      } = validateActiveIngredient({
        activeIngredient,
        isMicroneedling
      });

      if (isForbiddenIngredient) {
        const { confirm } = await this.$asyncModal.show('forbidden-ingredient-warning-modal');

        return { confirm: !shouldRejectOnReceivingConsent && confirm };
      }

      return { confirm: true };
    },
    async updateIngredient(ingredient, ingredientValue) {
      const { confirm } = await this.validateActiveIngredient(
        ingredientValue && ingredientValue.name,
        this.isMicroneedlingActiveForBottle
      );

      if (!confirm) {
        return;
      }

      this.$emit('input', { ...this.value, [ingredient]: ingredientValue });
    },
    selectUsage(usage) {
      this.$emit('input', { ...this.value, usage });
      this.$emit('select-usage', usage);
    },
    toggleDuplication(isDuplicated) {
      this.$emit('input', { ...this.value, isDuplicated: !isDuplicated });
    },
    deleteSerum() {
      this.$emit('delete-serum');
    },
    async onNeedleSizeChange(size) {
      const { shouldReceiveConsent, shouldRejectOnReceivingConsent } = await validateNeedleSize({
        needleSize: size,
        patientId: this.currentPatientId
      });

      if (shouldReceiveConsent) {
        const { confirm } = await this.$asyncModal.show('thin-skin-warning-modal');

        if (shouldRejectOnReceivingConsent || !confirm) {
          return;
        }
      }

      this.setMicroneedlingOn({
        sectionName: this.section,
        usage: this.value.usage,
        needleSize: size,
        bottleNumber: this.bottleNumber
      });
    },
    async validateActiveIngredients() {
      const activeIngredientNames = compact(
        ACTIVE_INGREDIENTS_KEYS.map(activeIngredientKey =>
          get(this.value, [activeIngredientKey, 'name'])
        )
      );

      const results = await Promise.all(
        activeIngredientNames.map(name => this.validateActiveIngredient(name, true))
      );

      if (some(results, ({ confirm }) => !confirm)) {
        return { confirm: false };
      }

      return { confirm: true };
    },
    async toggleMicroneedling(activate) {
      const sectionName = this.section;

      if (!activate) {
        this.setMicroneedlingOff({ sectionName });

        return;
      }

      const { confirm: activesConfirmed } = await this.validateActiveIngredients();

      if (!activesConfirmed) {
        return;
      }

      const { confirm } = await this.askForMicroneedlingValidation({
        treatmentSectionName: this.section
      });

      if (!confirm) {
        return;
      }

      this.setMicroneedlingOn({
        sectionName,
        usage: this.value.usage,
        needleSize: this.needleSize,
        bottleNumber: this.bottleNumber
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/products';
@import '../../../../../../assets/css/common/tooltips.css';
@import '../../../../../../assets/scss/common/text';

.serum {
  position: relative;
  flex-wrap: wrap;

  padding: 10px 19px 10px 16px;

  background-color: $white;
  box-shadow: 0 6px 7px rgba(148, 166, 176, 0.25);
  border-radius: 8px;

  &__microneedling-badge {
    flex: 1 0 auto;
    margin-top: 5px;
  }

  &__container {
    position: relative;
  }

  &__actions {
    width: 19px;
    padding-top: 5px;
  }

  &__options {
    padding-left: 15px;
  }

  &__usage {
    padding: 2px 0 10px;
  }

  &--duplicate {
    position: absolute;
    bottom: -10px;
    right: -10px;

    width: 100%;
    height: 180px;
  }

  &--duplicate-with-microneedling {
    height: 245px;
  }
}

.serum__options {
  margin-right: auto;
  max-width: calc(100% - 72px);
  width: 100%;
}

.options {
  &__actives {
    min-width: 100%;

    margin: 10px 0;
  }
}

.header {
  &__info {
    padding-right: 20px;

    &:hover {
      padding-right: 0;

      .info {
        &__icon {
          display: block;
        }
      }
    }
  }
}

.info {
  &__text {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;

    color: $text-color;
  }

  &__icon {
    display: block;
  }
}

.bottle {
  align-self: flex-end;

  &__capacity {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    color: $grey-color;
  }
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

@media (max-width: 991px) {
  .serum {
    &__options {
      max-width: calc(100% - 68px);
    }
  }
}

@media (max-width: 767px) {
  .serum {
    width: 310px;
    padding: 10px 20px 10px 15px;

    &--duplicate {
      right: auto;

      width: 310px;
    }

    &--duplicate-with-microneedling {
      height: 283px;
    }

    &__options {
      max-width: calc(100% - 63px);
      padding-left: 10px;
    }
  }

  .info {
    padding-right: 0;

    &__text {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }

    &__icon {
      display: block;
    }
  }

  .usage {
    &__bottle {
      margin-right: 4px;
    }
  }

  .options {
    &__actives {
      width: 200px;
      min-width: 200px;

      margin: 10px 0;
    }
  }
}
</style>
