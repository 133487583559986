<template>
  <main ref="content" class="summary-treatment__content">
    <section class="summary-treatment__summary-info-container">
      <summary-info label-key="dashboard.label.newTreatmentFor" />
    </section>
    <div class="flex">
      <div class="summary-treatment__column mr30">
        <summary-treatment-composition />
      </div>
      <div class="summary-treatment__column">
        <summary-treatment-details />
      </div>
    </div>
  </main>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import SummaryInfo from '@/modules/dashboard/components/create-treatment-tab/SummaryInfo';
import SummaryTreatmentComposition from '@/modules/dashboard/components/create-treatment-tab/SummaryTreatmentComposition';
import SummaryTreatmentDetails from '@/modules/dashboard/components/create-treatment-tab/SummaryTreatmentDetails';

import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'SummaryTreatment',
  components: {
    SummaryInfo,
    SummaryTreatmentComposition,
    SummaryTreatmentDetails
  },
  data() {
    return {
      scrollbar: null
    };
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar(this.$refs.content, DEFAULT_PERFECT_SCROLLBAR_OPTIONS);
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/perfect-scrollbar-styles';

.summary-treatment {
  &__summary-info-container {
    width: 400px;
    margin: 15px auto 50px;
  }

  &__content {
    height: 580px;
    padding: 35px;
    position: relative;
  }

  &__column {
    width: calc(50% - 12.5px);
    flex-shrink: 0;
  }
}
</style>
