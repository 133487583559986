<template>
  <div
    class="treatment__container"
    :class="{ open: isOpened, 'treatment__container--is-coming-soon': !hasMoisturizeProducts }"
  >
    <treatment-header
      :title="$t('dashboard.label.rebalanceMoisturize')"
      :icons="moisturizeIconsConfig"
      :is-opened="isOpened"
      :price="moisturizeTreatmentPrice"
      :has-recommendation="hasTreatmentRecommendation && hasMoisturizeProducts"
      :is-coming-soon="!hasMoisturizeProducts"
      @section-toggle="toggleSection"
    />
    <slide-down :show="isOpened">
      <div
        slot="slide-body"
        class="flex--center--center treatment__content"
        :class="{ 'treatment__content--opened': isOpened }"
      >
        <template v-for="product in products">
          <complementary-care-product
            :key="product.name"
            :product="product"
            :is-active="getIsProductActive(product.name)"
            :is-disabled="getIsProductDisabled(product.name)"
            :disabled-tooltip-text="$t('dashboard.warning.universkinSelectedWithinTreat')"
            @toggle-active="updateProduct"
            @update-product="updateProduct($event, true)"
          />
        </template>
      </div>
    </slide-down>

    <moisturize-usage-selection-modal />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';

import TreatmentHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentHeader';
import ComplementaryCareProduct from '@/modules/dashboard/components/create-treatment-tab/treatments/ComplementaryCareProduct';
import MoisturizeUsageSelectionModal from '@/modules/dashboard/components/create-treatment-tab/treatments/MoisturizeUsageSelectionModal';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';

import SectionIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/SectionIcon';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_SECTION_NAMES,
  TREATMENT_BOTTLE_ICONS_CONFIG,
  PRODUCT_REFERENCES
} from '@/modules/dashboard/api/constants';

const MOISTURIZE_PRODUCTS = [
  PRODUCT_REFERENCES.NEXULTRA_P_LIGHT,
  PRODUCT_REFERENCES.NEXULTRA_P_RICH,
  PRODUCT_REFERENCES.NEXULTRA_1,
  PRODUCT_REFERENCES.NEXULTRA_2,
  PRODUCT_REFERENCES.NEXULTRA_3
];

export default {
  name: 'RebalanceAndMoisturize',
  components: {
    TreatmentHeader,
    ComplementaryCareProduct,
    MoisturizeUsageSelectionModal,
    SlideDown
  },
  data() {
    return {
      isOpened: false
    };
  },
  computed: {
    ...mapState({
      moisutrizeUsages: state => state.dashboard.moisturizeUsages
    }),
    ...mapGetters({
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      moisturizeProducts: dashTypes.getters.MOISTURIZE_PRODUCTS,
      moisturizeProductsReferences: dashTypes.getters.MOISTURIZE_PRODUCTS_REFERENCES,
      moisturizeTreatment: dashTypes.getters.MOISTURIZE_TREATMENT,
      universkinHSectionSelection: dashTypes.getters.UNIVERSKIN_H_SECTION_SELECTION,
      moisturizeTreatmentPrice: dashTypes.getters.MOISTURIZE_TREATMENT_PRICE
    }),
    moisturizeIconsConfig() {
      return this.moisturizeProductsReferences.map(reference => {
        let isProductActive = this.moisturizeTreatment.includes(reference);

        if (reference === PRODUCT_REFERENCES.UNIVERSKIN_H) {
          isProductActive = this.isUniveskinHInMoisturizeSection && isProductActive;
        }

        return new SectionIcon(TREATMENT_BOTTLE_ICONS_CONFIG[reference], isProductActive);
      });
    },
    isUniveskinHInMoisturizeSection() {
      return this.universkinHSectionSelection === CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE;
    },
    isUniverskinHDisabled() {
      return (
        this.moisturizeTreatment.includes(PRODUCT_REFERENCES.UNIVERSKIN_H) &&
        !this.isUniveskinHInMoisturizeSection
      );
    },
    hasMoisturizeProducts() {
      return !isEmpty(this.moisturizeProducts);
    },
    moisutrizeUsagesKeys() {
      return Object.keys(this.moisutrizeUsages);
    },
    moisutrizeUsagesValues() {
      return Object.values(this.moisutrizeUsages);
    },
    availableUsages() {
      return this.moisutrizeUsagesKeys.filter(key => !this.moisutrizeUsages[key]) || [];
    },
    products() {
      if (!this.hasMoisturizeProducts) {
        return [];
      }

      // Modifies next moisturize products usages to follow moisturize limitation rules.
      // Product usage will be its usage if it is available or first usage from availableUsages list
      // if product's usage is not available
      return this.moisturizeProducts.map(product => {
        const { name, usage: productUsage } = product;

        if (
          !MOISTURIZE_PRODUCTS.includes(name) ||
          this.moisutrizeUsagesValues.includes(name) ||
          name === PRODUCT_REFERENCES.NEXULTRA_L
        ) {
          return product;
        }

        const usage = this.availableUsages.includes(productUsage)
          ? productUsage
          : this.availableUsages[0];

        return { ...product, usage };
      });
    }
  },
  methods: {
    ...mapActions({
      updateMoisturazeProducts: dashTypes.actions.UPDATE_MOISTURIZE_PRODUCTS,
      setUniverskinHSectionSelection: dashTypes.actions.SET_UNIVERSKIN_H_SECTION_SELECTION
    }),
    toggleSection() {
      if (this.hasMoisturizeProducts) {
        this.isOpened = !this.isOpened;
      }
    },
    getIsProductDisabled(productName) {
      return productName === PRODUCT_REFERENCES.UNIVERSKIN_H ? this.isUniverskinHDisabled : false;
    },
    getIsProductActive(product) {
      if (product === PRODUCT_REFERENCES.UNIVERSKIN_H && !this.isUniveskinHInMoisturizeSection) {
        return false;
      }

      return this.moisturizeTreatment.includes(product);
    },
    async updateProduct(productInfo, isUpdate = false) {
      const { product, isActive } = productInfo;
      const { name } = product;

      if (name === PRODUCT_REFERENCES.UNIVERSKIN_H) {
        await this.setUniverskinHSectionSelection(
          isActive ? CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE : null
        );
      }

      this.updateMoisturazeProducts({
        ...productInfo,
        section: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
        isUpdate,
        modal: this.$modal
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/treatment/create-treatment-shared';

.treatment {
  &__content {
    min-height: 240px;
  }
}

@media (max-width: 767px) {
  .treatment {
    &__content {
      height: auto;
    }
  }
}
</style>
