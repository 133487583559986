<template>
  <section>
    <mobile-header class="send-offer-section__header header">
      <span slot="back-button" class="custom-icon icon-close mr15" @click="closePage"></span>
      <span slot="header-title" class="header__label">
        {{ $t('dashboard.label.sendOffer') }}
      </span>
    </mobile-header>
    <main ref="content" class="send-offer-section__content flex-column flex--align-center">
      <section class="content__summary-info-section">
        <summary-info label-key="dashboard.label.treatmentOfferFor" use-active-promo-price />
      </section>
      <button class="rounded-button-gray" @click="onReviewOfferCompositionClick">
        {{ $t('dashboard.label.reviewOfferComposition') }}
      </button>
      <section class="content__participant-select">
        <loyalty-participant-select
          :selected-participant="selectedParticipant"
          @select-participant="onParticipantSelect"
        />
      </section>
      <section class="content__steps-section flex">
        <div class="steps-section__cards-icon icon-credit-cards custom-icon"></div>
        <div class="flex-column">
          <span class="steps-section__title">{{ $t('dashboard.label.howItWorks') }}</span>
          <ol class="steps-list">
            <li v-for="step in sendOfferSteps" :key="step">
              {{ step }}
            </li>
          </ol>
        </div>
      </section>
    </main>
    <mobile-fixed-footer class="send-offer-section__mobile-footer">
      <footer slot="footer" class="send-offer-section__footer flex--center--center">
        <button class="otherXS cancel-button mr30" @click="closeModal">
          {{ $t('dashboard.action.cancel') }}
        </button>
        <button class="rounded-button-white" @click="onNextClick">
          {{ $t('dashboard.action.next') }}
        </button>
      </footer>
    </mobile-fixed-footer>

    <finish-send-offer-modal class="onlyXS" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { get } from 'lodash';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import SummaryInfo from '@/modules/dashboard/components/create-treatment-tab/SummaryInfo';
import LoyaltyParticipantSelect from '@/modules/dashboard/components/create-treatment-tab/LoyaltyParticipantSelect';
import FinishSendOfferModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/FinishSendOfferModal';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';

import treatmentPreviewMixin from '@/modules/dashboard/components/mixins/treatmentPreviewMixin';

import { createTreatmentPreview } from '@/modules/dashboard/api';
import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import {
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  REVIEW_TREATMENT_MESSAGE_TYPE
} from '@/modules/dashboard/api/constants';
import { getTreatmentType } from '@/modules/dashboard/utils/create-treatment-utils';

export default {
  name: 'SendOffer',
  components: {
    MobileHeader,
    SummaryInfo,
    LoyaltyParticipantSelect,
    FinishSendOfferModal,
    MobileFixedFooter
  },
  mixins: [treatmentPreviewMixin],
  data() {
    return {
      selectedParticipant: null,
      scrollbar: null
    };
  },
  computed: {
    ...mapGetters({
      patientFirstName: types.getters.CURRENT_PATIENT_FIRST_NAME,
      currentPatientId: types.getters.CURRENT_PATIENT_ID,
      recommendationDiagnostic: types.getters.RECOMMENDATION_DIAGNOSTIC,
      treatmentDirectoryRecommendations: types.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS
    }),
    sendOfferSteps() {
      return [
        `${this.$t('dashboard.label.receivesLink', [this.patientFirstName])}`,
        `${this.$t('dashboard.label.canUpdate', [this.patientFirstName])}`,
        `${this.$t('dashboard.label.placesOrder', [this.patientFirstName])}`,
        `${this.$t('dashboard.label.yourConsultationTime', [this.patientFirstName])}`
      ];
    }
  },
  mounted() {
    if (isMobileDevice()) {
      return;
    }

    this.scrollbar = new PerfectScrollbar(this.$refs.content, DEFAULT_PERFECT_SCROLLBAR_OPTIONS);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      addRequestError: types.actions.ADD_ERROR,
      setSummaryTreatmentId: types.actions.SET_SUMMARY_TREATMENT_ID
    }),
    onReviewOfferCompositionClick() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'OfferComposition' });

        return;
      }

      this.$modal.show('offer-composition-modal');
      this.closeModal();
    },
    onParticipantSelect(participant) {
      this.selectedParticipant = participant;
    },
    closePage() {
      this.$router.go(-1);
    },
    closeModal() {
      this.$modal.hide('send-offer-modal');
    },
    async onNextClick() {
      this.setLoading(true);

      try {
        const participantId = get(this.selectedParticipant, 'value', null);
        const diagnosticId = get(this.recommendationDiagnostic, 'id', null);

        const type = getTreatmentType({
          isTreatmentDirectory: !!this.treatmentDirectoryRecommendations,
          diagnosticId
        });

        const treatmentDirectoryId = get(
          this.treatmentDirectoryRecommendations,
          'treatmentRecommendationId',
          null
        );

        const treatment = {
          ...this.getTreatmentForPreview(),
          paid: false,
          startDate: null,
          started: false,
          patientId: this.currentPatientId,
          treatmentDirectoryId,
          participantId,
          diagnosticId,
          type,
          onlineOffer: true
        };

        const { data: treatmentId } = await createTreatmentPreview(
          this.currentPatientId,
          treatment
        );

        await this.setSummaryTreatmentId(treatmentId);
      } catch (error) {
        this.addRequestError('Treatment is not created');
      } finally {
        this.setLoading(false);
      }

      if (isMobileDevice()) {
        this.$router.push({
          name: 'ReviewPatientInviteTabs',
          params: {
            id: this.currentPatientId,
            templateType: REVIEW_TREATMENT_MESSAGE_TYPE.SEND_OFFER
          }
        });

        return;
      }

      this.$modal.show('review-send-offer-message-modal');
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.send-offer-section {
  &__content {
    height: 570px;
    padding: 15px 15px 30px;
    position: relative;

    @media (max-height: 700px) {
      height: 420px;
    }
  }

  &__footer {
    height: 70px;

    border-top: 1px solid $dim-white;
  }
}

.content {
  &__summary-info-section {
    width: 400px;

    margin: 35px 0 40px;
  }

  &__participant-select {
    width: 350px;
    margin: 50px 0 40px;
    padding: 30px 25px 40px;
    background: $background;
    border-radius: 8px;
  }

  &__steps-section {
    padding: 0 52px;
  }
}

.steps-section {
  &__cards-icon {
    margin-right: 30px;
  }

  &__title {
    font-weight: 600;
  }
}

.steps-list {
  list-style: none;
  counter-reset: steps-counter;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    counter-increment: steps-counter;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    line-height: 21px;
    color: $text-color;

    &::before {
      content: counter(steps-counter) ' •';
      position: absolute;
      left: 0;
      font-weight: bold;
    }
  }
}

@media (max-width: 767px) {
  .send-offer-section {
    &__header {
      height: 45px;
      padding: 0 15px;

      &.header {
        background-color: $background;
      }
    }

    &__content {
      height: auto;
      padding: 60px 20px 130px;
    }

    &__footer {
      height: 60px;
    }

    &__mobile-footer {
      background: $white;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .header {
    &__label {
      font-size: 13px;
      font-weight: 400;
      color: $text-color;
    }
  }

  .content {
    &__summary-info-section {
      width: 100%;

      margin: 0 0 30px;
    }

    &__participant-select {
      width: 100%;
      max-width: 350px;

      margin: 40px 0 35px;
      padding: 0;
    }

    &__steps-section {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  .steps-section {
    &__cards-icon {
      margin: 0 0 28px;
    }

    &__title {
      font-size: 13px;
      line-height: 21px;
    }
  }

  .steps-list {
    li {
      padding-top: 5px;
      font-size: 13px;
      line-height: 15px;
    }
  }
}
</style>
