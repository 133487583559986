<template>
  <modal
    name="treatment-directory-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    :click-to-close="false"
    width="800px"
    height="700px"
  >
    <div class="modal-container">
      <treatment-directory />
    </div>
  </modal>
</template>

<script>
import TreatmentDirectory from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentDirectory';

export default {
  name: 'TreatmentDirectoryModal',
  components: { TreatmentDirectory }
};
</script>
