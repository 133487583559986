<template>
  <div class="template__container flex-column">
    <mobile-header v-if="!customTemplateData" class="mobile-header__container">
      <div slot="header-title" class="template__mobile-header ellipsized">
        {{ mobileHeaderTitle }}
      </div>
    </mobile-header>

    <slot name="tabs" class="onlyXS"></slot>

    <div class="template__content">
      <div v-if="!customTemplateData" class="template__description">
        <i>{{ templateDescriptionText }}</i>
      </div>
      <div class="template__language-select mb20">
        <language-dropdown-input :language="language" @language-select="changeLanguage" />
      </div>
      <div class="divider otherXS"></div>
      <div v-if="!isSmsNotificationTemplate" class="template__subject mb15">
        <div class="template__label">{{ $t('dashboard.label.subject') }}</div>
        <input
          :value="templateData.subject"
          type="text"
          class="rounded-input template__input input-focus-within"
          @input="onSubjectInput"
          @change="updateCurrentTemplateField({ fieldName: 'subject', value: $event.target.value })"
        />
      </div>
      <component
        :is="messageTemplateComponent"
        :notification-type="notificationType"
        :template-type="templateType"
        :template-data="templateData"
        :fields-max-length="fieldsMaxLength"
        @field-input="onFieldInput"
        @field-change="updateCurrentTemplateField"
      >
        <template slot="treatment-details">
          <slot name="treatment-details"></slot>
        </template>
      </component>
      <div v-if="isSmsNotificationTemplate" class="template__counter mb20">
        <i class="counter__text">
          {{ $t('dashboard.label.charactersLeft', [remainingCharacters]) }},
          {{ $t('dashboard.action.smsCounter', [smsCount, 5]) }}
        </i>
      </div>
      <div
        v-if="!customTemplateData"
        class="template__footer template-footer flex--space-between--center"
        :class="{ mt30: !isSmsNotificationTemplate }"
      >
        <button
          class="template-footer__update-button rounded-button-white"
          @click="updateNotificationTemplate"
        >
          {{ $t('dashboard.action.updateTemplate') }}
        </button>
        <div>
          <div
            class="template-footer__reset-button rounded-button-gray"
            @click="resetTextToDefault"
          >
            {{ $t('dashboard.action.resetTextToDeafault') }}
          </div>
        </div>
      </div>
    </div>

    <unsaved-changes-modal class="onlyXS" />
    <dashboard-notification />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { pick, get } from 'lodash';
import Splitter from 'split-sms';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import LanguageDropdownInput from '@/modules/dashboard/components/profile/LanguageDropdownInput';
import DashboardNotification from '@/modules/dashboard/components/dashboard/common/DashboardNotification';
import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';
import DefaultNotificationTemplateMessage from '@/modules/dashboard/components/message-templates/notification/DefaultNotificationTemplateMessage';
import TreatmentOfferEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentOfferEmailTemplate';
import TreatmentRenewOfferEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentRenewOfferEmailTemplate';
import TreatmentRenewOfferMicroneedlingEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentRenewOfferMicroneedlingEmailTemplate';
import QuestionnaireCompletedEmailTemplate from '@/modules/dashboard/components/message-templates/notification/QuestionnaireCompletedEmailTemplate';
import IncompleteQuestionnaireEmailTemplate from '@/modules/dashboard/components/message-templates/notification/IncompleteQuestionnaireEmailTemplate';
import TreatmentCompletedEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentCompletedEmailTemplate';
import TreatmentCompletedMicroneedlingEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentCompletedMicroneedlingEmailTemplate';
import TreatmentCreatedEmailTemplate from '@/modules/dashboard/components/message-templates/notification/TreatmentCreatedEmailTemplate';
import ReminderOfferEmailTemplate from '@/modules/dashboard/components/message-templates/notification/ReminderOfferEmailTemplate';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';
import updateTemplateFieldMixin from '@/modules/dashboard/components/mixins/updateTemplateFieldMixin';

import {
  getNotificationTemplate,
  updateNotificationTemplate,
  deleteMessageTemplate
} from '@/modules/dashboard/api';

import {
  isMobileDevice,
  getStringLength,
  getNonNegativeNumber,
  buildNotificationTemplateText
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION } from '@/constants';

import {
  NOTIFICATION_TEMPLATES_TYPES,
  MESSAGE_TEMPLATE_TYPES,
  MESSAGE_MAX_LENGTH,
  SMS_MAX_LENGTH,
  TEMPLATE_TYPE_NAME,
  NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX,
  NOTIFICATION_TEMPLATE_DESCRIPTION_TRANSLATION_POSTFIX,
  MESSAGE_TEMPLATES_TAB_NAMES,
  SMS_TEMPLATE_CONST_SYMBOLS,
  SMS_TEMPLATE_CONST_SYMBOLS_WITH_LINK,
  EMAIL_TEMPLATE_DATA_DEFAULT_VALUES,
  MESSAGE_SECTION_VALUES
} from '@/modules/dashboard/api/constants';

const NOTIFICATION_SMS_TEMPLATES_WITH_LINK = [
  NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE,
  NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED
];

const NEW_TREATMENT_DESCRIPTION_TRANSLATION_KEYS_BY_TYPE = {
  [MESSAGE_TEMPLATE_TYPES.EMAIL]: 'treatmentCreatedMail',
  [MESSAGE_TEMPLATE_TYPES.SMS]: 'treatmentCreatedSms'
};

const NOTIFICATION_TEMPLATE_LENGTH_FIELD_NAMES = [
  'greeting',
  'bodyPartOne',
  'bodyPartTwo',
  'bodyPartThree',
  'bodyPartFour',
  'signature'
];

// We are using this value because curly brace takes 2 length in sms counter.
const CURLY_BRACES_COUNTER_VALUE = 2;

const MESSAGE_TEMPLATE_COMPONENT_NAMES = {
  DEFAULT_TEMPLATE: 'DefaultNotificationTemplateMessage',
  TREATMENT_OFFER_EMAIL_TEMPLATE: 'TreatmentOfferEmailTemplate',
  TREATMENT_RENEW_OFFER_EMAIL_TEMPLATE: 'TreatmentRenewOfferEmailTemplate',
  TREATMENT_RENEW_OFFER_MICRONEEDLING_EMAIL_TEMPLATE:
    'TreatmentRenewOfferMicroneedlingEmailTemplate',
  QUESTIONNAIRE_COMPLETED_EMAIL_TEMPLATE: 'QuestionnaireCompletedEmailTemplate',
  INCOMPLETE_QUESTIONNAIRE_EMAIL_TEMPLATE: 'IncompleteQuestionnaireEmailTemplate',
  TREATMENT_COMPLETED_EMAIL_TEMPLATE: 'TreatmentCompletedEmailTemplate',
  TREATMENT_COMPLETED_MICRONEEDLING_EMAIL_TEMPLATE: 'TreatmentCompletedMicroneedlingEmailTemplate',
  TREATMENT_CREATED_EMAIL_TEMPLATE: 'TreatmentCreatedEmailTemplate',
  REMINDER_OFFER: 'ReminderOfferEmailTemplate'
};

const MESSAGE_TEMPLATE_COMPONENTS_BY_NOTIFICATION_AND_TEMPLATE_TYPES = {
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]: MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_OFFER_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_RENEW_OFFER_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_RENEW_OFFER_MICRONEEDLING_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.QUESTIONNAIRE_COMPLETED_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.INCOMPLETE_QUESTIONNAIRE_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_COMPLETED_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_COMPLETED_MICRONEEDLING_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]:
      MESSAGE_TEMPLATE_COMPONENT_NAMES.TREATMENT_CREATED_EMAIL_TEMPLATE
  },
  [NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER]: {
    [MESSAGE_TEMPLATE_TYPES.EMAIL]: MESSAGE_TEMPLATE_COMPONENT_NAMES.REMINDER_OFFER
  }
};

export default {
  name: 'NotificationTemplate',
  components: {
    TreatmentCreatedEmailTemplate,
    TreatmentCompletedEmailTemplate,
    TreatmentCompletedMicroneedlingEmailTemplate,
    IncompleteQuestionnaireEmailTemplate,
    QuestionnaireCompletedEmailTemplate,
    TreatmentRenewOfferEmailTemplate,
    TreatmentRenewOfferMicroneedlingEmailTemplate,
    TreatmentOfferEmailTemplate,
    DefaultNotificationTemplateMessage,
    MobileHeader,
    LanguageDropdownInput,
    DashboardNotification,
    UnsavedChangesModal,
    ReminderOfferEmailTemplate
  },
  mixins: [templateUnsavedChangesMixin, updateTemplateFieldMixin],
  props: {
    notificationType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    customTemplateData: {
      type: Object,
      default: null
    },
    defaultLanguage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      language: null,
      defaultTemplateData: {
        ...EMAIL_TEMPLATE_DATA_DEFAULT_VALUES
      },
      lengthData: {
        ...MESSAGE_SECTION_VALUES
      }
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      userLocale: rootTypes.getters.GET_USER_LANGUAGE,
      savedMessageTemplate: types.getters.SAVED_MESSAGE_TEMPLATE,
      currentMessageTemplate: types.getters.CURRENT_MESSAGE_TEMPLATE
    }),
    templateData: {
      get() {
        return this.customTemplateData || this.defaultTemplateData;
      },
      set(updatedTemplateData) {
        if (this.customTemplateData) {
          this.$emit('update-custom-template-data', updatedTemplateData);

          return;
        }

        this.defaultTemplateData = updatedTemplateData;
      }
    },
    messageTemplateComponent() {
      return get(
        MESSAGE_TEMPLATE_COMPONENTS_BY_NOTIFICATION_AND_TEMPLATE_TYPES,
        `${this.notificationType}.${this.templateType}`,
        MESSAGE_TEMPLATE_COMPONENT_NAMES.DEFAULT_TEMPLATE
      );
    },
    isSmsNotificationTemplate() {
      return this.templateType === MESSAGE_TEMPLATE_TYPES.SMS;
    },
    isSmsTemplateWithLink() {
      return NOTIFICATION_SMS_TEMPLATES_WITH_LINK.includes(this.notificationType);
    },
    mobileHeaderTitle() {
      const keyPostfix = NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[this.notificationType];

      return this.$t(`dashboard.label.notification.${keyPostfix}`);
    },
    smsTemplateConstSymbols() {
      return this.isSmsTemplateWithLink
        ? SMS_TEMPLATE_CONST_SYMBOLS_WITH_LINK
        : SMS_TEMPLATE_CONST_SYMBOLS;
    },
    templateDescriptionText() {
      let translationPostfix;

      if (this.notificationType === NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT) {
        translationPostfix = NEW_TREATMENT_DESCRIPTION_TRANSLATION_KEYS_BY_TYPE[this.templateType];

        return this.$t(`dashboard.label.${translationPostfix}`);
      }

      translationPostfix =
        NOTIFICATION_TEMPLATE_DESCRIPTION_TRANSLATION_POSTFIX[this.notificationType];

      const templateTypeName = TEMPLATE_TYPE_NAME[this.templateType];

      return this.$t(`dashboard.label.${translationPostfix}`, [templateTypeName]);
    },
    templateMessageLinkName() {
      return this.savedMessageTemplate.linkName || '';
    },
    templateTextConstSymbols() {
      return this.linkNameLength + this.smsTemplateConstSymbols;
    },
    editableTextMaxLength() {
      return this.smsMaxLength - this.templateTextConstSymbols;
    },
    greetingMaxLength() {
      return this.getFieldLength(this.greetingLength + this.messageSymbolsLeft);
    },
    bodyPartOneMaxLength() {
      return this.getFieldLength(this.bodyPartOneLength + this.messageSymbolsLeft);
    },
    bodyPartTwoMaxLength() {
      return this.getFieldLength(this.bodyPartTwoLength + this.messageSymbolsLeft);
    },
    bodyPartThreeMaxLength() {
      return this.getFieldLength(this.bodyPartThreeLength + this.messageSymbolsLeft);
    },
    bodyPartFourMaxLength() {
      return this.getFieldLength(this.bodyPartFourLength + this.messageSymbolsLeft);
    },
    signatureMaxLength() {
      return this.getFieldLength(this.signatureLength + this.messageSymbolsLeft);
    },
    fieldsMaxLength() {
      return {
        greeting: this.greetingMaxLength,
        bodyPartOne: this.bodyPartOneMaxLength,
        bodyPartTwo: this.bodyPartTwoMaxLength,
        bodyPartThree: this.bodyPartThreeMaxLength,
        bodyPartFour: this.bodyPartFourMaxLength,
        signature: this.signatureMaxLength
      };
    },
    greetingLength() {
      return getStringLength(this.lengthData.greeting);
    },
    bodyPartOneLength() {
      return getStringLength(this.lengthData.bodyPartOne);
    },
    bodyPartTwoLength() {
      return getStringLength(this.lengthData.bodyPartTwo);
    },
    bodyPartThreeLength() {
      return getStringLength(this.lengthData.bodyPartThree);
    },
    bodyPartFourLength() {
      return getStringLength(this.lengthData.bodyPartFour);
    },
    linkNameLength() {
      return this.templateMessageLinkName.length;
    },
    signatureLength() {
      return getStringLength(this.lengthData.signature);
    },
    messageSymbolsLength() {
      return (
        this.greetingLength +
        this.bodyPartOneLength +
        this.bodyPartTwoLength +
        this.bodyPartThreeLength +
        this.bodyPartFourLength +
        this.signatureLength +
        CURLY_BRACES_COUNTER_VALUE
      );
    },
    messageSymbolsLeft() {
      return getNonNegativeNumber(this.editableTextMaxLength - this.messageSymbolsLength);
    },
    smsMaxLength() {
      return SMS_MAX_LENGTH[this.notificationTemplateSmsCounter.characterSet];
    },
    marketingCampaignText() {
      return this.buildTemplateTextWithField();
    },
    notificationTemplateSmsCounter() {
      return Splitter.split(this.marketingCampaignText);
    },
    remainingCharacters() {
      return this.notificationTemplateSmsCounter.remainingInPart;
    },
    smsCount() {
      const { parts } = this.notificationTemplateSmsCounter;

      return parts && parts.length;
    }
  },
  watch: {
    savedMessageTemplate(template) {
      if (this.customTemplateData) {
        return;
      }

      const {
        subject,
        greeting,
        bodyPartOne,
        bodyPartTwo,
        bodyPartThree,
        bodyPartFour,
        signature,
        linkName,
        alternativeLinkName,
        language,
        bestSkinLabel,
        yourDoctorLabel
      } = template;

      const lengthFields = {
        greeting,
        bodyPartOne,
        bodyPartTwo,
        bodyPartThree,
        bodyPartFour,
        signature
      };

      this.templateData = {
        ...this.templateData,
        subject,
        linkName,
        alternativeLinkName,
        language,
        bestSkinLabel,
        yourDoctorLabel,
        ...lengthFields
      };

      this.lengthData = { ...lengthFields };
    },
    customTemplateData(template) {
      this.lengthData = pick(template, NOTIFICATION_TEMPLATE_LENGTH_FIELD_NAMES);
    }
  },
  async mounted() {
    this.language = this.defaultLanguage || this.userLocale;

    if (this.customTemplateData) {
      this.lengthData = pick(this.customTemplateData, NOTIFICATION_TEMPLATE_LENGTH_FIELD_NAMES);

      return;
    }

    await this.getNotificationTemplate();

    if (isMobileDevice()) {
      await this.setActiveMessageTemplateTab(MESSAGE_TEMPLATES_TAB_NAMES.NOTIFICATIONS);
    }
  },
  async destroyed() {
    await this.updateStoreMessageTemplate({});
  },
  methods: {
    ...mapActions({
      setCurrentMessageTemplate: types.actions.SET_CURRENT_MESSAGE_TEMPLATE,
      updateStoreMessageTemplate: types.actions.UPDATE_STORE_MESSAGE_TEMPLATE,
      setActiveMessageTemplateTab: types.actions.SET_ACTIVE_MESSAGE_TEMPLATE_TAB
    }),
    getFieldLength(smsLength) {
      return this.isSmsNotificationTemplate ? getNonNegativeNumber(smsLength) : MESSAGE_MAX_LENGTH;
    },
    buildTemplateTextWithField({ fieldName, fieldValue } = {}) {
      const textObject = {
        ...this.lengthData,
        linkName: this.templateMessageLinkName,
        withLink: this.isSmsTemplateWithLink
      };

      if (fieldName) {
        textObject[fieldName] = fieldValue;
      }

      return buildNotificationTemplateText(textObject);
    },
    onSubjectInput(event) {
      if (!isMobileDevice()) {
        return;
      }

      this.updateCurrentTemplateField({ fieldName: 'subject', value: event.target.value });
    },
    onFieldInput(params) {
      this.updateTemplateField(params);

      if (isMobileDevice()) {
        const { fieldName, event } = params;

        this.updateCurrentTemplateField({ fieldName, value: event.target.value });
      }
    },
    updateCurrentTemplateField({ fieldName, value }) {
      this.templateData = { ...this.templateData, [fieldName]: value };

      this.setCurrentMessageTemplate({ ...this.currentMessageTemplate, [fieldName]: value });
    },
    async getNotificationTemplate() {
      if (!this.userId || !this.templateType || !this.language) {
        return;
      }

      const { data: marketingTemplate } = await getNotificationTemplate({
        userId: this.userId,
        groupType: this.notificationType,
        wayOfSend: this.templateType,
        language: this.language
      });

      await this.updateStoreMessageTemplate(marketingTemplate);
    },
    async changeLanguage(language) {
      if (this.customTemplateData) {
        this.language = language;
        this.$emit('change-language', language);

        return;
      }

      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.language = language;
      await this.getNotificationTemplate();
    },
    async resetTextToDefault() {
      const { id } = this.savedMessageTemplate;

      if (id) {
        await deleteMessageTemplate({
          userId: this.userId,
          wayOfSend: this.templateType,
          templateId: id
        });
      }

      await this.getNotificationTemplate();
    },
    async updateNotificationTemplate() {
      const { data: marketingTemplate } = await updateNotificationTemplate(
        this.userId,
        this.currentMessageTemplate
      );

      await this.updateStoreMessageTemplate(marketingTemplate);

      this.$notify({
        group: 'dashboard-notification',
        title: this.$t('dashboard.label.templateUpdated'),
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/message-templates/message-templates';

.template {
  &__container {
    padding: 30px;
    background: $white;
  }

  &__description {
    margin-bottom: 15px;

    font-size: 12px;
    color: $grey-color;
  }

  &__language-select {
    font-size: 13px;
    color: $text-color;
  }

  &__language-select {
    width: 200px;
  }

  &__footer {
    font-size: 15px;
  }
}

.counter {
  &__text {
    font-size: 12px;
    color: $grey-color;
  }
}

/deep/ .language-label {
  font-size: 13px;
  color: $text-color;
}

.divider {
  margin: 5px 0 15px;
}

@media (max-width: 767px) {
  .template {
    &__container {
      padding: 0;
    }

    &__content {
      min-height: calc(100vh - 106px);
      padding: 0 15px 30px;
      background-color: $white;
    }

    &__description {
      margin-bottom: 25px;

      font-size: 10px;
    }

    &__language-select {
      width: 100%;
    }

    &__language-select {
      font-size: 12px;
    }

    &__mobile-header,
    &__subject,
    &__footer {
      font-size: 16px;
      line-height: 19px;

      &:disabled {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__footer {
      height: 80px;

      flex-direction: column;
      justify-content: space-between;
    }
  }

  .template__container .mobile-header {
    &__container {
      margin-bottom: 0;
    }
  }

  .rounded-button-white {
    padding: 6px 15px;
  }

  .template-footer {
    &__update-button,
    &__reset-button {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
