<template>
  <div class="treatment-options-container">
    <div class="treatment-options-title">
      {{ $t('dashboard.action.createTreatmentTab.createTreatment') }}
    </div>
    <div class="treatment-options">
      <div
        class="treatment-options__option diagnostic"
        :class="{
          'diagnostic--opened': shouldHighlightDiagnosticField
        }"
        @click="toggleDiagnosticSection"
        @click.stop
      >
        <template v-if="shouldShowDiagnosticOptions">
          <slide-down class="diagnostic-dropdown">
            <div slot="toggle">
              <div class="flex--space-between--center pointer">
                <div>
                  {{ $t('dashboard.createTreatmentTab.fromDiagnostic') }}
                </div>
                <span
                  class="arrow-icon fas fa-chevron-down"
                  :class="{ 'arrow-icon--opened': isOpened }"
                ></span>
              </div>
            </div>
            <div
              slot="slide-body"
              class="treatment__content"
              :class="{ 'treatment__content--opened': isOpened }"
            >
              <diagnostics-list
                class="diagnostic-list-dropdown dropdown-menu mobile-diagnostic-list-dropdown"
                :diagnostics="diagnostics"
                @select-diagnostic="selectDiagnosticForRecommendation"
                @start-new-photo-analysis="onStartNewPhotoAnalysis"
              />
            </div>
          </slide-down>
        </template>

        <div
          v-if="!shouldShowDiagnosticOptions && hasDiagnostics"
          @click="selectLastDiagnosticForRecommendation"
        >
          {{ $t('dashboard.createTreatmentTab.fromTheLastDiagnostic') }}
        </div>
      </div>
      <div class="treatment-options__option" @click="selectTreatmentFromTreatmentDirectory">
        {{ $t('dashboard.createTreatmentTab.fromTreatmentsDirectory') }}
      </div>
      <div class="treatment-options__option" @click="selectTreatmentFromScratchOption">
        {{ $t('dashboard.createTreatmentTab.fromScratch') }}
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, first } from 'lodash';

import DiagnosticsList from '@/modules/dashboard/components/create-treatment-tab/DiagnosticsList';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';

export default {
  name: 'CreateTreatmentTypes',
  components: { SlideDown, DiagnosticsList },
  props: {
    diagnostics: {
      type: Array,
      default: () => []
    },
    shouldShowDiagnosticOptions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpened: false
    };
  },
  computed: {
    hasDiagnostics() {
      return !isEmpty(this.diagnostics);
    },
    shouldHighlightDiagnosticField() {
      return this.shouldShowDiagnosticOptions && this.isOpened;
    }
  },
  methods: {
    selectFromDiagnosticOption() {
      this.isDiagnosticListDropdownVisible = !this.isDiagnosticListDropdownVisible;
    },
    disableDiagnosticListDropdownVisibility() {
      this.isDiagnosticListDropdownVisible = false;
    },
    selectDiagnosticForRecommendation(diagnostic) {
      this.$emit('select-diagnostic-option', diagnostic);
    },
    selectLastDiagnosticForRecommendation() {
      const lastDiagnostic = first(this.diagnostics);

      this.$emit('select-diagnostic-option', lastDiagnostic);
    },
    onStartNewPhotoAnalysis() {
      this.$emit('start-new-photo-analysis');
    },
    selectTreatmentFromTreatmentDirectory() {
      this.$emit('select-treatment-option');
    },
    selectTreatmentFromScratchOption() {
      this.$emit('select-scratch-option');
    },
    toggleDiagnosticSection() {
      this.isOpened = !this.isOpened;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/treatment/create-treatment-shared';

.treatment-options-container {
  padding: 20px 0;
  color: $active-title-color;
}

.treatment-options-title {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 24px;
  padding: 0 20px;
}

.treatment-options {
  width: 300px;

  &__option {
    width: 100%;
    padding: 0 15px;
    font-size: 15px;

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
}
.treatment__content {
  padding: 0;
}

.diagnostic--opened {
  padding-top: 10px;
  background-color: #f4f7f7;
}

.arrow-icon {
  height: 11px;
  margin-right: 10px;

  font-size: 12px;
  color: $dim-grey-color;

  &--opened {
    transform: rotate(180deg);
  }
}

.diagnostic-list-dropdown {
  display: block;
}

.dropdown-menu {
  width: 100%;
  box-shadow: none;
  padding: 10px;
  position: relative;
  font-size: 15px;
  background-color: inherit;
  border: none;
}
</style>
