<template>
  <modal
    name="send-offer-modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="800"
    height="auto"
  >
    <div class="modal-container">
      <modal-header current-modal-name="send-offer-modal">
        <span slot="header-title">{{ $t('dashboard.label.sendOffer') }}</span>
      </modal-header>
      <send-offer />
    </div>
  </modal>
</template>

<script>
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import SendOffer from '@/modules/dashboard/components/create-treatment-tab/send-offer/SendOffer';

export default {
  name: 'SendOfferModal',
  components: { ModalHeader, SendOffer }
};
</script>
