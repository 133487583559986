<template>
  <section class="treatment-directory flex-column">
    <modal-header-with-previous
      class="treatment-directory__modal-header treatment-directory-modal-header"
      current-modal-name="treatment-directory-modal"
      previous-modal-name="treatment-selection-modal"
      return-to-opened-patient
    >
      <span slot="header-title" class="treatment-directory-modal-header__title-text">
        {{ $t('treatmentDirectory.treatmentDirectory') }}
      </span>
    </modal-header-with-previous>
    <div
      ref="treatmentPreviewList"
      class="treatment-directory__directory-section directory-section"
    >
      <div class="directory-section__hint mb10 ml20">
        {{ $t('treatmentDirectory.selectTreatmentToContinue') }}
      </div>
      <treatment-directory-preview
        v-for="treatmentPreview in treatmentPreviews"
        :key="treatmentPreview.id"
        :treatment-preview="treatmentPreview"
        @select-treatment-preview="onSelectTreatmentPreview"
      />
    </div>
    <div class="treatment-directory__footer"></div>
    <treatment-warning-modal />
  </section>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import { mapActions, mapGetters } from 'vuex';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import TreatmentDirectoryPreview from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentDirectoryPreview';
import TreatmentWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentWarningModal';

import { microneedlingValidations } from '@/modules/dashboard/mixins/microneedling/microneedlingValidations';

import {
  getTreatmentDirectoryPreview,
  getTreatmentDirectoryRecommendationById
} from '@/modules/dashboard/api';

import {
  getPatientPageNameForTreatmentRecommendationProcess,
  isMobileDevice
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { TREATMENT_DIRECTORY_TYPE } from '@/modules/dashboard/constants/treatment-directory';
import {
  CREATE_TREATMENT_TYPES,
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  PATIENT_TAB_NAMES
} from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentDirectory',
  components: {
    TreatmentWarningModal,
    TreatmentDirectoryPreview,
    ModalHeaderWithPrevious
  },
  mixins: [microneedlingValidations],
  data() {
    return {
      scrollbar: null,
      treatmentPreviews: [],
      treatmentDirectoryType: null
    };
  },
  computed: {
    ...mapGetters({
      locale: rootTypes.getters.GET_LOCALE,
      doctorCountry: rootTypes.getters.GET_USER_COUNTRY,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      treatmentDirectoryQuestions: dashTypes.getters.TREATMENT_DIRECTORY_QUESTIONS,
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    })
  },
  async mounted() {
    this.setLoading(true);

    this.treatmentDirectoryType = this.treatmentDirectoryQuestions.name;

    this.treatmentPreviews = (
      await getTreatmentDirectoryPreview(
        this.locale,
        this.doctorCountry,
        this.treatmentDirectoryQuestions
      )
    ).data;

    this.setLoading(false);

    if (isMobileDevice()) {
      return;
    }

    this.scrollbar = new PerfectScrollbar(
      this.$refs.treatmentPreviewList,
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      selectCreateTreatmentType: dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE
    }),
    async onSelectTreatmentPreview({ name, clarifyingInformation, treatmentRecommendationId }) {
      this.setLoading(true);

      const treatmentRecommendation = (
        await getTreatmentDirectoryRecommendationById(treatmentRecommendationId)
      ).data;

      const { treatmentComposition, treatmentWarning } = treatmentRecommendation || {};

      this.setLoading(false);

      const { confirm } = await this.askDoctorForValidation({ treatmentWarning });

      if (!confirm) {
        return;
      }

      this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);

      await this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_DIRECTORY,
        treatmentDirectoryRecommendations: {
          name,
          clarifyingInformation,
          treatmentRecommendationId,
          treatmentComposition,
          treatmentWarning
        }
      });

      this.closeCurrentModal();

      const nextRouteName = getPatientPageNameForTreatmentRecommendationProcess();

      if (this.$route.name !== nextRouteName) {
        this.$router.push({
          name: nextRouteName,
          params: { id: this.currentPatientId, showTreatmentRecommendations: true }
        });
      }
    },
    closeCurrentModal() {
      this.$modal.hide('treatment-directory-modal');
    },
    async askForCommonValidation({ treatmentWarning }) {
      if (!treatmentWarning) {
        return { confirm: true };
      }

      const modalParams = {
        patientId: this.currentPatientId,
        treatmentWarning
      };

      const { confirm: isTreatmentConfirmed } = await this.$asyncModal.show(
        'treatment-warning-modal',
        modalParams
      );

      return { confirm: isTreatmentConfirmed };
    },
    async askDoctorForValidation({ treatmentWarning }) {
      const { confirm: confirmCommon } = await this.askForCommonValidation({ treatmentWarning });

      if (!confirmCommon) {
        return { confirm: false };
      }

      const isMicroneedling =
        this.treatmentDirectoryType === TREATMENT_DIRECTORY_TYPE.MICRONEEDLING;

      if (isMicroneedling) {
        const { confirm: microneedlingConfirm } = await this.askForMicroneedlingValidation();

        if (!microneedlingConfirm) {
          return { confirm: false };
        }
      }

      return { confirm: true };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/text';

.treatment-directory {
  height: 100%;
  position: relative;

  &__directory-section {
    flex-grow: 1;
    padding: 20px 25px 50px;
    position: relative;
    background: $background;
    border-radius: 8px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    height: 40px;
    width: 100%;

    background: linear-gradient(0, $background 0%, rgba(249, 250, 252, 0) 100%);
    border-radius: 8px;
  }
}

.treatment-directory-modal-header {
  &__title-text {
    color: $text-color;
  }
}

.directory-section {
  &__hint {
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .treatment-directory {
    &__modal-header {
      position: fixed;
      top: 0;
      z-index: 1;
      background-color: $white;
    }

    &__directory-section {
      padding: 70px 5px 50px;
    }

    &__footer {
      position: fixed;
    }
  }

  .directory-section {
    &__hint {
      font-size: 12px;
      line-height: 14px;
      margin-left: 15px;
    }
  }
}
</style>
