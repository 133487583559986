<template>
  <treatment-dropdown microneedling :is-readonly="isReadonly">
    <template #dropdown-icon="{ disableDropdownVisibility }">
      <span class="needle-size-dropdown" @blur="disableDropdownVisibility">
        {{ formatNeedleSize(needleSize) }}
      </span>
    </template>
    <template #dropdown-options>
      <treatment-product-options
        :value="needleSize"
        :options="options"
        class="needle-size-options"
        @select-option="selectOption"
      >
        <template #option="{ option }">
          <div class="option__content flex--align-center" @click="isOpen = false">
            <span class="custom-icon icon-microneedling needle-size-options__icon"></span>
            <span class="needle-size-options__size">{{ formatNeedleSize(option) }}</span>
          </div>
        </template>
      </treatment-product-options>
    </template>
  </treatment-dropdown>
</template>

<script>
import TreatmentDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentDropdown';
import TreatmentProductOptions from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductOptions';

import { formatNumberWithOneDecimalPart } from '@/modules/dashboard/utils';

export default {
  name: 'NeedleSizeDropdown',
  components: { TreatmentDropdown, TreatmentProductOptions },
  props: {
    needleSize: {
      type: Number,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectOption(size) {
      this.$emit('select-size', size);
    },
    formatNeedleSize(size) {
      const sizeDecimal = formatNumberWithOneDecimalPart(size);
      const units = this.$t('dashboard.label.mm');

      return `${sizeDecimal}${units}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';

.needle-size-dropdown {
  font-size: 10px;
}

.needle-size-options {
  color: $text-color;

  &__size {
    font-size: 10px;
    margin-left: 12px;
  }
}
</style>
