<template>
  <treatment-recommendation-basis>
    <template slot="header-detail">
      <div class="recommendation-title flex--align-center">
        <diagnostic :diagnostic="diagnostic" display-diagnostic-date hide-validation-warn-icon />
      </div>
      <div class="hint-text pointer" @click="toggleSlide">
        {{ toggleSlideButtonLabel }}
      </div>
    </template>
    <template slot="body">
      <slide-down :show="isSliderShown">
        <div slot="slide-body">
          <diagnostic-recommendation-details v-if="diagnostic" :diagnostic="diagnostic" />
        </div>
      </slide-down>
      <diagnostic-recommendation-details-modal />
    </template>
  </treatment-recommendation-basis>
</template>

<script>
import { mapGetters } from 'vuex';

import SlideDown from '@/modules/dashboard/components/common/SlideDown';
import Diagnostic from '@/modules/dashboard/components/diagnostic-tab/Diagnostic';
import DiagnosticRecommendationDetails from '@/modules/dashboard/components/create-treatment-tab/DiagnosticRecommendationDetails';
import DiagnosticRecommendationDetailsModal from '@/modules/dashboard/components/create-treatment-tab/DiagnosticRecommendationDetailsModal';
import TreatmentRecommendationBasis from '@/modules/dashboard/components/create-treatment-tab/TreatmentRecommendationBasis';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'DiagnosticRecommendation',
  components: {
    TreatmentRecommendationBasis,
    Diagnostic,
    DiagnosticRecommendationDetailsModal,
    DiagnosticRecommendationDetails,
    SlideDown
  },
  data() {
    return {
      isSliderShown: false
    };
  },
  computed: {
    ...mapGetters({
      recommendationDiagnostic: dashTypes.getters.RECOMMENDATION_DIAGNOSTIC,
      getDiagnosticById: dashTypes.getters.GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID
    }),
    diagnostic() {
      return this.recommendationDiagnostic || {};
    },
    toggleSlideButtonLabel() {
      return this.$t(`dashboard.action.${this.isSliderShown ? 'hideDetails' : 'showDetails'}`);
    }
  },
  methods: {
    toggleSlide() {
      if (isMobileDevice()) {
        this.$modal.show('diagnostic-recommendation-details-modal', {
          diagnostic: this.diagnostic
        });

        return;
      }

      this.isSliderShown = !this.isSliderShown;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.recommendation-title {
  flex-grow: 1;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .recommendation-title {
    min-height: 48px;

    margin: 3px 0 0;
    width: 100%;
  }
}
</style>
