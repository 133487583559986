<template>
  <div class="microneedling-serum-badge flex--align-center">
    <span
      class="custom-icon icon-close microneedling-serum-badge__close-button"
      @click="onClose"
    ></span>
    <span class="custom-icon icon-microneedling-circle-fill microneedling-serum-badge__icon"></span>
    <span class="microneedling-serum-badge__title">
      {{ $t('dashboard.microneedling.step') }}
    </span>
    <span
      v-tooltip="{ content: $t($options.infoTooltipText) }"
      class="custom-icon icon-info microneedling-serum-badge__info"
    ></span>
    <needle-size-dropdown
      class="microneedling-serum-badge__size-select"
      :needle-size="needleSize"
      :options="$options.needleSizeOptions"
      :is-readonly="!isNeedleSizeEditable"
      @select-size="onSizeSelect"
    />
    <div class="microneedling-serum-badge__details">
      <span class="microneedling-serum-badge__label text-capitalize"
        >{{ $t('dashboard.label.volume') }}:</span
      >
      <span class="microneedling-serum-badge__volume">{{ volumeFormatted }}</span>
    </div>
  </div>
</template>

<script>
import NeedleSizeDropdown from '@/modules/dashboard/components/microneedling/NeedleSizeDropdown.vue';

import { MICRONEEDLING_NEEDLE_SIZES } from '@/modules/dashboard/constants/microneedling';

const INFO_TOOLTIP_TEXT = 'dashboard.microneedling.needleSizeCost';

export default {
  name: 'MicroneedlingSerumBadge',
  components: { NeedleSizeDropdown },
  infoTooltipText: INFO_TOOLTIP_TEXT,
  needleSizeOptions: [...MICRONEEDLING_NEEDLE_SIZES],
  props: {
    needleSize: {
      type: Number,
      required: true
    },
    volume: {
      type: Number,
      required: true
    },
    isNeedleSizeEditable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    volumeFormatted() {
      return `${this.volume}${this.$t('dashboard.label.ml')}`;
    }
  },
  methods: {
    onSizeSelect(size) {
      this.$emit('select-size', size);
    },
    onClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/scss/helpers/colors';

.microneedling-serum-badge {
  background-color: $porcelain-color;
  border-radius: 8px;
  padding: 14px 10px;
  color: $text-color;
  max-width: 100%;
  height: 50px;

  &__close-button {
    margin-left: 10px;
  }

  &__icon {
    cursor: auto;
    margin-left: 15px;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
  }

  &__info {
    margin-left: 8px;
  }

  &__size-select {
    margin-left: 12px;
  }

  &__details {
    font-size: 10px;
    font-weight: 500;
    margin-left: auto;
  }

  &__label {
    display: none;
  }
}

@media (max-width: 835px) {
  .microneedling-serum-badge {
    flex-wrap: wrap;
    height: 88px;

    &__details {
      flex: 1 0 100%;
      align-items: flex-start;
      font-size: 13px;
      line-height: 16px;
      padding-left: 70px;
    }

    &__title {
      font-size: 13px;
    }

    &__label {
      display: inline-block;
      margin-right: 5px;
      color: $grey-color;
    }
  }
}
</style>
