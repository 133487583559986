import { mapActions, mapGetters } from 'vuex';

import {
  validateDiagnostics,
  validateTiming
} from '@/modules/dashboard/business-logic/microneedling';

import { types } from '@/modules/dashboard/store/types';
import rootTypes from '@/store/types';
import { TREATMENT_SECTION_NAME } from '@/modules/dashboard/api/constants';

export const microneedlingValidations = {
  computed: {
    ...mapGetters({
      currentPatientId: types.getters.CURRENT_PATIENT_ID,
      getIsMicroneedlingActiveForTreatmentSection:
        types.getters.GET_IS_MICRONEEDLING_ACTIVE_FOR_TREATMENT_SECTION
    })
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    async askForMicroneedlingValidation(options) {
      const { treatmentSectionName } = options || {};

      const isFaceMicroneedlingActive = this.getIsMicroneedlingActiveForTreatmentSection(
        TREATMENT_SECTION_NAME.TREAT_FACE
      );

      this.setLoading(true);

      const {
        shouldReceiveConsent: shouldAskForDiagnosticsConfirmation,
        shouldRejectOnReceivingConsent: shouldRejectOnReceivingDiagnosticsConsent,
        warningParams: diagnosticsWarningParams
      } = await validateDiagnostics({
        isMicroneedling: true,
        patientId: this.currentPatientId,
        treatmentSectionName,
        isFaceMicroneedlingActive
      });

      this.setLoading(false);

      if (shouldAskForDiagnosticsConfirmation) {
        const { confirm: isDiagnosticConfirmed } = await this.$asyncModal.show(
          'diagnostic-results-warning-modal',
          diagnosticsWarningParams
        );

        if (!isDiagnosticConfirmed || shouldRejectOnReceivingDiagnosticsConsent) {
          return { confirm: false };
        }
      }

      this.setLoading(true);

      const {
        shouldReceiveConsent: shouldAskForTimingConfirmation,
        shouldRejectOnReceivingConsent: shouldRejectOnReceivingTimingConsent,
        warningParams: timingWarningParams
      } = await validateTiming({
        isMicroneedling: true,
        patientId: this.currentPatientId,
        treatmentSectionName,
        isFaceMicroneedlingActive
      });

      this.setLoading(false);

      if (shouldAskForTimingConfirmation) {
        const { confirm: isLastTreatmentConfirmed } = await this.$asyncModal.show(
          'recent-treatment-warning-modal',
          timingWarningParams
        );

        return { confirm: isLastTreatmentConfirmed && !shouldRejectOnReceivingTimingConsent };
      }

      return { confirm: true };
    }
  }
};
