<template>
  <div class="date-input input-text flex">
    <input
      v-allow-only-reg-exp="/^[0-9]$/"
      v-validate="{ required: required }"
      :value="value.day"
      placeholder="DD"
      class="date-input__day rounded-input main-placeholder"
      maxlength="2"
      max="31"
      type="number"
      :name="dayInputName"
      :class="{ 'error-highlight': dayInputHasError }"
      @input="updateDay"
    />
    <input
      v-allow-only-reg-exp="/^[0-9]$/"
      v-validate="{ required: required }"
      :value="value.month"
      placeholder="MM"
      class="date-input__month rounded-input main-placeholder"
      maxlength="2"
      type="number"
      max="12"
      :name="monthInputName"
      :class="{ 'error-highlight': monthInputHasError }"
      @input="updateMonth"
    />
    <input
      v-allow-only-reg-exp="/^[0-9]$/"
      v-validate.disable="{ required: required, min: 4 }"
      :value="value.year"
      placeholder="YYYY"
      class="date-input__year rounded-input main-placeholder"
      maxlength="4"
      :name="yearInputName"
      :class="{ 'error-highlight': yearInputHasError }"
      @input="updateYear"
    />
  </div>
</template>

<script>
export default {
  name: 'DateInputForm',
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      default: () => ({
        day: '',
        month: '',
        year: ''
      })
    },
    name: {
      type: String,
      default: 'date'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dayInputName() {
      return `${this.name}-day`;
    },
    monthInputName() {
      return `${this.name}-month`;
    },
    yearInputName() {
      return `${this.name}-year`;
    },
    dayInputHasError() {
      return this.errors.has(this.dayInputName);
    },
    monthInputHasError() {
      return this.errors.has(this.monthInputName);
    },
    yearInputHasError() {
      return this.errors.has(this.yearInputName);
    }
  },
  methods: {
    updateDay({ target: { value: day } }) {
      if (day < 1 || day > 31) {
        return;
      }

      this.$emit('input', { ...this.value, day });
    },
    updateMonth({ target: { value: month } }) {
      if (month < 1 || month > 12) {
        return;
      }

      this.$emit('input', { ...this.value, month });
    },
    updateYear({ target: { value: year } }) {
      this.$emit('input', { ...this.value, year });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/inputs.css';
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/css/common/errors.css';

.rounded-input {
  padding: 9px 10px;
  margin-bottom: 0;
}

.rounded-input::-webkit-outer-spin-button,
.rounded-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rounded-input[type='number'] {
  -moz-appearance: textfield;
}

.main-placeholder::placeholder {
  font-style: normal;
}

.date-input {
  &__day,
  &__month {
    width: 64px;
    margin-right: 12px;
  }

  &__year {
    width: 88px;
  }
}

@media (max-width: 767px) {
  .rounded-input {
    padding: 8px;
  }

  .date-input {
    &__day,
    &__month {
      width: 40px;
      margin-right: 5px;
    }

    &__year {
      width: 70px;
    }
  }
}
</style>
