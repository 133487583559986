<template>
  <section class="treatment-type-options__container full-width">
    <create-treatment-types
      class="onlyXS"
      :diagnostics="diagnostics"
      :should-show-diagnostic-options="shouldShowDiagnosticOptions"
      @select-diagnostic-option="selectDiagnosticForRecommendation"
      @start-new-photo-analysis="onStartNewPhotoAnalysis"
      @select-treatment-option="selectTreatmentFromTreatmentDirectory"
      @select-scratch-option="selectTreatmentFromScratchOption"
    />
    <create-treatment-type-buttons
      class="treatment-type-options__buttons"
      :diagnostics="diagnostics"
      @select-diagnostic-option="selectDiagnosticForRecommendation"
      @start-new-photo-analysis="onStartNewPhotoAnalysis"
      @select-treatment-option="selectTreatmentFromTreatmentDirectory"
      @select-scratch-option="selectTreatmentFromScratchOption"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import CreateTreatmentTypeButtons from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentTypeButtons';
import CreateTreatmentTypes from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentTypes';

import { handleRoutingInfo, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_TYPES,
  PHOTO_ANALYSIS_ROUTING_INFO,
  TREATMENT_SELECTION_ROUTING_INFO
} from '@/modules/dashboard/api/constants';

export default {
  name: 'CreateTreatmentOptions',
  components: {
    CreateTreatmentTypeButtons,
    CreateTreatmentTypes
  },
  props: {
    shouldShowDiagnosticOptions: {
      type: Boolean,
      default: true
    },
    shouldSelectDiagnosticCreateTreatmentType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDiagnosticListDropdownVisible: false
    };
  },
  computed: {
    ...mapState({
      notValidatedDiagnosticId: state => state.dashboard.notValidatedDiagnosticId
    }),
    ...mapGetters({
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS
    })
  },
  watch: {
    notValidatedDiagnosticId: {
      handler(newDiagnosticId) {
        if (!newDiagnosticId) {
          return;
        }

        this.$modal.show('diagnostic-not-validated-modal', { diagnosticId: newDiagnosticId });
      },
      immediate: true
    }
  },
  mounted() {
    if (this.notValidatedDiagnosticId) {
      this.$modal.show('diagnostic-not-validated-modal', {
        diagnosticId: this.notValidatedDiagnosticId
      });
    }
  },
  methods: {
    ...mapMutations({
      setNotValidatedDiagnosticId: dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      selectCreateTreatmentType: dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE,
      selectRecommendationDiagnostic: dashTypes.actions.SELECT_RECOMMENDATION_DIAGNOSTIC
    }),
    selectFromDiagnosticOption() {
      this.isDiagnosticListDropdownVisible = !this.isDiagnosticListDropdownVisible;
    },
    disableDiagnosticListDropdownVisibility() {
      this.isDiagnosticListDropdownVisible = false;
    },
    async selectTreatmentFromScratchOption() {
      await this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH
      });

      if (isMobileDevice()) {
        this.$emit('option-selected');
      }
    },
    async selectDiagnosticForRecommendation(diagnostic) {
      const { id, validated } = diagnostic;

      if (!validated) {
        await this.selectTreatmentFromScratchOption();
        this.setNotValidatedDiagnosticId(id);

        return;
      }

      this.setLoading(true);

      if (this.shouldSelectDiagnosticCreateTreatmentType) {
        await this.selectCreateTreatmentType({
          createTreatmentType: CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT,
          recommendationDiagnostic: diagnostic
        });
      } else {
        await this.selectRecommendationDiagnostic(diagnostic);
      }

      this.setLoading(false);

      if (isMobileDevice()) {
        this.$emit('option-selected');
      }
    },
    selectTreatmentFromTreatmentDirectory() {
      this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH
      });

      handleRoutingInfo(
        { ...TREATMENT_SELECTION_ROUTING_INFO, params: { isHeaderWithBackButton: false } },
        this.$router,
        this.$modal
      );
    },
    onStartNewPhotoAnalysis() {
      this.selectTreatmentFromScratchOption();
      handleRoutingInfo(PHOTO_ANALYSIS_ROUTING_INFO, this.$router, this.$modal);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .treatment-type-options__buttons {
    display: none;
  }
}
</style>
