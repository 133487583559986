import { isNil, isEmpty } from 'lodash';

import {
  getLastTreatmentValidation,
  getSkinDiagnosticValidation,
  getSkinThicknessValidation
} from '@/modules/dashboard/api';

import {
  MICRONEEDLING_NEEDLE_SIZE,
  MICRONEEDLING_RESTRICTION,
  MICRONEEDLING_RESTRICTION_DISPLAY_VALUE
} from '@/modules/dashboard/constants/microneedling';
import { INGREDIENTS, TREATMENT_SECTION_NAME } from '@/modules/dashboard/api/constants';

export const validateNeedleSize = async ({ needleSize, patientId }) => {
  const isMediumNeedle = needleSize === MICRONEEDLING_NEEDLE_SIZE.MEDIUM;

  if (!isMediumNeedle) {
    return { shouldReceiveConsent: false, shouldRejectOnReceivingConsent: false };
  }

  const { data: restriction } = await getSkinThicknessValidation(patientId);
  const shouldReceiveConsent = restriction === MICRONEEDLING_RESTRICTION.THIN_SKIN;

  return { shouldReceiveConsent, shouldRejectOnReceivingConsent: false };
};

export const validateActiveIngredient = ({ isMicroneedling, activeIngredient }) => {
  const shouldReceiveConsent = isMicroneedling && activeIngredient === INGREDIENTS.GLYCOLIC;

  return { shouldReceiveConsent, shouldRejectOnReceivingConsent: shouldReceiveConsent };
};

export const validateOfflineTreatment = ({ isMicroneedling }) => ({
  shouldReceiveConsent: isMicroneedling,
  shouldRejectOnReceivingConsent: isMicroneedling
});

export const validateContraindications = ({ isMicroneedling }) => ({
  shouldReceiveConsent: isMicroneedling,
  shouldRejectOnReceivingConsent: false
});

export const validateTiming = async options => {
  const { isMicroneedling, patientId, treatmentSectionName, isFaceMicroneedlingActive } = options;

  const isSwitchingSerumBottles =
    treatmentSectionName === TREATMENT_SECTION_NAME.TREAT_FACE && isFaceMicroneedlingActive;

  if (isSwitchingSerumBottles) {
    return {
      shouldReceiveConsent: false
    };
  }

  const {
    data: { daysFromPreviousMicroneedling, restriction }
  } = await getLastTreatmentValidation(patientId);

  const shouldReceiveConsent = isMicroneedling && !isNil(daysFromPreviousMicroneedling);
  const isSkinRednessNotification = restriction === MICRONEEDLING_RESTRICTION.SKIN_REDNESS;

  const warningParams = {
    lastProcedureDaysAgo: daysFromPreviousMicroneedling,
    isSkinRednessNotification
  };

  return {
    shouldReceiveConsent,
    shouldRejectOnReceivingConsent: false,
    warningParams
  };
};

export const validateDiagnostics = async options => {
  const { isMicroneedling, patientId, treatmentSectionName, isFaceMicroneedlingActive } = options;

  const isSwitchingSerumBottles =
    treatmentSectionName === TREATMENT_SECTION_NAME.TREAT_FACE && isFaceMicroneedlingActive;

  if (!isMicroneedling || isSwitchingSerumBottles) {
    return {
      shouldReceiveConsent: false
    };
  }

  const { data: diagnosticResultsKeys } = await getSkinDiagnosticValidation(patientId);
  const diagnosticResults = diagnosticResultsKeys.map(
    resultKey => MICRONEEDLING_RESTRICTION_DISPLAY_VALUE[resultKey]
  );

  const shouldReceiveConsent = !isEmpty(diagnosticResults);
  const warningParams = { diagnosticResults };

  return {
    shouldReceiveConsent,
    shouldRejectOnReceivingConsent: false,
    warningParams
  };
};

export const getIsNeedleSizeEditable = ({ sectionName }) =>
  sectionName === TREATMENT_SECTION_NAME.TREAT_FACE;
