import { render, staticRenderFns } from "./OfferComposition.vue?vue&type=template&id=4df41872&scoped=true&"
import script from "./OfferComposition.vue?vue&type=script&lang=js&"
export * from "./OfferComposition.vue?vue&type=script&lang=js&"
import style0 from "./OfferComposition.vue?vue&type=style&index=0&id=4df41872&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df41872",
  null
  
)

export default component.exports