<template>
  <warning-modal
    class="nexultra-o-recommandation-modal"
    name="nexultra-o-recommandation-modal"
    title="dashboard.microneedling.warning.notice"
    large-screen-width="520"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <i18n tag="div" path="dashboard.modal.recommendNexultraO.mainText.p0">
        <span place="patientFirstName">{{ patientFirstName }}</span>
      </i18n>
      <i18n class="mt15" tag="div" path="dashboard.modal.recommendNexultraO.mainText.p1.base">
        <b place="nexultraO">{{
          $t('dashboard.modal.recommendNexultraO.mainText.p1.nexultraO')
        }}</b>
      </i18n>
    </template>

    <template #secondary-content>
      <i18n class="mt15" tag="div" path="dashboard.modal.recommendNexultraO.secondaryText.base">
        <b place="nexultraO">{{
          $t('dashboard.modal.recommendNexultraO.secondaryText.nexultraO')
        }}</b>
      </i18n>
    </template>

    <template #actions>
      <confirmation-modal-actions
        cancel-text="dashboard.modal.recommendNexultraO.skip"
        confirm-text="dashboard.modal.recommendNexultraO.agree"
        @cancel="handleClose(false)"
        @confirm="handleClose(true)"
      />
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions';

export default {
  name: 'NexultraORecommendationModal',
  components: { WarningModal, ConfirmationModalActions },
  props: {
    patientFirstName: {
      type: String,
      required: true
    }
  },
  data() {
    return { onClose: null };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    }
  }
};
</script>
