<template>
  <div class="ingredient-icon__container flex">
    <div class="ingredient-icon" :style="{ backgroundColor: ingredientColor }">
      <span v-if="numberOfCapsulesLabel" class="ingredient-icon__text">
        {{ numberOfCapsulesLabel }}
      </span>
    </div>
    <div
      v-if="isDoubleNumberOfCapsules"
      class="ingredient-icon__double"
      :style="severalCapsulesIngredientStyles"
    ></div>
    <div
      v-if="isTripleNumberOfCapsules"
      class="ingredient-icon__triple"
      :style="severalCapsulesIngredientStyles"
    ></div>
  </div>
</template>

<script>
const NUMBER_OF_CAPSULES = {
  DOUBLE: '×2',
  TRIPLE: '×3'
};

export default {
  name: 'IngredientIcon',
  props: {
    numberOfCapsules: {
      type: Number,
      default: 1
    },
    ingredientColor: {
      type: String,
      required: true
    }
  },
  computed: {
    isDoubleNumberOfCapsules() {
      return this.numberOfCapsules >= 2;
    },
    isTripleNumberOfCapsules() {
      return this.numberOfCapsules === 3;
    },
    severalCapsulesIngredientStyles() {
      return { borderColor: this.ingredientColor };
    },
    numberOfCapsulesLabel() {
      if (this.isTripleNumberOfCapsules) {
        return NUMBER_OF_CAPSULES.TRIPLE;
      }

      if (this.isDoubleNumberOfCapsules) {
        return NUMBER_OF_CAPSULES.DOUBLE;
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.ingredient-icon {
  display: inline-block;
  position: relative;
  z-index: 3;
  width: 20px;
  height: 20px;

  border-radius: 3px;

  font-size: 11px;
  line-height: 20px;
  font-weight: 600;
  color: $white;
  text-align: center;

  &__container {
    position: relative;
    margin-right: 12px;
  }

  &__double,
  &__triple {
    position: absolute;
    top: 0;

    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid green;
  }

  &__double {
    z-index: 2;
    right: -3px;
  }

  &__triple {
    z-index: 1;
    right: -6px;
  }
}
</style>
