<template>
  <section class="eye-contour">
    <div v-if="bottle">
      <treat-section-header
        class="eye-contour__header"
        :title="$t('dashboard.label.treatEyeContour')"
        :first-active-time="activeTime"
        :duration="treatEyesDuration"
        :price="treatSectionPricePerMonth"
        :can-recalculate="hasRecommendation"
        :section-name="$options.sectionName"
        @recalculate="applyTreatEyesRecommendation"
      />
      <div
        class="eye-contour__serum flex--center--center"
        :class="{
          'justify-content--flex-start': isMorningSerum,
          'justify-content--flex-end': isEveningSerum
        }"
      >
        <serum
          v-model="bottle"
          :can-be-duplicated="false"
          :can-change-concentration="false"
          :is-recommended="hasRecommendation"
          :section="sectionName"
          @input="deleteBottleValidationError"
          @delete-serum="deleteTreatEyes"
        />
      </div>
    </div>
    <div v-else class="eye-contour__default-section add-eye-contour flex--center--center">
      <div
        class="rounded-button-gray add-eye-contour__button add-button flex--align-center"
        @click="addEyeContour"
      >
        <div class="add-button__icon custom-icon icon-plus-active"></div>
        {{ $t('dashboard.action.addEyeContourSerum') }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import TreatSectionHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/TreatSectionHeader';
import Serum from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Serum';

import {
  getBottleCompositionForRecommendation,
  getBottleActiveTime
} from '@/modules/dashboard/utils/create-treatment-utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import {
  CREATE_TREATMENT_ERRORS,
  DEFAULT_COMPOSITION_BOTTLE_VALUES,
  SERUM_USAGE_KEYS,
  TREATMENT_SECTION_NAME,
  TREAT_SECTIONS
} from '@/modules/dashboard/api/constants';

export default {
  name: 'EyeContourSection',
  components: { Serum, TreatSectionHeader },
  sectionName: TREAT_SECTIONS.EYE_CONTOUR,
  data() {
    return {
      sectionName: TREATMENT_SECTION_NAME.TREAT_EYES
    };
  },
  computed: {
    ...mapGetters({
      treatEyes: dashTypes.getters.TREAT_EYES,
      treatEyesPrice: dashTypes.getters.TREAT_EYES_PRICE,
      treatEyesDuration: dashTypes.getters.TREAT_EYES_DURATION,
      treatEyesRecommendation: dashTypes.getters.TREAT_EYES_RECOMMENDATION,
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      hasCreateTreatmentErrors: dashTypes.getters.HAS_CREATE_TREATMENT_ERRORS,
      treatmentDirectoryRecommendations: dashTypes.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS
    }),
    bottle: {
      get() {
        return this.treatEyes.compositionBottle1;
      },
      set(compositionBottle1) {
        this.updateTreatEyes({ ...this.treatEyes, compositionBottle1 });
      }
    },
    bottleUsage() {
      return get(this.bottle, 'usage');
    },
    isMorningSerum() {
      return this.bottleUsage === SERUM_USAGE_KEYS.MORNING_ONLY;
    },
    isEveningSerum() {
      return this.bottleUsage === SERUM_USAGE_KEYS.EVENING_ONLY;
    },
    activeTime() {
      return getBottleActiveTime({ bottle: this.bottle, $t: this.$t });
    },
    treatSectionPricePerMonth() {
      return this.treatEyesPrice / this.treatEyesDuration;
    },
    hasRecommendation() {
      const isFound = get(this.treatEyesRecommendation, 'formulasOptions.oneFormula.isFound');

      return this.treatEyesRecommendation && this.hasTreatmentRecommendation && isFound;
    }
  },
  watch: {
    treatEyesRecommendation(value) {
      if (value && this.treatmentDirectoryRecommendations) {
        this.applyTreatEyesRecommendation();
      }
    }
  },
  mounted() {
    if (this.treatEyesRecommendation && this.treatmentDirectoryRecommendations && !this.bottle) {
      this.applyTreatEyesRecommendation();
    }
  },
  methods: {
    ...mapActions({
      updateTreatEyes: dashTypes.actions.UPDATE_TREAT_EYES,
      deleteCreateTreatmentError: dashTypes.actions.DELETE_CREATE_TREATMENT_ERROR
    }),
    addEyeContour() {
      if (this.treatEyesRecommendation && !this.bottle) {
        this.applyTreatEyesRecommendation();
      }

      if (this.bottle) {
        return;
      }

      this.bottle = {
        ...DEFAULT_COMPOSITION_BOTTLE_VALUES
      };
    },
    deleteTreatEyes() {
      this.bottle = null;
    },
    deleteBottleValidationError() {
      if (this.hasCreateTreatmentErrors) {
        this.deleteCreateTreatmentError(CREATE_TREATMENT_ERRORS.TREAT_EYES_BOTTLE_SERUM_VALIDATION);
      }
    },
    applyTreatEyesRecommendation() {
      const oneFormula = get(this.treatEyesRecommendation, 'formulasOptions.oneFormula', {});

      if (oneFormula.isFound) {
        this.useFormulaRecommendations(oneFormula);
      }

      this.deleteBottleValidationError();
    },
    useFormulaRecommendations({ compositionBottle1, usage }) {
      this.bottle = getBottleCompositionForRecommendation(compositionBottle1, usage);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';

.eye-contour {
  .serum__container {
    max-width: calc(50% - 14px);
    width: 100%;
  }

  &__serum {
    padding: 20px 15px 30px;
  }

  &__default-section {
    margin-bottom: 30px;
  }
}

.add-button {
  &__icon {
    width: 15px;
    height: 15px;

    margin-right: 8px;
  }
}

@media (max-width: 930px) {
  .eye-contour {
    .serum__container {
      max-width: calc(50% - 10px);
    }

    &__serum {
      padding: 20px 0 30px;
    }
  }
}

@media (max-width: 767px) {
  .eye-contour {
    margin-top: 5px;

    .serum__container {
      max-width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__header {
      margin-top: 5px;
    }

    &__serum {
      padding: 18px 5px 0;
      margin-bottom: 25px;

      justify-content: center;
    }

    &__default-section {
      margin-bottom: 24px;
    }
  }
}
</style>
