<template>
  <section class="treat-face full-width">
    <treat-section-header
      :title="$t('dashboard.label.treatFace')"
      :first-active-time="firstActiveTime"
      :second-active-time="secondActiveTime"
      :can-recalculate="hasRecommendation"
      :duration="treatFaceDuration"
      :price="treatSectionPricePerMonth"
      :section-name="$options.sectionName"
      @recalculate="recalculate"
    />
    <div class="face-serum__container flex--align-center">
      <div v-if="isMorningAndEveningSerum" class="flex--center--center full-width face-serum__container__morning-and-evening">
        <serum
          v-if="firstBottle"
          v-model="firstBottle"
          :bottle-number="$options.serumBottleNumber.FIRST"
          :is-recommended="hasRecommendation"
          @input="deleteFirstBottleError"
          @select-usage="selectFirstBottleUsage"
          @delete-serum="deleteFirstBottle"
        />
      </div>
      <div v-else class="treat-face flex--space-between full-width treat-face__serum">
        <serum
          v-if="firstBottle"
          v-model="firstBottle"
          class="treat-face__morning-serum"
          :bottle-number="$options.serumBottleNumber.FIRST"
          :can-be-duplicated="!isTwoBottlesSelected"
          :is-recommended="hasRecommendation"
          @input="deleteFirstBottleError"
          @select-usage="selectFirstBottleUsage"
          @delete-serum="deleteFirstBottle"
        />
        <default-serum-card
          v-else
          class="treat-face__default-morning-serum"
          type="morning"
          @click.native="addMorningSerum"
        />
        <serum
          v-if="secondBottle"
          v-model="secondBottle"
          class="treat-face__evening-serum"
          :bottle-number="$options.serumBottleNumber.SECOND"
          :can-be-duplicated="!isTwoBottlesSelected"
          :is-recommended="hasRecommendation"
          @input="deleteSecondBottleError"
          @select-usage="selectSecondBottleUsage"
          @delete-serum="deleteSecondBottle"
        />
        <default-serum-card v-else type="evening" @click.native="addEveningSerum" />
      </div>
    </div>
    <medical-concentration-modal />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import MedicalConcentrationModal from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/MedicalConcentrationModal';
import TreatSectionHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/TreatSectionHeader';
import DefaultSerumCard from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/DefaultSerumCard';
import Serum from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Serum';

import {
  getBottleActiveTime,
  getBottleCompositionForRecommendation,
  isBottleDuplicated,
  isOneOfBottlesDuplicated
} from '@/modules/dashboard/utils/create-treatment-utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_ERRORS,
  DEFAULT_COMPOSITION_BOTTLE_VALUES,
  SERUM_USAGE_KEYS,
  TREAT_SECTIONS,
  SERUM_BOTTLE_NUMBER
} from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatFaceSection',
  components: { MedicalConcentrationModal, Serum, DefaultSerumCard, TreatSectionHeader },
  serumBottleNumber: SERUM_BOTTLE_NUMBER,
  sectionName: TREAT_SECTIONS.FACE_SECTION,
  computed: {
    ...mapGetters({
      setting: dashTypes.getters.SETTING,
      treatFace: dashTypes.getters.TREAT_FACE,
      treatFacePrice: dashTypes.getters.TREAT_FACE_PRICE,
      treatFaceDuration: dashTypes.getters.TREAT_FACE_DURATION,
      treatFaceRecommendation: dashTypes.getters.TREAT_FACE_RECOMMENDATION,
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      hasCreateTreatmentErrors: dashTypes.getters.HAS_CREATE_TREATMENT_ERRORS
    }),
    firstBottle: {
      get() {
        return this.treatFace.compositionBottle1;
      },
      set(compositionBottle1) {
        this.updateTreatFace({ ...this.treatFace, compositionBottle1 });
      }
    },
    secondBottle: {
      get() {
        return this.treatFace.compositionBottle2;
      },
      set(compositionBottle2) {
        this.updateTreatFace({ ...this.treatFace, compositionBottle2 });
      }
    },
    isMorningAndEveningSerum() {
      return this.firstBottle && this.firstBottle.usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING;
    },
    isTwoBottlesSelected() {
      return this.firstBottle && this.secondBottle;
    },
    firstActiveTime() {
      return getBottleActiveTime({
        bottle: this.firstBottle,
        isDuplicated: this.isOneOfBottlesDuplicated,
        $t: this.$t
      });
    },
    secondActiveTime() {
      return getBottleActiveTime({
        bottle: this.secondBottle,
        isDuplicated: this.isOneOfBottlesDuplicated,
        $t: this.$t
      });
    },
    isOneOfBottlesDuplicated() {
      return isOneOfBottlesDuplicated(this.firstBottle, this.secondBottle);
    },
    treatSectionPricePerMonth() {
      return this.treatFacePrice / this.treatFaceDuration;
    },
    hasRecommendation() {
      return this.treatFaceRecommendation && this.hasTreatmentRecommendation;
    },
    oneFormulaRecommendation() {
      return get(this.treatFaceRecommendation, 'formulasOptions.oneFormula');
    },
    twoFormulasRecommendation() {
      return get(this.treatFaceRecommendation, 'formulasOptions.twoFormulas');
    },
    isOneFormulaRecommendationFound() {
      return get(this.oneFormulaRecommendation, 'isFound');
    },
    isTwoFormulaRecommendationFound() {
      return get(this.twoFormulasRecommendation, 'isFound');
    }
  },
  watch: {
    treatFaceRecommendation(value) {
      if (value) {
        this.applyTreatFaceRecommendations();
      }
    }
  },
  mounted() {
    if (this.treatFaceRecommendation && !this.firstBottle && !this.secondBottle) {
      this.applyTreatFaceRecommendations();
    }
  },
  methods: {
    ...mapActions({
      updateTreatFace: dashTypes.actions.UPDATE_TREAT_FACE,
      deleteCreateTreatmentError: dashTypes.actions.DELETE_CREATE_TREATMENT_ERROR
    }),
    addMorningSerum() {
      this.firstBottle = {
        ...DEFAULT_COMPOSITION_BOTTLE_VALUES
      };

      if (isBottleDuplicated(this.secondBottle)) {
        this.secondBottle = { ...this.secondBottle, isDuplicated: false };
      }
    },
    addEveningSerum() {
      this.secondBottle = {
        ...DEFAULT_COMPOSITION_BOTTLE_VALUES,
        usage: SERUM_USAGE_KEYS.EVENING_ONLY
      };

      if (isBottleDuplicated(this.firstBottle)) {
        this.firstBottle = { ...this.firstBottle, isDuplicated: false };
      }
    },
    selectFirstBottleUsage(usage) {
      if (usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING) {
        this.deleteSecondBottle();
      }

      if (usage !== SERUM_USAGE_KEYS.EVENING_ONLY) {
        return;
      }

      if (this.secondBottle) {
        this.secondBottle = {
          ...this.secondBottle,
          usage: SERUM_USAGE_KEYS.MORNING_ONLY
        };
      }

      this.swapBottles();
    },
    selectSecondBottleUsage(usage) {
      if (usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING) {
        this.swapBottles();
        this.deleteSecondBottle();
      }

      if (usage !== SERUM_USAGE_KEYS.MORNING_ONLY) {
        return;
      }

      if (this.firstBottle) {
        this.firstBottle = {
          ...this.firstBottle,
          usage: SERUM_USAGE_KEYS.EVENING_ONLY
        };
      }

      this.swapBottles();
    },
    deleteFirstBottle() {
      this.firstBottle = null;
    },
    deleteSecondBottle() {
      this.secondBottle = null;
    },
    deleteFirstBottleError() {
      if (this.hasCreateTreatmentErrors) {
        this.deleteCreateTreatmentError(
          CREATE_TREATMENT_ERRORS.TREAT_FACE_FIRST_BOTTLE_SERUM_VALIDATION
        );
      }
    },
    deleteSecondBottleError() {
      if (this.hasCreateTreatmentErrors) {
        this.deleteCreateTreatmentError(
          CREATE_TREATMENT_ERRORS.TREAT_FACE_SECOND_BOTTLE_SERUM_VALIDATION
        );
      }
    },
    resetBottles() {
      this.deleteFirstBottle();
      this.deleteSecondBottle();
    },
    swapBottles() {
      [this.firstBottle, this.secondBottle] = [this.secondBottle, this.firstBottle];
    },
    applyTreatFaceRecommendations() {
      if (this.isTwoFormulaRecommendationFound) {
        this.useTwoFormulasBottlesRecommendation();

        return;
      }

      if (this.isOneFormulaRecommendationFound) {
        this.useOneFormulaBottlesRecommendation();
      }
    },
    useTwoFormulasBottlesRecommendation() {
      const { compositionBottle1, compositionBottle2 } = this.twoFormulasRecommendation;

      this.firstBottle = getBottleCompositionForRecommendation(compositionBottle1);
      this.secondBottle = getBottleCompositionForRecommendation(
        compositionBottle2,
        SERUM_USAGE_KEYS.EVENING_ONLY
      );
    },
    useOneFormulaBottlesRecommendation() {
      const { compositionBottle1, compositionBottle2, usage } = this.oneFormulaRecommendation;
      const bottleRecommendation = getBottleCompositionForRecommendation(compositionBottle1, usage);

      if (compositionBottle2) {
        bottleRecommendation.isDuplicated = true;
      }

      if (usage === SERUM_USAGE_KEYS.EVENING_ONLY) {
        this.secondBottle = bottleRecommendation;
      } else {
        this.firstBottle = bottleRecommendation;
      }
    },
    recalculate() {
      this.deleteFirstBottleError();
      this.deleteSecondBottleError();

      if (this.firstBottle && this.secondBottle && this.isTwoFormulaRecommendationFound) {
        this.useTwoFormulasBottlesRecommendation();

        return;
      }

      if ((this.firstBottle || this.secondBottle) && this.isOneFormulaRecommendationFound) {
        this.resetBottles();
        this.useOneFormulaBottlesRecommendation();

        return;
      }

      this.applyTreatFaceRecommendations();
    }
  }
};
</script>

<style lang="scss" scoped>
.face-serum {
  &__container {
    padding: 20px 15px 30px;
  }
}

.treat-face {
  .serum__container {
    max-width: calc(50% - 14px);
    width: 100%;
  }

  .options__actives {
    min-width: 100%;
  }

  &__morning-serum {
    margin-right: 20px;
  }

  .list-element__name {
    max-width: 100px;
  }
}

@media (max-width: 930px) {
  .face-serum {
    &__container {
      padding: 20px 0 30px;
    }
  }

  .treat-face {
    .serum__container {
      max-width: calc(50% - 10px);
    }
  }
}

@media (max-width: 767px) {
  .treat-face {
    &__morning-serum {
      margin-right: 0;
    }

    .serum__container {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .serum__options {
    margin-right: auto;
  }

  .options__actives {
    min-width: 280px;
  }

  .face-serum {
    &__container {
      padding: 10px 5px 0;
      margin-bottom: 25px;
    }
  }

  .treat-face {
    flex-direction: column;
    align-items: center;

    &__morning-serum,
    &__default-morning-serum {
      margin-bottom: 15px;
    }
  }
}
</style>
