<template>
  <modal
    name="medical-concentration-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="280"
    height="auto"
    @before-open="beforeOpen"
  >
    <div v-if="ingredient" class="modal-container concentration">
      <div class="concentration__hint ie-text-modal">
        {{
          $t('dashboard.warning.medicalGrade', [ingredientLabel, preMedicalIngredientConcentration])
        }}
      </div>
      <div class="concentration__question ie-text-modal">
        {{ setConcentrationLabel }}
      </div>
      <div class="concentration__buttons ie-text-modal flex--center--center">
        <div class="cancel-button pointer mr20" @click="cancel">
          {{ $t('dashboard.action.cancel') }}
        </div>
        <div class="rounded-button-white" @click="setConcentration">
          {{ $t('dashboard.action.setConcentration') }}
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { get } from 'lodash';

import { formatIngredientConcentration } from '@/modules/dashboard/utils';

import {
  INGREDIENT_MEDICAL_CONCENTRATIONS,
  MEDICAL_CONCENTRATION_KEYS,
  UNSAVED_CHANGES_INPUTS
} from '@/modules/dashboard/api/constants';

export default {
  name: 'MedicalConcentrationModal',
  data() {
    return {
      ingredient: null,
      answerResolver: null,
      selectedConcentration: 1
    };
  },
  computed: {
    ingredientName() {
      return get(this.ingredient, 'name');
    },
    ingredientLabel() {
      return this.$t(`dashboard.ingredients.${this.ingredientName}`);
    },
    preMedicalConcentrationLevel() {
      // The number of capsules before turning to ingredient medical concentration level
      return (
        INGREDIENT_MEDICAL_CONCENTRATIONS[this.ingredient.name] -
        MEDICAL_CONCENTRATION_KEYS.ONE_CAPSULE_MEDICAL_CONCENTRATION
      );
    },
    preMedicalIngredientConcentration() {
      return formatIngredientConcentration(this.preMedicalConcentrationLevel, this.ingredientName);
    },
    setConcentrationLabel() {
      return this.$t('dashboard.label.setConcentration', [
        this.ingredientLabel,
        formatIngredientConcentration(this.selectedConcentration, this.ingredientName)
      ]);
    }
  },
  methods: {
    beforeOpen({ params: { ingredient, concentrationResolver, selectedConcentration } }) {
      this.ingredient = ingredient;
      this.answerResolver = concentrationResolver;
      this.selectedConcentration = selectedConcentration;
    },
    setConcentration() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.YES);
      this.hideModal();
    },
    cancel() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.CANCEL);
      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('medical-concentration-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';

.concentration {
  padding: 30px 20px;
  border-radius: 8px;
  color: $text-color;

  &__hint {
    padding: 15px;
    border-radius: 8px;
    background: $beige;

    font-size: 11px;
    line-height: 16px;
    font-weight: 300;
  }

  &__question {
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;

    text-align: center;
    margin: 30px 0 20px;
  }
}
</style>
