<template>
  <modal
    name="review-message-modal"
    :classes="['modal-window', 'review-message-modal']"
    :click-to-close="false"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <modal-header-with-previous
        class="modal-header-with-previous"
        current-modal-name="review-message-modal"
        previous-modal-name="summary-treatment-modal"
        @after-modal-close="onAfterModalClose"
      >
        <span slot="header-title">
          {{ $t('dashboard.label.reviewTreatmentMessages') }}
        </span>
      </modal-header-with-previous>

      <review-patient-invite-tabs :template-type="$options.templateType" />
      <invite-template-sent-modal />
    </div>
  </modal>
</template>

<script>
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import ReviewPatientInviteTabs from '@/modules/dashboard/components/patient-card/invite/ReviewPatientInviteTabs';

import { REVIEW_TREATMENT_MESSAGE_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'ReviewMessageModal',
  components: {
    ReviewPatientInviteTabs,
    InviteTemplateSentModal,
    ModalHeaderWithPrevious
  },
  templateType: REVIEW_TREATMENT_MESSAGE_TYPE.CREATE_TREATMENT,
  methods: {
    onAfterModalClose(event) {
      this.$emit('after-modal-close', event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/modals/modal-header-with-previous-shared';

.modal-header-with-previous {
  margin-bottom: 0;
}
</style>
