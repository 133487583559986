<template>
  <div class="dropdown">
    <treatment-dropdown class="product-size-dropdown">
      <template slot="dropdown-icon" slot-scope="{ disableDropdownVisibility }">
        <span class="size-label size-label--active" tabindex="-1" @blur="disableDropdownVisibility">
          {{ size }}
        </span>
      </template>
      <template slot="dropdown-options">
        <treatment-product-options :value="size" :options="options" @select-option="selectOption">
          <template slot="option" slot-scope="{ option }">
            <span class="option__content" :class="{ 'option__content--active': size === option }">
              {{ option }}
            </span>
          </template>
        </treatment-product-options>
      </template>
    </treatment-dropdown>
  </div>
</template>

<script>
import TreatmentDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentDropdown';
import TreatmentProductOptions from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductOptions';

export default {
  name: 'ProductSizeDropdown',
  components: { TreatmentDropdown, TreatmentProductOptions },
  props: {
    size: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectOption(size) {
      this.$emit('select-size', size);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/treatment/numeral-dropdowns';

.option {
  &__content {
    font-size: 13px;
  }
}

.product-size-dropdown /deep/ .dropdown-icon {
  height: 20px;
  padding: 5px;
}
</style>
