<template>
  <div
    class="treatment__container"
    :class="{ open: isOpened, 'treatment__container--is-coming-soon': !hasCleanseProducts }"
  >
    <treatment-header
      :title="$t('dashboard.label.cleanse')"
      :icons="cleanseIconsConfig"
      :is-opened="isOpened"
      :price="cleanseTreatmentPrice"
      :has-recommendation="hasTreatmentRecommendation && hasCleanseProducts"
      :is-coming-soon="!hasCleanseProducts"
      @section-toggle="toggleSection"
    />
    <slide-down :show="isOpened">
      <div
        slot="slide-body"
        class="treatment__content"
        :class="{ 'treatment__content--opened': isOpened }"
      >
        <template v-for="product in cleanseProducts">
          <complementary-care-product
            :key="product.name"
            :product="product"
            :is-active="getIsProductActive(product.name)"
            @toggle-active="updateProduct"
            @update-product="updateProduct($event, true)"
          />
        </template>
      </div>
    </slide-down>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

import TreatmentHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentHeader';
import ComplementaryCareProduct from '@/modules/dashboard/components/create-treatment-tab/treatments/ComplementaryCareProduct';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';

import SectionIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/SectionIcon';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_SECTION_NAMES,
  TREATMENT_BOTTLE_ICONS_CONFIG
} from '@/modules/dashboard/api/constants';

export default {
  name: 'Cleanse',
  components: { TreatmentHeader, ComplementaryCareProduct, SlideDown },
  data() {
    return {
      isOpened: false
    };
  },
  computed: {
    ...mapGetters({
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      cleanseProducts: dashTypes.getters.CLEANSE_PRODUCTS,
      cleanseProductsReferences: dashTypes.getters.CLEANSE_PRODUCTS_REFERENCES,
      cleanseTreatment: dashTypes.getters.CLEANSE_TREATMENT,
      cleanseTreatmentPrice: dashTypes.getters.CLEANSE_TREATMENT_PRICE
    }),
    cleanseIconsConfig() {
      return this.cleanseProductsReferences.map(reference => {
        const isProductActive = this.cleanseTreatment.includes(reference);

        return new SectionIcon(TREATMENT_BOTTLE_ICONS_CONFIG[reference], isProductActive);
      });
    },
    hasCleanseProducts() {
      return !isEmpty(this.cleanseProducts);
    }
  },
  methods: {
    ...mapActions({
      toggleComplementaryCareProduct: dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT,
      updateComplementaryCareProduct: dashTypes.actions.UPDATE_COMPLEMENTARY_CARE_PRODUCT
    }),
    toggleSection() {
      if (this.hasCleanseProducts) {
        this.isOpened = !this.isOpened;
      }
    },
    getIsProductActive(product) {
      return this.cleanseTreatment.includes(product);
    },
    async updateProduct(product, isUpdate = false) {
      const actionParams = {
        ...product,
        section: CREATE_TREATMENT_SECTION_NAMES.CLEANSE,
        isUpdate
      };

      if (isUpdate) {
        await this.updateComplementaryCareProduct(actionParams);

        return;
      }

      await this.toggleComplementaryCareProduct(actionParams);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/treatment/create-treatment-shared';
@import '../../../../../assets/scss/helpers/colors';

.treatment__content {
  padding: 20px;
}

@media (max-width: 767px) {
  .treatment__content {
    padding: 15px 5px;
    background-color: $dim-background;
  }
}
</style>
