<template>
  <section class="date-question flex-column--align-center">
    <span class="date-question__text main-text">{{ questionText }}</span>
    <date-input-form
      v-model="dateOfBirth"
      :name="name"
      class="date-question__form"
      required
      @input="updateAnswer"
    />
    <field-error
      v-if="isDateInvalid"
      class="mt10"
      :error-message="$t('dashboard.warning.ageBetween')"
    ></field-error>
  </section>
</template>

<script>
import differenceInYears from 'date-fns/difference_in_years';

import DateInputForm from '@/modules/dashboard/components/dashboard/common/DateInputForm';
import FieldError from '@/modules/dashboard/components/common/FieldError';

import { getDateFromDateObject, getDateObjectFromString } from '@/modules/dashboard/utils';
import { formatDate } from '@/modules/dashboard/utils/date-utils';

import { DATE_FORMAT, MAX_AGE, MIN_AGE } from '@/modules/dashboard/api/constants';

export default {
  name: 'DateQuestion',
  components: { FieldError, DateInputForm },
  inject: ['$validator'],
  props: {
    question: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: 'date'
    },
    value: {
      type: String,
      default: ''
    },
    isQuestionKey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateOfBirth: {
        day: '',
        month: '',
        year: ''
      },
      filledDate: null
    };
  },
  computed: {
    isDateInvalid() {
      if (this.filledDate) {
        const difference = differenceInYears(new Date(), this.filledDate);

        return difference < MIN_AGE || difference > MAX_AGE;
      }

      return false;
    },
    questionText() {
      return this.isQuestionKey ? this.$t(this.question) : this.question;
    }
  },
  watch: {
    value(dateString) {
      this.dateOfBirth = getDateObjectFromString(dateString);
      this.filledDate = new Date(dateString);
    }
  },
  methods: {
    updateAnswer() {
      if (this.dateOfBirth.day && this.dateOfBirth.month && this.dateOfBirth.year.length > 3) {
        this.$emit('input', formatDate(getDateFromDateObject(this.dateOfBirth), DATE_FORMAT));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/text';

.date-question {
  &__text {
    margin-bottom: 10px;
  }
}
</style>
