<template>
  <div
    class="ingredients-group"
    :class="{ 'ingredients-group--highly-recommended': isHighlyRecommended }"
  >
    <div class="ingredients-group__header group-header flex--align-center">
      <div class="custom-icon" :class="groupIcon"></div>
      <span class="group-header__title">{{ groupTitle }}</span>
    </div>
    <ul class="ingredients-group__ingredients-list ingredients-list">
      <li
        v-for="ingredient in groupedIngredients[group]"
        :key="ingredient.name"
        class="ingredients-list__element"
      >
        <ingredient-list-element
          class="ingredients-group__ingredient"
          :ingredient="ingredient"
          :disabled-ingredients="disabledIngredients"
          :is-highly-recommended="isHighlyRecommended"
          @show-ingredient-info="showIngredientInfo"
          @click.native="selectIngredient(ingredient)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import IngredientListElement from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientListElement';

import { HIGHLY_RECOMMENDED_SELECTION_SCORE } from '@/modules/dashboard/api/constants';

const GROUP_ICONS = [
  'icon-can-not-be-used-ingredients',
  'icon-red-cross-bold',
  'icon-check-green',
  'icon-recommended-ingredients',
  'icon-highly-recommended-ingredients'
];

const GROUP_TITLES_KEY_POSTFIX = [
  'canNot',
  'shouldNot',
  'canBe',
  'recommended',
  'highlyRecommended'
];

export default {
  name: 'IngredientsGroup',
  components: { IngredientListElement },
  props: {
    group: {
      type: String,
      required: true
    },
    groupedIngredients: {
      type: Object,
      required: true
    },
    disabledIngredients: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    groupIcon() {
      return GROUP_ICONS[this.group];
    },
    groupTitle() {
      return this.$t(`dashboard.label.groupTitle.${GROUP_TITLES_KEY_POSTFIX[this.group]}`);
    },
    isHighlyRecommended() {
      return parseInt(this.group, 10) === HIGHLY_RECOMMENDED_SELECTION_SCORE;
    }
  },
  methods: {
    selectIngredient(ingredient) {
      this.$emit('select-ingredient', ingredient);
    },
    showIngredientInfo(ingredientName) {
      this.$emit('show-ingredient-info', ingredientName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.icon-red-cross-bold {
  width: 6px;
  height: 6px;
}

.ingredients-group {
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  &--highly-recommended {
    padding: 15px 0 5px 10px;
    border-radius: 5px;
    background: $hovered-button-color;
  }

  &__ingredients-list {
    list-style: none;
    column-count: 3;
    padding: 0;
  }
}

/* TODO: refactor dropdown-select deep usage */
.ingredients-group .ingredients-group__ingredients-list .ingredients-list {
  padding-top: 10px;

  &__element {
    break-inside: avoid;
    padding: 5px 0;
    overflow: hidden;
  }
}

.group-header {
  padding-left: 15px;

  &__title {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;

    color: $main-title-color;

    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .ingredients-group {
    &__ingredients-list {
      column-count: 1;
      margin-bottom: 0;
    }

    &--highly-recommended {
      padding-right: 10px;
    }
  }
}
</style>
