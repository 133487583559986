var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-notification-template"},[_c('default-template-message',[_vm._l((_vm.textareaBindings),function(textarea){return [(textarea.isExist)?_c('template-text-area',{key:textarea.fieldName,ref:textarea.fieldName,refInFor:true,attrs:{"value":textarea.value,"maxlength":textarea.maxLength,"field-name":textarea.fieldName},on:{"input":function($event){return _vm.onMessageFieldInput(Object.assign({}, $event,
            {isTextarea: true,
            refName: textarea.fieldName,
            isSmsCampaign: _vm.isSmsNotificationTemplate}))},"change":_vm.updateCurrentTemplateField}}):_vm._e()]}),(_vm.notificationTemplateConfig.isLinkNameExist)?_c('div',{staticClass:"message__link-name mb10"},[_vm._v(" "+_vm._s(_vm.templateData.linkName)+" ")]):_vm._e()],2),(!_vm.isSmsNotificationTemplate)?_c('div',{staticClass:"template__signature"},[_c('div',{staticClass:"template__label"},[_vm._v(_vm._s(_vm.$t('dashboard.label.signature')))]),_c('input',{ref:"signatureInput",staticClass:"rounded-input template__input input-focus-within",attrs:{"type":"text","maxlength":_vm.fieldsMaxLength.signature},domProps:{"value":_vm.templateData.signature},on:{"input":function($event){return _vm.onMessageFieldInput({
          fieldName: 'signature',
          event: $event,
          refName: 'signatureInput',
          isSmsCampaign: _vm.isSmsNotificationTemplate
        })},"change":function($event){return _vm.updateCurrentTemplateField({ fieldName: 'signature', value: $event.target.value })}}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }