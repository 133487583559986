<template>
  <modal
    name="diagnostic-not-validated-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="280"
    height="auto"
    @before-open="onBeforeOpen"
  >
    <section class="not-valid-diagnostic modal-content flex-column--align-center small-modal-text">
      <span class="not-valid-diagnostic__description ie-text-modal">
        {{ $t('dashboard.warning.diagnosticHasAnomalies') }}
      </span>
      <button
        class="not-valid-diagnostic__go-to-button rounded-button-white"
        @click="goToDiagnostic"
      >
        {{ $t('dashboard.button.goToDiagnostic') }}
      </button>
      <span class="cancel-button pointer" @click="closeModal">
        {{ $t('dashboard.action.cancel') }}
      </span>
    </section>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticNotValidatedModal',
  data() {
    return {
      diagnosticId: null
    };
  },
  methods: {
    ...mapActions({
      setActiveDiagnosticId: dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB
    }),
    onBeforeOpen({ params: { diagnosticId } }) {
      this.diagnosticId = diagnosticId;
    },
    goToDiagnostic() {
      this.setActiveDiagnosticId(this.diagnosticId);
      this.setActivePatientTab(PATIENT_TAB_NAMES.DIAGNOSTICS);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'DiagnosticDetails',
          params: {
            diagnosticId: this.diagnosticId
          }
        });
      }
    },
    closeModal() {
      this.$modal.hide('diagnostic-not-validated-modal');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/common/text';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.not-valid-diagnostic {
  padding: 30px 20px;

  &__description {
    padding: 12px 15px;
    background: $beige;
    border-radius: 8px;
  }

  &__go-to-button {
    margin: 30px 0;
  }
}
</style>
