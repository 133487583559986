export const shouldDisplayNexultraORecommendationModal = (
  hasDiagnosticRecommendation,
  doesTreatmentCompositionIncludeNexultraO,
  actionScores
) => {
  if (!hasDiagnosticRecommendation) {
    return false;
  }

  const shouldRecommend =
    actionScores.red > 5 ||
    actionScores.blue > 6 ||
    actionScores.pink > 8 ||
    actionScores.orange > 4 ||
    actionScores.green > 0;

  if (!doesTreatmentCompositionIncludeNexultraO) {
    return shouldRecommend;
  }

  return false;
};

export default {
  shouldDisplayNexultraORecommendationModal
};
