<template>
  <div class="default-notification-template">
    <default-template-message>
      <template v-for="textarea in textareaBindings">
        <template-text-area
          v-if="textarea.isExist"
          :ref="textarea.fieldName"
          :key="textarea.fieldName"
          :value="textarea.value"
          :maxlength="textarea.maxLength"
          :field-name="textarea.fieldName"
          @input="
            onMessageFieldInput({
              ...$event,
              isTextarea: true,
              refName: textarea.fieldName,
              isSmsCampaign: isSmsNotificationTemplate
            })
          "
          @change="updateCurrentTemplateField"
        />
      </template>
      <div v-if="notificationTemplateConfig.isLinkNameExist" class="message__link-name mb10">
        {{ templateData.linkName }}
      </div>
    </default-template-message>

    <div v-if="!isSmsNotificationTemplate" class="template__signature">
      <div class="template__label">{{ $t('dashboard.label.signature') }}</div>
      <input
        ref="signatureInput"
        type="text"
        class="rounded-input template__input input-focus-within"
        :value="templateData.signature"
        :maxlength="fieldsMaxLength.signature"
        @input="
          onMessageFieldInput({
            fieldName: 'signature',
            event: $event,
            refName: 'signatureInput',
            isSmsCampaign: isSmsNotificationTemplate
          })
        "
        @change="updateCurrentTemplateField({ fieldName: 'signature', value: $event.target.value })"
      />
    </div>
  </div>
</template>

<script>
import TemplateTextArea from '@/modules/dashboard/components/message-templates/TemplateTextArea';
import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';

import TemplateTextAreaBinding from '@/modules/dashboard/components/message-templates/TemplateTextAreaBinding';
import NotificationTemplateConfig from '@/modules/dashboard/components/message-templates/NotificationTemplateConfig';

import {
  MESSAGE_TEMPLATE_TYPES,
  NOTIFICATION_TEMPLATES_TYPES
} from '@/modules/dashboard/api/constants';

const NOTIFICATION_EMAIL_TEMPLATES_CONFIG = {
  [NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED]: new NotificationTemplateConfig(
    true,
    true,
    true,
    false,
    false
  ),
  [NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE]: new NotificationTemplateConfig(
    true,
    true,
    true,
    true,
    true
  ),
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED]: new NotificationTemplateConfig(
    true,
    true,
    true,
    true,
    true
  ),
  [NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT]: new NotificationTemplateConfig(
    true,
    true,
    false,
    false,
    false
  ),
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER]: new NotificationTemplateConfig(
    false,
    true,
    false,
    false,
    true
  ),
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER]: new NotificationTemplateConfig(
    true,
    true,
    true,
    false,
    true
  )
};

const SMS_TEMPLATE_CONFIG = new NotificationTemplateConfig(true, true, false, false, false);

const SMS_TEMPLATE_WITH_LINK_CONFIG = new NotificationTemplateConfig(
  true,
  true,
  false,
  false,
  true
);

const NOTIFICATION_SMS_TEMPLATES_CONFIG = {
  [NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED]: SMS_TEMPLATE_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT]: SMS_TEMPLATE_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING]: SMS_TEMPLATE_WITH_LINK_CONFIG,
  [NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER]: SMS_TEMPLATE_WITH_LINK_CONFIG
};

export default {
  name: 'DefaultNotificationTemplateMessage',
  components: { DefaultTemplateMessage, TemplateTextArea },
  props: {
    notificationType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSmsNotificationTemplate() {
      return this.templateType === MESSAGE_TEMPLATE_TYPES.SMS;
    },
    notificationTemplateConfig() {
      return this.isSmsNotificationTemplate
        ? NOTIFICATION_SMS_TEMPLATES_CONFIG[this.notificationType]
        : NOTIFICATION_EMAIL_TEMPLATES_CONFIG[this.notificationType];
    },
    textareaBindings() {
      return [
        new TemplateTextAreaBinding(
          'greeting',
          this.notificationTemplateConfig.isGreetingExist,
          this.templateData.greeting,
          this.fieldsMaxLength.greeting
        ),
        new TemplateTextAreaBinding(
          'bodyPartOne',
          this.notificationTemplateConfig.isBodyPartOneExist,
          this.templateData.bodyPartOne,
          this.fieldsMaxLength.bodyPartOne
        ),
        new TemplateTextAreaBinding(
          'bodyPartTwo',
          this.notificationTemplateConfig.isBodyPartTwoExist,
          this.templateData.bodyPartTwo,
          this.fieldsMaxLength.bodyPartTwo
        ),
        new TemplateTextAreaBinding(
          'bodyPartThree',
          this.notificationTemplateConfig.isBodyPartThreeExist,
          this.templateData.bodyPartThree,
          this.fieldsMaxLength.bodyPartThree
        )
      ];
    }
  },
  methods: {
    onMessageFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    updateCurrentTemplateField(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/inputs.css';
@import '../../../../../assets/scss/message-templates/message-templates';
</style>
