<template>
  <warning-modal
    class="inform-modal"
    name="inaccessible-nexultra-1-2-inform-modal"
    :title="modalTitle"
    large-screen-width="520"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <p v-if="shouldOfferReplaceProducts">{{ mainText }}</p>
      <p v-else>
        {{ infoText }}
      </p>
    </template>

    <template #secondary-content>
      <i18n
        v-if="shouldOfferReplaceProducts"
        path="dashboard.modal.unavailableProductReplace.secondaryText"
        tag="span"
        class="pre-wrap mb30"
      >
        <span class="bold">{{ $t('dashboard.settings.nexUltraPLight') }}</span>
      </i18n>
    </template>

    <template #actions>
      <confirmation-modal-actions
        v-if="shouldOfferReplaceProducts"
        :confirm-text="$t('dashboard.action.moisturizer.select')"
        @cancel="handleClose(false)"
        @confirm="handleClose(true)"
      />

      <button v-else class=" rounded-button-white" @click="handleClose(false)">
        {{ $t('dashboard.button.ok') }}
      </button>
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions';

export default {
  name: 'InaccessibleNexultra1And2InformModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return {
      onClose: null,
      shouldOfferReplaceProducts: true,
      nexultra1: null,
      nexultra2: null
    };
  },
  computed: {
    modalTitle() {
      const titleForNexUltra1And2 = this.$t('dashboard.modal.unavailableProducts.title', [
        this.$t('dashboard.settings.nexUltra1'),
        this.$t('dashboard.settings.nexUltra2')
      ]);

      const titleForNexUltra1Or2 = this.$t('dashboard.modal.unavailableProduct.title', [
        this.$t(`dashboard.settings.${this.nexultra1 || this.nexultra2}`)
      ]);

      return this.nexultra1 && this.nexultra2 ? titleForNexUltra1And2 : titleForNexUltra1Or2;
    },
    mainText() {
      const mainTextForNexUltra1And2 = this.$t(
        'dashboard.modal.unavailableProductsReplace.mainText',
        [
          this.$t('dashboard.settings.nexUltra1'),
          this.$t('dashboard.settings.nexUltra2'),
          this.$t('dashboard.settings.nexUltraPLight')
        ]
      );

      const mainTextForNexUltra1Or2 = this.$t(
        'dashboard.modal.unavailableProductReplace.mainText',
        [
          this.$t(`dashboard.settings.${this.nexultra1 || this.nexultra2}`),
          this.$t('dashboard.settings.nexUltraPLight')
        ]
      );

      return this.nexultra1 && this.nexultra2 ? mainTextForNexUltra1And2 : mainTextForNexUltra1Or2;
    },
    infoText() {
      const infoTextForNexUltra1And2 = this.$t('dashboard.modal.unavailableProductsInfo.mainText', [
        this.$t('dashboard.settings.nexUltra1'),
        this.$t('dashboard.settings.nexUltra2')
      ]);

      const infoTextForNexUltra1Or2 = this.$t('dashboard.modal.unavailableProductInfo.mainText', [
        this.$t(`dashboard.settings.${this.nexultra1 || this.nexultra2}`)
      ]);

      return this.nexultra1 && this.nexultra2 ? infoTextForNexUltra1And2 : infoTextForNexUltra1Or2;
    }
  },
  methods: {
    onBeforeOpen({ params: { onClose, shouldOfferReplaceProducts, selectedProducts } }) {
      this.onClose = onClose;
      this.shouldOfferReplaceProducts = shouldOfferReplaceProducts;
      const [nexultra1, nexultra2] = selectedProducts;

      this.nexultra1 = nexultra1;
      this.nexultra2 = nexultra2;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons.scss';
</style>
