<template>
  <div
    class="flex--align-center ingredient-element"
    :class="{
      'ingredient-element--disabled': isIngredientDisabled,
      'ingredient-element--highly-recommended': isHighlyRecommended && isIngredientDisabled
    }"
  >
    <div class="ingredient-element__color" :style="{ backgroundColor: ingredient.color }"></div>
    <span
      class="ingredient-element__name"
      :class="{ 'ingredient-element__name--disabled': isIngredientDisabled }"
    >
      {{ $t(`dashboard.ingredients.${ingredientName}`) }}
    </span>
    <span class="ingredient-element__concentration">
      {{ defaultConcentration | ingredientConcentration(ingredientName) }}%
    </span>
    <span
      class="ingredient-element__info custom-icon icon-question"
      @click.stop="showIngredientInfo"
    ></span>
  </div>
</template>

<script>
import { DEFAULT_INGREDIENT_NUMBER_OF_CAPSULES } from '@/modules/dashboard/api/constants';

export default {
  name: 'IngredientListElement',
  props: {
    ingredient: {
      type: Object,
      required: true
    },
    disabledIngredients: {
      type: Array,
      default: () => []
    },
    isHighlyRecommended: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultConcentration: DEFAULT_INGREDIENT_NUMBER_OF_CAPSULES
    };
  },
  computed: {
    ingredientName() {
      return this.ingredient.name;
    },
    isIngredientDisabled() {
      return !!this.disabledIngredients.find(({ name }) => name === this.ingredientName);
    }
  },
  methods: {
    showIngredientInfo() {
      this.$emit('show-ingredient-info', this.ingredientName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.ingredient-element {
  width: 100%;
  max-width: 200px;
  padding: 3px 15px;
  font-size: 13px;
  line-height: 15px;
  border-radius: 20px;

  &:hover {
    background: $light-grey-hover-color;

    .ingredient-element__info {
      display: block;
    }
  }

  &--disabled {
    background-color: $disable-ingredient-color;
  }

  &--highly-recommended {
    background-color: $whitesmoke-color;
  }

  &__concentration {
    margin: 1px 4px 0 4px;

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    color: $grey-color;
  }

  &__icon-close {
    width: 8px;
    height: 8px;
  }

  &__color {
    display: inline-block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    padding: 1px;

    border-radius: 3px;
  }

  &__info {
    display: none;
    margin-left: auto;
  }

  &__name {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;

    font-size: 13px;
    line-height: 15px;

    color: $text-color;

    &--disabled {
      color: $grey-color;
    }
  }
}

@media (max-width: 1024px) {
  .ingredient-element {
    &__info {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .ingredient-element {
    max-width: none;

    &__info {
      margin-left: 12px;
    }

    &__concentration {
      font-size: 13px;
      line-height: 15px;
      font-weight: normal;
    }
  }
}
</style>
