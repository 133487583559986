<template>
  <div class="question">
    <component
      :is="question.componentName"
      v-validate="'required'"
      :value="value"
      :name="question.translationLabel"
      :question="question.question"
      :value-bindings="question.valueBindings"
      is-question-key
      @input="onInput"
    />
  </div>
</template>

<script>
import { isString, isObject } from 'lodash';

import DateQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/DateQuestion';
import RadioButtonQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/RadioButtonQuestion';
import SelectQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/SelectQuestion';

export default {
  name: 'ClarificationQuestion',
  components: { SelectQuestion, DateQuestion, RadioButtonQuestion },
  inject: ['$validator'],
  props: {
    value: {
      validator: prop => isString(prop) || isObject(prop) || prop === null,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    isQuestionKey: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style scoped>
.question {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .question {
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
