<template>
  <section ref="loyaltyParticipantRef" class="participants-select select-section flex flex-column">
    <div class="select-section__label flex">
      <span class="label__text">
        {{ $t('dashboard.label.chargeLoyaltyBonus') }}
      </span>
      <span
        v-tooltip="{ content: $t('dashboard.hint.loyaltyBonus'), trigger: tooltipTrigger }"
        class="info__icon custom-icon icon-info"
      ></span>
    </div>
    <q-select
      label="label"
      :allow-empty="false"
      :placeholder="placeholder"
      :value="selectedParticipant"
      :options="participantsSelectOptions"
      track-by="label"
      :disabled="disabled"
      class="select-section__select custom--with-border"
      @input="onParticipantSelect"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

import QSelect from '@/modules/dashboard/components/common/Select';

import { getLoyaltyProgramParticipants } from '@/modules/dashboard/api';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'LoyaltyParticipantSelect',
  components: { QSelect },
  props: {
    selectedParticipant: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPastTreatment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      participantDropdownScrollbar: null,
      participantsSelectOptions: []
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    placeholder() {
      if (!this.disabled) {
        return this.$t('dashboard.placeholder.selectParticipant');
      }

      return this.isPastTreatment
        ? this.$t('dashboard.pastTreatment.notAvailableForPastTreatments')
        : '';
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  mounted() {
    this.initLoyaltyProgramSelectValues();

    const participantsDropdownRef = this.$refs.loyaltyParticipantRef.querySelector(
      '.select-section__select .multiselect__content-wrapper'
    );

    this.participantDropdownScrollbar = new PerfectScrollbar(
      participantsDropdownRef,
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );
  },
  methods: {
    async initLoyaltyProgramSelectValues() {
      const { data: participants } = await getLoyaltyProgramParticipants(this.userId);

      if (!participants) {
        return;
      }

      this.participantsSelectOptions = participants.map(({ name, id }) => ({
        label: name,
        value: id
      }));

      this.setDefaultParticipant();
    },
    setDefaultParticipant() {
      if (this.participantsSelectOptions.length > 1) {
        return;
      }

      const [participant] = this.participantsSelectOptions;

      this.onParticipantSelect(participant);
    },
    onParticipantSelect(participant) {
      this.$emit('select-participant', participant);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/perfect-scrollbar-styles';

.select-section {
  &__label {
    margin-bottom: 10px;
    line-height: 15px;
  }
}

.label {
  &__text {
    font-weight: 300;
    font-size: 13px;
    color: $text-color;
  }
}
</style>
