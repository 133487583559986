<template>
  <div class="treat-section full-width">
    <treat-face-section />
    <eye-contour-section />
    <template v-if="isUniverskinHAvailable">
      <div class="divider"></div>
      <universkin-h-section />
    </template>
    <active-ingredients-mobile-selector-modal />
    <ingredient-info-modal @click.native.stop />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TreatFaceSection from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/TreatFaceSection';
import EyeContourSection from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/EyeContourSection';
import UniverskinHSection from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/UniverskinHSection';
import ActiveIngredientsMobileSelectorModal from '@/modules/dashboard/components/create-treatment-tab/ActiveIngredientsMobileSelectorModal';
import IngredientInfoModal from '@/modules/dashboard/components/create-treatment-tab/IngredientInfoModal';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PRODUCT_REFERENCES } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatSectionContent',
  components: {
    IngredientInfoModal,
    ActiveIngredientsMobileSelectorModal,
    UniverskinHSection,
    EyeContourSection,
    TreatFaceSection
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      treatSectionPrice: dashTypes.getters.TREAT_SECTION_PRICE,
      availableComplementaryCare: dashTypes.getters.AVAILABLE_COMPLEMENTARY_CARE
    }),
    isUniverskinHAvailable() {
      return this.availableComplementaryCare.includes(PRODUCT_REFERENCES.UNIVERSKIN_H);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
</style>
