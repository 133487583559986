<template>
  <section class="universkin-h flex--center--center">
    <template v-for="product in products">
      <complementary-care-product
        :key="product.name"
        :product="product"
        :is-active="getIsProductActive(product.name)"
        :is-disabled="getIsProductDisabled(product.name)"
        :disabled-tooltip-text="$t('dashboard.warning.universkinSelectedWithinRebalance')"
        @toggle-active="updateProduct"
      />
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ComplementaryCareProduct from '@/modules/dashboard/components/create-treatment-tab/treatments/ComplementaryCareProduct';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  PRODUCT_REFERENCES,
  CREATE_TREATMENT_DEFAULT_PRODUCTS,
  CREATE_TREATMENT_SECTION_NAMES
} from '@/modules/dashboard/api/constants';

const UNIVERSKIN_H_SECTION_PRODUCTS = [
  CREATE_TREATMENT_DEFAULT_PRODUCTS[PRODUCT_REFERENCES.UNIVERSKIN_H]
];

export default {
  name: 'UniverskinHSection',
  components: { ComplementaryCareProduct },
  computed: {
    ...mapGetters({
      moisturizeTreatment: dashTypes.getters.MOISTURIZE_TREATMENT,
      universkinHSectionSelection: dashTypes.getters.UNIVERSKIN_H_SECTION_SELECTION,
      moisturizeProducts: dashTypes.getters.MOISTURIZE_PRODUCTS
    }),
    universkinHProduct() {
      return this.moisturizeProducts.find(({ name }) => name === PRODUCT_REFERENCES.UNIVERSKIN_H);
    },
    products() {
      return this.universkinHProduct ? [this.universkinHProduct] : UNIVERSKIN_H_SECTION_PRODUCTS;
    },
    isUniveskinHInMoisturizeSection() {
      return this.universkinHSectionSelection === CREATE_TREATMENT_SECTION_NAMES.TREAT;
    },
    isUniverskinHDisabled() {
      return (
        this.moisturizeTreatment.includes(PRODUCT_REFERENCES.UNIVERSKIN_H) &&
        !this.isUniveskinHInMoisturizeSection
      );
    }
  },
  methods: {
    ...mapActions({
      toggleComplementaryCareProduct: dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT,
      updateComplementaryCareProduct: dashTypes.actions.UPDATE_COMPLEMENTARY_CARE_PRODUCT,
      setUniverskinHSectionSelection: dashTypes.actions.SET_UNIVERSKIN_H_SECTION_SELECTION
    }),
    getIsProductDisabled(productName) {
      return productName === PRODUCT_REFERENCES.UNIVERSKIN_H ? this.isUniverskinHDisabled : false;
    },
    getIsProductActive(product) {
      if (product === PRODUCT_REFERENCES.UNIVERSKIN_H && !this.isUniveskinHInMoisturizeSection) {
        return false;
      }

      return this.moisturizeTreatment.includes(product);
    },
    async updateProduct(product, isUpdate = false) {
      const { isActive } = product;

      await this.setUniverskinHSectionSelection(
        isActive ? CREATE_TREATMENT_SECTION_NAMES.TREAT : null
      );

      const actionParams = {
        ...product,
        section: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
        isUpdate
      };

      if (isUpdate) {
        await this.updateComplementaryCareProduct(actionParams);

        return;
      }

      await this.toggleComplementaryCareProduct(actionParams);
    }
  }
};
</script>

<style lang="scss" scoped>
.universkin-h {
  margin: 25px 0;
}

@media (max-width: 767px) {
  .universkin-h {
    padding: 0 5px;
    margin: 15px 0;
  }
}
</style>
