<template>
  <div class="ingredient-placeholder flex--align-center full-width">
    <div class="ingredient-placeholder__icon"></div>
    <div class="ingredient-placeholder__input ingredient-input">
      <input
        v-model="searchIngredient"
        class="transparent-input main-placeholder ingredient-input__search pointer"
        :placeholder="placeholder"
        @click="focusSearchInput"
        @keypress.enter="addSuggestion"
        @blur="addSuggestion"
      />
      <span class="ingredient-input__search-ingredient">{{ searchIngredient }}</span>
      <span class="ingredient-input__suggestion">{{ suggestion.translation }}</span>
    </div>
    <div
      v-if="hasClearIcon"
      class="ingredient-placeholder__close-icon custom-icon icon-close"
      @click="clearSearchIngredient"
    ></div>
  </div>
</template>

<script>
import { findOptionNameSuggestion } from '@/modules/dashboard/utils/create-treatment-utils';

export default {
  name: 'IngredientInputSelector',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    ingredientOptions: {
      type: Array,
      default: () => []
    },
    hasClearIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchIngredient: ''
    };
  },
  computed: {
    searchOptions() {
      return this.ingredientOptions.map(({ name }) => ({
        name,
        translation: this.$t(`dashboard.ingredients.${name}`)
      }));
    },
    suggestion() {
      return findOptionNameSuggestion(this.searchOptions, this.searchIngredient);
    }
  },
  methods: {
    addSuggestion() {
      if (!this.searchIngredient && !this.suggestion.name) {
        return;
      }

      this.$emit('add-suggestion', this.suggestion.name);

      this.clearSearchIngredient();
    },
    // IE 11 lose focus after dropdown is opened. Workaround for this case
    async focusSearchInput({ target }) {
      await this.$nextTick();

      target.focus();
    },
    clearSearchIngredient() {
      this.searchIngredient = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs.css';

.ingredient-placeholder {
  &__icon {
    width: 20px;
    height: 20px;

    margin-right: 12px;

    background: $dim-white;
    border-radius: 3px;
  }

  &__input {
    font-size: 13px;
    line-height: 15px;
    color: $text-color;
  }

  &__close-icon {
    margin-left: auto;
  }
}

.ingredient-input {
  position: relative;

  &__search {
    position: relative;
    padding: 1px 0;
    z-index: 3;
  }

  &__search:hover::placeholder {
    color: $orange-color;
  }

  &__search:hover:-ms-input-placeholder {
    color: $orange-color;
  }

  &__search:hover::-ms-input-placeholder {
    color: $orange-color;
  }

  &__search::-ms-clear {
    display: none;
  }

  &__search-ingredient,
  &__suggestion {
    position: absolute;
    top: 1px;
    left: 0;
  }

  &__search-ingredient {
    z-index: 2;

    background-color: $white;
    white-space: pre;
  }

  &__suggestion {
    z-index: 1;

    background-color: $blue-steel-color;
    color: $white;
  }
}

@media (max-width: 767px) {
  .ingredient-placeholder {
    &__input {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
