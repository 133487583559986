var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"serum__container"},[(_vm.value.isDuplicated)?_c('div',{staticClass:"serum serum--duplicate",class:_vm.duplicateSerumClasses}):_vm._e(),_c('section',{staticClass:"serum flex"},[_c('div',{staticClass:"serum__actions flex-column--align-center"},[_c('span',{staticClass:"custom-icon icon-close",on:{"click":_vm.deleteSerum}}),(_vm.isMicroneedlingAvailable)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('dashboard.microneedling.applyProcedure'),
          trigger: _vm.tooltipTrigger
        }),expression:"{\n          content: $t('dashboard.microneedling.applyProcedure'),\n          trigger: tooltipTrigger\n        }"}],staticClass:"custom-icon mt20",class:_vm.microneedlingIconClasses,on:{"click":function($event){return _vm.toggleMicroneedling(!_vm.isMicroneedlingActiveForBottle)}}}):_vm._e(),(_vm.canBeDuplicated)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.$t('dashboard.action.duplicateSerum'), trigger: _vm.tooltipTrigger }),expression:"{ content: $t('dashboard.action.duplicateSerum'), trigger: tooltipTrigger }"}],staticClass:"custom-icon icon-copy-serum mt20",class:{ 'icon-copy-serum--active': _vm.value.isDuplicated },on:{"click":function($event){return _vm.toggleDuplication(_vm.value.isDuplicated)}}}):_vm._e()]),_c('div',{staticClass:"serum__options options"},[_c('div',{staticClass:"options__header header flex--align-center"},[_c('div',{staticClass:"header__icon product-header-icon mr10"},[_vm._v(" P ")]),_c('div',{staticClass:"header__info info flex-column"},[_c('div',{staticClass:"flex--align-center"},[_c('span',{staticClass:"info__text"},[_vm._v("Universkin Serum P")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('dashboard.label.serumPTooltip'),
                classes: 'custom-tooltip serum-tooltip',
                trigger: _vm.tooltipTrigger
              }),expression:"{\n                content: $t('dashboard.label.serumPTooltip'),\n                classes: 'custom-tooltip serum-tooltip',\n                trigger: tooltipTrigger\n              }"}],staticClass:"info__icon custom-icon icon-info"})]),(_vm.isRecommended)?_c('div',{staticClass:"flex--align-center"},[_c('span',{staticClass:"custom-icon icon-check-green mr5"}),_c('i',{staticClass:"recommended-text"},[_vm._v(_vm._s(_vm.$t('dashboard.label.recommended')))])]):_vm._e()])]),_c('div',{staticClass:"options__actives"},_vm._l((_vm.activeIngredients),function(ingredient){return _c('active-ingredients-dropdown',{key:ingredient,staticClass:"mb10",attrs:{"value":_vm.value[ingredient],"can-change-concentration":_vm.canChangeConcentration,"disabled-ingredients":_vm.disabledIngredients,"section":_vm.section},on:{"update-ingredient":function($event){return _vm.updateIngredient(ingredient, $event)}}})}),1)]),_c('div',{staticClass:"serum__usage usage flex-column--align-center space-between"},[_c('time-usage-dropdown',{attrs:{"usage":_vm.value.usage,"options":_vm.activeTimeUsage.options},on:{"select-usage":_vm.selectUsage},nativeOn:{"click":function($event){return _vm.onDropdownClick($event, 'usage', _vm.value.usage, _vm.activeTimeUsage.options)}}}),_c('div',{staticClass:"usage__bottle bottle flex-column--align-center"},[_c('span',{staticClass:"custom-icon icon-universkin-serum-p-active cursor-default mb5"}),_c('span',{staticClass:"bottle__capacity"},[_vm._v(_vm._s(_vm.serumSize))])])],1),(_vm.isMicroneedlingActiveForBottle)?_c('microneedling-serum-badge',{staticClass:"serum__microneedling-badge",attrs:{"needle-size":_vm.microneedlingConfig.needleSize,"volume":_vm.microneedlingConfig.volume,"is-needle-size-editable":_vm.isNeedleSizeEditable},on:{"select-size":_vm.onNeedleSizeChange,"close":function($event){return _vm.toggleMicroneedling(false)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }