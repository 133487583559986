<template>
  <modal
    name="treatment-selection-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :click-to-close="false"
    width="800px"
    height="auto"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container">
      <treatment-selection :is-header-with-back-button="isHeaderWithBackButton" />
    </div>
  </modal>
</template>

<script>
import TreatmentSelection from '@/modules/dashboard/components/dashboard/create-treatment/treatment-directory/TreatmentSelection';

export default {
  name: 'TreatmentSelectionModal',
  components: { TreatmentSelection },
  data() {
    return {
      isHeaderWithBackButton: true
    };
  },
  methods: {
    onBeforeOpen({ params: { isHeaderWithBackButton = true } }) {
      this.isHeaderWithBackButton = isHeaderWithBackButton;
    }
  }
};
</script>
