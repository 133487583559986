<template>
  <div class="diagnostic-details__container">
    <div class="diagnostic-details flex space-between">
      <patient-diagnostic-photo
        :key="diagnostic.id"
        :diagnostic="diagnostic"
        class="diagnostic-details__patient-photo"
      />
      <skin-dysfunctions-list
        class="diagnostic-details__dysfunction-list full-width"
        :diagnostic="diagnostic"
        :is-questionnaire-diagnostic="isQuestionnaireDiagnostic"
        :has-title="false"
      />
    </div>
  </div>
</template>

<script>
import PatientDiagnosticPhoto from '@/modules/dashboard/components/create-treatment-tab/PatientDiagnosticPhoto';
import SkinDysfunctionsList from '@/modules/dashboard/components/diagnostic-tab/SkinDysfunctionsList';

import { DIAGNOSTIC_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticRecommendationDetails',
  components: { SkinDysfunctionsList, PatientDiagnosticPhoto },
  props: {
    diagnostic: {
      type: Object,
      required: true
    }
  },
  computed: {
    isQuestionnaireDiagnostic() {
      return this.diagnostic.type === DIAGNOSTIC_TYPES.QUESTIONNAIRE_BASED;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.diagnostic-details {
  &__patient-photo {
    margin-top: 10px;
  }

  &__dysfunction-list {
    margin-top: 12px;
    margin-right: 9%;
    max-width: 430px;
  }
}

@media (max-width: 991px) {
  .diagnostic-details {
    &__dysfunction-list {
      margin-right: 5%;
      margin-left: 4%;
    }
  }
}

@media (max-width: 767px) {
  .diagnostic-details {
    flex-direction: column;
    align-items: center;

    &__patient-photo {
      align-self: center;
    }

    &__dysfunction-list {
      margin: 15px 0 0;
    }
  }

  .diagnostic-details /deep/ .dysfunctions-item {
    &.toggle {
      background-color: $background;
      margin-bottom: 10px;
    }
  }

  .diagnostic-details /deep/ .inner-list {
    &__item-title {
      color: $text-color;
      opacity: 0.6;
    }
  }
}
</style>
