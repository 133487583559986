<template>
  <div
    class="product flex--space-between--center"
    :class="{ 'product--active': isActive, 'product--disabled': isDisabled }"
  >
    <div class="product__left-container flex--align-center ml15">
      <div
        v-tooltip="{ content: disabledTooltipValue, trigger: tooltipTrigger }"
        class="product__include-icon custom-icon mr15"
        :class="[isActive ? 'icon-close' : 'icon-plus-active', { 'cursor-default': isDisabled }]"
        @click="toggleActive"
      ></div>
      <div
        class="product__identifier-icon mr10"
        :class="{ 'product__identifier-icon--active': isActive }"
      >
        {{ productLetter }}
      </div>
      <div class="product__info flex--align-center">
        <div class="flex-column">
          <div
            class="info__product-name main-text flex"
            :class="{ 'info__product-name--non-active': !isActive }"
          >
            {{ $t(productConfig.nameKey) }}

            <div
              v-tooltip="{ content: $t(productConfig.hintText), trigger: tooltipTrigger }"
              class="info__icon custom-icon icon-info"
            ></div>
          </div>
          <div v-if="product.isRecommended" class="flex--align-center">
            <span class="custom-icon icon-check-green mr5"></span>
            <i class="recommended-text">{{ $t('dashboard.label.recommended') }}</i>
          </div>
        </div>
      </div>
    </div>
    <div class="product__right-contaier flex--space-between--center">
      <div v-if="hasTimeUsage" class="product__time-usage">
        <div v-if="isActive && !hasTimeUsageOptions">
          <div class="custom-icon cursor-default" :class="usageIconClasses[product.usage]"></div>
        </div>
        <time-usage-dropdown
          v-if="isActive && hasTimeUsageOptions"
          :usage="product.usage"
          :options="productConfig.usageOptions"
          @select-usage="selectUsage"
          @click.native="
            onDropdownClick($event, 'usage', product.usage, productConfig.usageOptions)
          "
        />
      </div>
      <div class="product__size">
        <div v-if="!isActive || !hasSizeOptions" class="size__text">
          {{ productSize }}
        </div>
        <product-size-dropdown
          v-else
          :size="productSize"
          :options="productSizeOptions"
          @select-size="selectSize"
          @click.native="onDropdownClick($event, 'size', productSize, productSizeOptions)"
        />
      </div>
      <div class="product__bottle-icon">
        <div
          class="custom-icon cursor-default"
          :class="`${productConfig.bottleIcon}${isActive ? '-active' : ''}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

import TimeUsageDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TimeUsageDropdown';
import ProductSizeDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/ProductSizeDropdown';

import { isMobileDevice } from '@/modules/dashboard/utils';
import { getTreatmentProductConfig } from '@/modules/dashboard/utils/create-treatment-utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  PRODUCT_LETTERS,
  TREATMENT_USAGE_ICON_CLASSES,
  DROPDOWN_TYPES,
  PRODUCT_SIZE_LABEL,
  COMPLEMENTARY_PRODUCT_SIZE_UNITS
} from '@/modules/dashboard/api/constants';

export default {
  name: 'ComplementaryCareProduct',
  components: { TimeUsageDropdown, ProductSizeDropdown },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    disabledTooltipText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      usageIconClasses: TREATMENT_USAGE_ICON_CLASSES,
      productConfig: null
    };
  },
  computed: {
    ...mapGetters({
      getIsComplementaryCareAvailable: dashTypes.getters.GET_IS_COMPLEMENTARY_CARE_AVAILABLE
    }),
    productName() {
      return this.product.name;
    },
    productLetter() {
      return PRODUCT_LETTERS[this.productName];
    },
    hasTimeUsage() {
      return !!this.product.usage;
    },
    hasTimeUsageOptions() {
      return !isEmpty(this.productConfig.usageOptions);
    },
    productSize() {
      return `${this.product.size}${this.sizeMeasurement}`;
    },
    productSizeOptions() {
      return this.productConfig.sizeOptions
        .filter(size => this.getIsComplementaryCareAvailable(this.productName, size))
        .map(size => `${size}${this.sizeMeasurement}`);
    },
    hasSizeOptions() {
      return this.productSizeOptions.length > 1;
    },
    sizeMeasurement() {
      const sizeLabel = PRODUCT_SIZE_LABEL[COMPLEMENTARY_PRODUCT_SIZE_UNITS[this.productName]];
      return this.$i18n.t(sizeLabel);
    },
    disabledTooltipValue() {
      return this.isDisabled && this.disabledTooltipText;
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  created() {
    this.productConfig = getTreatmentProductConfig(this.productName);
  },
  methods: {
    toggleActive() {
      if (this.isDisabled) {
        return;
      }

      this.$emit('toggle-active', { product: this.product, isActive: !this.isActive });
    },
    async onDropdownClick(event, dropdownType, value, options) {
      if (!isMobileDevice()) {
        return;
      }

      const selectedOption = await this.showOptionsModalOnMobile(dropdownType, value, options);

      switch (dropdownType) {
        case DROPDOWN_TYPES.USAGE: {
          this.selectUsage(selectedOption);
          break;
        }

        default: {
          this.selectSize(selectedOption);
        }
      }

      event.stopPropagation();
    },
    showOptionsModalOnMobile(type, value, options) {
      return new Promise(resolve => {
        this.$modal.show('treatment-product-dropdown-modal', {
          type,
          value,
          options,
          optionResolver: resolve
        });
      });
    },
    selectUsage(usage) {
      this.$emit('update-product', { product: { ...this.product, usage } });
    },
    selectSize(productSize) {
      const size = parseInt(productSize, 10) || 0;

      this.$emit('update-product', { product: { ...this.product, size } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/tooltips.css';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/scss/helpers/colors';

.product {
  max-width: 47%;
  width: 100%;
  height: 60px;

  background: $background;
  border: 1px solid $dim-white;
  border-radius: 8px;

  &--active {
    border-color: transparent;
    background-color: $white;
    box-shadow: 0px 6px 7px rgba(148, 166, 176, 0.15);
  }

  &--disabled {
    opacity: 0.5;
  }

  &__include-icon {
    padding: 10px 0;
  }

  &__identifier-icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;

    border: 1px solid $grey-color;
    border-radius: 3px;

    text-align: center;
    font-size: 12px;
    line-height: 30px;
    font-weight: 600;

    color: $grey-color;

    &--active {
      border-color: transparent;
      color: $white;
      background: $grey-color;
    }
  }

  &__right-contaier,
  &__time-usage,
  &__size {
    margin-right: 20px;
  }

  &__info {
    padding-right: 20px;

    &:hover {
      padding-right: 0;

      .info {
        &__icon {
          display: block;
        }
      }
    }
  }
}

.info {
  &__product-name {
    &--non-active {
      color: $grey-color;
    }
  }

  &__icon {
    display: block;
  }
}

.size {
  &__text {
    font-size: 10px;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .product {
    max-width: 400px;

    &__right-contaier,
    &__time-usage,
    &__size {
      margin-right: 15px;
    }
  }

  .info {
    &__product-name {
      font-size: 12px;
    }

    &__icon {
      display: block;
    }
  }
}
</style>
