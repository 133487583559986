<template>
  <div class="options flex-column--align-center">
    <li
      v-for="option in options"
      :key="option"
      class="dropdown__option flex--center--center pointer"
      :class="{ 'dropdown__option--active': value === option }"
      @mousedown="selectOption(option)"
    >
      <slot name="option" v-bind="{ option }"></slot>
    </li>
  </div>
</template>

<script>
export default {
  name: 'TreatmentProductOptions',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectOption(value) {
      this.$emit('select-option', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.options {
  padding: 15px 30px;
}

.dropdown {
  &__option {
    width: 85px;
    height: 30px;

    border: 1px solid $dim-white;
    border-radius: 15px;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    &--active {
      border: 0;
      background-color: $hovered-button-color;

      cursor: default;
    }
  }
}

.option {
  &__content {
    &--active {
      opacity: 0.5;
    }
  }
}

@media (max-width: 1080px) {
  .dropdown {
    &__option {
      width: 70px;
    }
  }
}

@media (max-width: 767px) {
  .dropdown {
    &__option {
      font-size: 13px;
    }
  }
}
</style>
