<template>
  <section class="treatment-selection flex-column">
    <component
      :is="headerComponent"
      class="treatment-selection__modal-header"
      current-modal-name="treatment-selection-modal"
      previous-modal-name="new-treatment-patient-details-modal"
      :previous-modal-params="$options.previousModalParams"
      return-to-opened-patient
    >
      <span slot="header-title" class="title-text">
        {{ $t('treatmentDirectory.treatmentSelection') }}
      </span>
    </component>
    <div class="treatment-selection__questions flex-column flex--center--center">
      <clarification-question
        v-for="question in visibleQuestions"
        :key="question.translationLabel"
        v-model="question.answer"
        :question="question"
        is-question-key
      />
    </div>
    <footer class="treatment-selection__footer treatment-selection-footer">
      <div class="divider otherXS"></div>
      <div class="treatment-selection-footer__button-container flex--center--center">
        <button
          class="rounded-button-orange"
          :disabled="isQuestionsNotAnswered"
          @click="onSeeRecommendedTreatmentClick"
        >
          {{ $t('treatmentDirectory.seeRecommendedTreatments') }}
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import { get, isObject, pick } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';
import SelectOptionObject from '@/components/common/SelectOptionObject';
import DashboardQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/DashboardQuestion';
import ClarificationQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/ClarificationQuestion';
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';

import { getMedicalDataSelectOptionsWithLabel, handleRoutingInfo } from '@/modules/dashboard/utils';
import { getPatientMedicalData, updatePatientMedicalData } from '@/modules/dashboard/api';
import { PHOTOTYPE } from '@/modules/dashboard/api/medicalData';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { GENDER } from '@/modules/questionnaire/api/constants';
import { TREATMENT_DIRECTORY_TYPE } from '@/modules/dashboard/constants/treatment-directory';

import {
  DASHBOARD_QUESTION_COMPONENTS,
  NEW_TREATMENT_FLOW_TYPES,
  UPDATE_PATIENT_FIELDS
} from '@/modules/dashboard/api/constants';

const QUESTION_FIELDS = {
  TREATMENT_TYPE: 'name',
  PATIENT_GENDER: 'gender',
  SKIN_PHOTOTYPE: 'phototype',
  SENSITIVE_SKIN: 'sensitiveSkin',
  SKIN_THICKNESS: 'skinThickness'
};

const TREATMENT_DIRECTORY_ROUTING_INFO = new RoutingInfo(
  'treatment-directory-modal',
  'TreatmentDirectory'
);

const TREATMENT_SELECTION_QUESTIONS = {
  [QUESTION_FIELDS.TREATMENT_TYPE]: new DashboardQuestion({
    fieldName: QUESTION_FIELDS.TREATMENT_TYPE,
    translationLabel: 'typeOfTreatment',
    question: 'treatmentDirectory.pleaseSelect',
    componentName: DASHBOARD_QUESTION_COMPONENTS.SELECT_QUESTION,
    valueBindings: [
      new SelectOptionObject(
        'treatmentDirectory.skinConditions',
        TREATMENT_DIRECTORY_TYPE.SKIN_CONDITIONS
      ),
      new SelectOptionObject(
        'treatmentDirectory.surgicalActs',
        TREATMENT_DIRECTORY_TYPE.SURGICAL_ACTS
      ),
      new SelectOptionObject(
        'treatmentDirectory.nonSurgicalActs',
        TREATMENT_DIRECTORY_TYPE.NON_SURGICAL_AND_LEDS_LASERS
      ),
      new SelectOptionObject('Universkin s.tep', TREATMENT_DIRECTORY_TYPE.MICRONEEDLING)
    ],
    answer: null
  }),
  [QUESTION_FIELDS.PATIENT_GENDER]: new DashboardQuestion({
    fieldName: QUESTION_FIELDS.PATIENT_GENDER,
    translationLabel: 'whatIsPatientGender',
    question: 'treatmentDirectory.genderOfPatient',
    valueBindings: [
      { translationLabel: 'dashboard.gender.female', value: 'female' },
      { translationLabel: 'dashboard.gender.male', value: 'male' }
    ]
  }),
  [QUESTION_FIELDS.SKIN_PHOTOTYPE]: new DashboardQuestion({
    fieldName: QUESTION_FIELDS.SKIN_PHOTOTYPE,
    translationLabel: 'patientSkinPhototype',
    question: 'treatmentDirectory.skinPhototype',
    componentName: DASHBOARD_QUESTION_COMPONENTS.SELECT_QUESTION,
    valueBindings: PHOTOTYPE.map(phototype => new SelectOptionObject(phototype, phototype)),
    answer: null
  }),
  [QUESTION_FIELDS.SENSITIVE_SKIN]: new DashboardQuestion({
    fieldName: QUESTION_FIELDS.SENSITIVE_SKIN,
    translationLabel: 'haveSensitiveSkin',
    question: 'treatmentDirectory.patientHaveSensitiveSkin'
  }),
  [QUESTION_FIELDS.SKIN_THICKNESS]: new DashboardQuestion({
    fieldName: QUESTION_FIELDS.SKIN_THICKNESS,
    translationLabel: 'skinThickness',
    question: 'treatmentDirectory.skinThickness',
    valueBindings: [
      { translationLabel: 'treatmentDirectory.thin', value: 'thin' },
      { translationLabel: 'treatmentDirectory.thick', value: 'thick' }
    ]
  })
};

const PREVIOUS_MODAL_PARAMS = {
  newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_TREATMENT_DIRECTORY
};

export default {
  name: 'TreatmentSelection',
  components: { ModalHeader, ClarificationQuestion, ModalHeaderWithPrevious },
  previousModalParams: PREVIOUS_MODAL_PARAMS,
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visibleQuestions: [],
      answeredQuestions: [],
      patientMedicalData: {}
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      isMicroneedlingAvailable: dashTypes.getters.IS_MICRONEEDLING_AVAILABLE
    }),
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    },
    isQuestionsNotAnswered() {
      return this.errors.any();
    }
  },
  async mounted() {
    const gender = get(this.currentPatient, 'gender', '');

    try {
      this.setLoading(true);
      const { data } = await getPatientMedicalData(this.currentPatientId);
      this.patientMedicalData = data;
    } catch (error) {
      this.patientMedicalData = {};
    } finally {
      this.setLoading(false);
    }

    const { phototypeDr, skinReactsToProductsDr, skinThicknessDr } =
      this.patientMedicalData.json || {};

    const questions = [
      {
        ...TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.TREATMENT_TYPE],
        valueBindings: this.filterTreatmentTypes(
          TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.TREATMENT_TYPE].valueBindings
        )
      },
      {
        ...TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.PATIENT_GENDER],
        answer: gender || ''
      },
      {
        ...TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.SKIN_PHOTOTYPE],
        valueBindings: getMedicalDataSelectOptionsWithLabel(this.$t, PHOTOTYPE),
        answer: phototypeDr
      },
      {
        ...TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.SENSITIVE_SKIN],
        answer: skinReactsToProductsDr || ''
      },
      {
        ...TREATMENT_SELECTION_QUESTIONS[QUESTION_FIELDS.SKIN_THICKNESS],
        answer: skinThicknessDr || ''
      }
    ];

    questions.forEach(question => {
      if (!question.answer) {
        this.visibleQuestions.push(question);

        return;
      }

      this.answeredQuestions.push(question);
    });
  },
  methods: {
    ...mapMutations({
      setLoading: rootTypes.actions.SET_LOADING,
      setTreatmentDirectoryQuestions: dashTypes.mutations.SET_TREATMENT_DIRECTORY_QUESTIONS
    }),
    ...mapActions({
      updatePatientInfo: dashTypes.actions.UPDATE_PATIENT_INFO
    }),
    filterTreatmentTypes(treatmentTypes) {
      return treatmentTypes.filter(
        ({ value }) =>
          value !== TREATMENT_DIRECTORY_TYPE.MICRONEEDLING || this.isMicroneedlingAvailable
      );
    },
    async onSeeRecommendedTreatmentClick() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const questions = [...this.answeredQuestions, ...this.visibleQuestions];

      const treatmentType = this.getAnswerByFieldName(QUESTION_FIELDS.TREATMENT_TYPE, questions);
      const gender = this.getAnswerByFieldName(QUESTION_FIELDS.PATIENT_GENDER, questions);
      const phototype = this.getAnswerByFieldName(QUESTION_FIELDS.SKIN_PHOTOTYPE, questions);
      const skinThickness = this.getAnswerByFieldName(QUESTION_FIELDS.SKIN_THICKNESS, questions);
      const sensitiveSkin = this.getAnswerByFieldName(QUESTION_FIELDS.SENSITIVE_SKIN, questions);

      const patientInfo = pick(this.currentPatient, UPDATE_PATIENT_FIELDS);

      this.updatePatientInfo({
        patientId: this.currentPatientId,
        patientData: { ...patientInfo, gender }
      });

      const medicalDataJson = {
        ...this.patientMedicalData.json,
        phototypeDr: phototype,
        skinThicknessDr: skinThickness,
        skinReactsToProductsDr: sensitiveSkin
      };

      const medicalData = { ...this.patientMedicalData, json: medicalDataJson };

      updatePatientMedicalData(this.currentPatientId, this.patientMedicalData.id, medicalData);

      const recommendationsGender = gender === GENDER.OTHER ? GENDER.MALE : gender;

      this.setTreatmentDirectoryQuestions({
        name: treatmentType,
        gender: recommendationsGender,
        phototype,
        skinThickness,
        sensitiveSkin
      });

      this.closeCurrentModal();
      handleRoutingInfo(TREATMENT_DIRECTORY_ROUTING_INFO, this.$router, this.$modal);
    },
    getAnswerByFieldName(questionFieldName, questions = []) {
      const question = questions.find(({ fieldName }) => fieldName === questionFieldName);
      const answer = get(question, 'answer', null);

      return isObject(answer) ? answer.value : answer;
    },
    closeCurrentModal() {
      this.$modal.hide('treatment-selection-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/common/text';

.treatment-selection {
  height: 100%;

  &__questions {
    height: 580px;
    overflow: auto;
  }

  &__footer {
    height: 70px;
  }
}

.treatment-selection-footer {
  &__button-container {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .treatment-selection {
    min-height: 100vh;
    height: auto;
    background-color: white;

    &__questions {
      padding: 85px 30px 70px;
    }

    &__modal-header {
      position: fixed;
      top: 0;
      z-index: 1;
      background-color: $background;
    }

    &__footer {
      height: 60px;

      position: fixed;
      background: $white;
      box-shadow: 0 -6px 7px rgba(148, 166, 176, 0.15);
    }
  }
}
</style>
