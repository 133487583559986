import {
  ACTIVES,
  INGREDIENTS,
  INGREDIENT_ACTION_BLOCKSIZES
} from '@/modules/dashboard/api/constants';

import IngredientAction from '@/modules/dashboard/components/create-treatment-tab/IngredientAction';

const INGREDIENTS_ACTIONS = {
  [INGREDIENTS.FERULIC]: { primaryAction: new IngredientAction(ACTIVES.OXIDATIVE_STRESS) },
  [INGREDIENTS.DMAE]: { primaryAction: new IngredientAction(ACTIVES.SKIN_APPEARANCE) },
  [INGREDIENTS.DPANTHENOL]: { primaryAction: new IngredientAction(ACTIVES.SKIN_DRYNESS) },
  [INGREDIENTS.GLYCOLIC]: { primaryAction: new IngredientAction(ACTIVES.SKIN_TEXTURE) },
  [INGREDIENTS.ARBUTIN]: { primaryAction: new IngredientAction(ACTIVES.SKIN_PIGMENTATION) },
  [INGREDIENTS.KOJIC]: { primaryAction: new IngredientAction(ACTIVES.SKIN_PIGMENTATION) },
  [INGREDIENTS.SOD]: {
    primaryAction: new IngredientAction(ACTIVES.OXIDATIVE_STRESS),
    secondaryActions: [
      new IngredientAction(ACTIVES.SKIN_REDNESS, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM)
    ]
  },
  [INGREDIENTS.MADECA]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_APPEARANCE),
    secondaryActions: [new IngredientAction(ACTIVES.SKIN_REDNESS)]
  },
  [INGREDIENTS.AZELAIC]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_REDNESS),
    secondaryActions: [new IngredientAction(ACTIVES.SKIN_PIGMENTATION)]
  },
  [INGREDIENTS.RUTIN]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_REDNESS),
    secondaryActions: [
      new IngredientAction(ACTIVES.OXIDATIVE_STRESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL)
    ]
  },
  [INGREDIENTS.SALICYLIC]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_TEXTURE),
    secondaryActions: [new IngredientAction(ACTIVES.SKIN_REDNESS)]
  },
  [INGREDIENTS.PHYTIC]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_TEXTURE),
    secondaryActions: [new IngredientAction(ACTIVES.OXIDATIVE_STRESS)]
  },
  [INGREDIENTS.ZINC]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_OILINESS),
    secondaryActions: [new IngredientAction(ACTIVES.PIMPLES, INGREDIENT_ACTION_BLOCKSIZES.SMALL)]
  },
  [INGREDIENTS.LACTO]: {
    primaryAction: new IngredientAction(ACTIVES.PIMPLES),
    secondaryActions: [
      new IngredientAction(ACTIVES.SKIN_OILINESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL)
    ]
  },
  [INGREDIENTS.ASCORBIC]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_APPEARANCE),
    secondaryActions: [
      new IngredientAction(ACTIVES.OXIDATIVE_STRESS, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_PIGMENTATION, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_REDNESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL),
      new IngredientAction(ACTIVES.SKIN_TEXTURE, INGREDIENT_ACTION_BLOCKSIZES.SMALL),
      new IngredientAction(ACTIVES.SKIN_DRYNESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL)
    ]
  },
  [INGREDIENTS.RETINOL]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_APPEARANCE),
    secondaryActions: [
      new IngredientAction(ACTIVES.SKIN_PIGMENTATION, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.OXIDATIVE_STRESS, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_TEXTURE, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM)
    ]
  },
  [INGREDIENTS.NIACINAMIDE]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_REDNESS),
    secondaryActions: [
      new IngredientAction(ACTIVES.SKIN_PIGMENTATION, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_APPEARANCE, INGREDIENT_ACTION_BLOCKSIZES.SMALL),
      new IngredientAction(ACTIVES.OXIDATIVE_STRESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL),
      new IngredientAction(ACTIVES.SKIN_DRYNESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL)
    ]
  },
  [INGREDIENTS.ALOE]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_DRYNESS),
    secondaryActions: [
      new IngredientAction(ACTIVES.SKIN_APPEARANCE, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_REDNESS, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM)
    ]
  },
  [INGREDIENTS.ISO]: {
    primaryAction: new IngredientAction(ACTIVES.SKIN_APPEARANCE),
    secondaryActions: [
      new IngredientAction(ACTIVES.OXIDATIVE_STRESS, INGREDIENT_ACTION_BLOCKSIZES.MEDIUM),
      new IngredientAction(ACTIVES.SKIN_PIGMENTATION, INGREDIENT_ACTION_BLOCKSIZES.SMALL),
      new IngredientAction(ACTIVES.SKIN_REDNESS, INGREDIENT_ACTION_BLOCKSIZES.SMALL)
    ]
  }
};

const COMPLEMENTARY_INFORMATION_KEYS = {
  TENSOR_EFFECT: 'tensorEffect',
  EVENING_PREFERABLE: 'eveningPreferable',
  NOT_FOR_PREGNANT: 'notForPregnant',
  NOT_DARK_PHOTOTYPE: 'notDarkPhototype',
  PREVENT_ALTERATION: 'preventAlteration',
  VASCULAR_ACTION: 'vascularAction',
  EXFOLIANT_STRENGTH_ONE: 'exfoliantStrength1',
  EXFOLIANT_STRENGTH_TWO: 'exfoliantSrtength2',
  EXFOLIANT_STRENGTH_FOUR: 'exfoliantSrtength4',
  SHOULD_USE_WITH_UV: 'shouldUseWithUV',
  SHOULD_USE_WITH_UV_MORNING: 'shouldUseWithUVMorning',
  CAN_USE_AROUND_EYE_CONTOUR: 'canUseAroundEyeContour',
  CANNOT_USE_AROUND_EYE_CONTOUR: 'cannotUseAroundEyeContour'
};

const INGREDIENTS_COMPLEMENTARY_INFORMATION = {
  [INGREDIENTS.FERULIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.DMAE]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.TENSOR_EFFECT
  ],
  [INGREDIENTS.DPANTHENOL]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.GLYCOLIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.EVENING_PREFERABLE,
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV_MORNING,
    COMPLEMENTARY_INFORMATION_KEYS.CANNOT_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.EXFOLIANT_STRENGTH_FOUR,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_FOR_PREGNANT
  ],
  [INGREDIENTS.ARBUTIN]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_DARK_PHOTOTYPE,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_FOR_PREGNANT
  ],
  [INGREDIENTS.KOJIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_DARK_PHOTOTYPE,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_FOR_PREGNANT
  ],
  [INGREDIENTS.SOD]: [COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV],
  [INGREDIENTS.MADECA]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.AZELAIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.PREVENT_ALTERATION
  ],
  [INGREDIENTS.RUTIN]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.VASCULAR_ACTION
  ],
  [INGREDIENTS.SALICYLIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CANNOT_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.EXFOLIANT_STRENGTH_TWO
  ],
  [INGREDIENTS.PHYTIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CANNOT_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.EXFOLIANT_STRENGTH_TWO
  ],
  [INGREDIENTS.ZINC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CANNOT_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.LACTO]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CANNOT_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.ASCORBIC]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.EXFOLIANT_STRENGTH_ONE
  ],
  [INGREDIENTS.RETINOL]: [
    COMPLEMENTARY_INFORMATION_KEYS.EVENING_PREFERABLE,
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV_MORNING,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_DARK_PHOTOTYPE,
    COMPLEMENTARY_INFORMATION_KEYS.EXFOLIANT_STRENGTH_TWO,
    COMPLEMENTARY_INFORMATION_KEYS.NOT_FOR_PREGNANT
  ],
  [INGREDIENTS.NIACINAMIDE]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR,
    COMPLEMENTARY_INFORMATION_KEYS.PREVENT_ALTERATION
  ],
  [INGREDIENTS.ALOE]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR
  ],
  [INGREDIENTS.ISO]: [
    COMPLEMENTARY_INFORMATION_KEYS.SHOULD_USE_WITH_UV,
    COMPLEMENTARY_INFORMATION_KEYS.CAN_USE_AROUND_EYE_CONTOUR
  ]
};

export default class IngredientDescription {
  constructor(name, $t) {
    this.name = name;
    this.actions = INGREDIENTS_ACTIONS[name];
    this.description = $t(`dashboard.ingredients.description.${name}`);
    this.complementaryInformation = INGREDIENTS_COMPLEMENTARY_INFORMATION[name];
  }
}
