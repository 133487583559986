export default class NotificationTemplateConfig {
  constructor(
    isGreetingExist,
    isBodyPartOneExist,
    isBodyPartTwoExist,
    isBodyPartThreeExist,
    isLinkNameExist
  ) {
    this.isGreetingExist = isGreetingExist;
    this.isBodyPartOneExist = isBodyPartOneExist;
    this.isBodyPartTwoExist = isBodyPartTwoExist;
    this.isBodyPartThreeExist = isBodyPartThreeExist;
    this.isLinkNameExist = isLinkNameExist;
  }
}
