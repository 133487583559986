import {
  DASHBOARD_QUESTION_COMPONENTS,
  YES_NO_QUESTION_BINDINGS
} from '@/modules/dashboard/api/constants';

export default class DashboardQuestion {
  constructor({
    fieldName,
    translationLabel,
    question,
    answer = '',
    componentName,
    valueBindings
  }) {
    this.fieldName = fieldName;
    this.translationLabel = translationLabel;
    this.question = question;
    this.answer = answer;
    this.componentName = componentName || DASHBOARD_QUESTION_COMPONENTS.RADIO_BUTTON_QUESTION;
    this.valueBindings = valueBindings;

    if (!componentName && !valueBindings) {
      this.valueBindings = [...YES_NO_QUESTION_BINDINGS];
    }
  }
}
