<template>
  <default-template-message class="mb30">
    <div class="default-email-message-template">
      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__title"
          field-name="greeting"
          :value="templateData.greeting"
          :max-length="fieldsMaxLength.greeting"
          @field-input="onTreatmentRenewOfferFieldInput"
          @field-change="onTreatmentRenewOfferFieldChange"
        />

        <email-message-template-text-area
          class="default-email-message-template__paragraph"
          field-name="bodyPartOne"
          :value="templateData.bodyPartOne"
          :max-length="fieldsMaxLength.bodyPartOne"
          @field-input="onTreatmentRenewOfferFieldInput"
          @field-change="onTreatmentRenewOfferFieldChange"
        />
      </div>

      <email-message-template-link-section :link-name="templateData.linkName" />

      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__paragraph"
          field-name="bodyPartThree"
          :value="templateData.bodyPartThree"
          :max-length="fieldsMaxLength.bodyPartThree"
          @field-input="onTreatmentRenewOfferFieldInput"
          @field-change="onTreatmentRenewOfferFieldChange"
        />
      </div>

      <email-message-template-link-section :link-name="templateData.alternativeLinkName" />

      <email-message-template-signature-section :template-data="templateData" />
    </div>
  </default-template-message>
</template>

<script>
import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';
import EmailMessageTemplateTextArea from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateTextArea';
import EmailMessageTemplateLinkSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateLinkSection';
import EmailMessageTemplateSignatureSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateSignatureSection';

export default {
  name: 'TreatmentRenewOfferEmailTemplate',
  components: {
    DefaultTemplateMessage,
    EmailMessageTemplateTextArea,
    EmailMessageTemplateLinkSection,
    EmailMessageTemplateSignatureSection
  },
  props: {
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    }
  },
  methods: {
    onTreatmentRenewOfferFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    onTreatmentRenewOfferFieldChange(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';
</style>
