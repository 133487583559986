<template>
  <treatment-dropdown class="concentration-dropdown">
    <template slot="dropdown-icon" slot-scope="{ disableDropdownVisibility }">
      <span class="size-label size-label--active" tabindex="-1" @blur="disableDropdownVisibility">
        {{ ingredientConcentration }}%
      </span>
    </template>
    <template slot="dropdown-options">
      <treatment-product-options
        :value="numberOfCapsules"
        :options="options"
        @select-option="selectOption"
      >
        <template slot="option" slot-scope="{ option }">
          <div
            class="option__content concentration flex--center--center"
            :class="{
              'option__content--active': numberOfCapsules === option,
              'option__content--medical': getHasMedicalConcentration(option)
            }"
          >
            <ingredient-icon :ingredient-color="ingredientColor" :number-of-capsules="option" />
            <span
              class="concentration__text"
              :class="{ 'concentration__text--active': numberOfCapsules === option }"
            >
              {{ option | ingredientConcentration(ingredientName) }}%
            </span>
          </div>
        </template>
      </treatment-product-options>
    </template>
  </treatment-dropdown>
</template>

<script>
import TreatmentDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentDropdown';
import IngredientIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientIcon';

import TreatmentProductOptions from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductOptions';

import { formatIngredientConcentration } from '@/modules/dashboard/utils';
import { getHasMedicalConcentration } from '@/modules/dashboard/utils/create-treatment-utils';

import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

export default {
  name: 'ConcentrationsDropdown',
  components: { IngredientIcon, TreatmentDropdown, TreatmentProductOptions },
  props: {
    ingredient: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    numberOfCapsules() {
      return this.ingredient.nbOfCapsules;
    },
    ingredientName() {
      return this.ingredient.name;
    },
    ingredientColor() {
      return this.ingredient.color;
    },
    ingredientConcentration() {
      return formatIngredientConcentration(this.numberOfCapsules, this.ingredientName);
    }
  },
  methods: {
    async selectOption(numberOfCapsules) {
      if (!this.getHasMedicalConcentration(numberOfCapsules)) {
        this.updateNumberOfCapsules(numberOfCapsules);

        return;
      }

      const answer = await this.showModalToSetMedicalConcentration(numberOfCapsules);

      if (answer === UNSAVED_CHANGES_INPUTS.CANCEL) {
        return;
      }

      this.updateNumberOfCapsules(numberOfCapsules);
    },
    updateNumberOfCapsules(numberOfCapsules) {
      this.$emit('select-number-of-capsules', numberOfCapsules);
    },
    getHasMedicalConcentration(numberOfCapsules) {
      return getHasMedicalConcentration(this.ingredientName, numberOfCapsules);
    },
    showModalToSetMedicalConcentration(selectedConcentration) {
      return new Promise(resolve =>
        this.$modal.show('medical-concentration-modal', {
          ingredient: this.ingredient,
          concentrationResolver: resolve,
          selectedConcentration
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/treatment/numeral-dropdowns';

.option {
  &__content {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;

    color: $text-color;

    &--medical {
      color: $medical-concentration-color;
    }
  }
}

.concentration-dropdown /deep/ .dropdown-icon {
  height: 20px;
  padding: 10px;
}
</style>
