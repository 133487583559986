<template>
  <div class="treatment-directory-preview" @click="selectTreatmentPreview">
    <div class="treatment-directory-preview__name">
      {{ treatmentPreview.name }}
    </div>
    <div
      v-if="treatmentPreview.clarifyingInformation"
      class="treatment-directory-preview__clarifying-information"
    >
      {{ treatmentPreview.clarifyingInformation }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreatmentDirectoryPreview',
  props: {
    treatmentPreview: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    selectTreatmentPreview() {
      this.$emit('select-treatment-preview', this.treatmentPreview);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.treatment-directory-preview {
  height: 54px;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: 45% 55%;

  &:hover {
    background: $white;
    box-shadow: 0 4px 5px rgba(148, 166, 176, 0.1);
  }

  &__name {
    padding-right: 20px;

    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
    color: $text-color;
  }

  &__clarifying-information {
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .treatment-directory-preview {
    height: auto;
    padding: 10px 15px;

    grid-template-columns: 1fr;

    &__name {
      padding-right: 0;

      font-size: 12px;
      color: $main-title-color;
    }

    &__clarifying-information {
      margin-top: 5px;

      font-size: 10px;
      line-height: 12px;
      font-weight: 300;
    }
  }
}
</style>
