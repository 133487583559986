<template>
  <div class="treatment__container" :class="{ open: isOpened }">
    <div class="treatment-header__container space-between" :class="{ open: isOpened }">
      <div class="full-width">
        <treatment-header
          :title="$t('dashboard.label.treat')"
          :icons="treatIconsConfig"
          :is-opened="isOpened"
          :price="treatSectionPrice"
          :has-recommendation="hasTreatmentRecommendation"
          :has-one-active-ingridient-warning="hasOneActiveIngridientWarning"
          class="treatment-header"
          @section-toggle="toggleSection"
        />
      </div>
    </div>
    <slide-down :show="isOpened" :class="{ treatment__content: isOpened }">
      <div
        slot="slide-body"
        :key="treatSectionKey"
        class="treat__content full-width"
        :class="{ 'treatment__content--opened': isOpened }"
      >
        <treat-section-content />
      </div>
    </slide-down>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TreatmentHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentHeader';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';
import TreatSectionContent from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/TreatSectionContent';

import SectionIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/SectionIcon';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_SECTION_NAMES,
  PRODUCT_REFERENCES
} from '@/modules/dashboard/api/constants';

export default {
  name: 'Treat',
  components: {
    TreatSectionContent,
    TreatmentHeader,
    SlideDown
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      treatFace: dashTypes.getters.TREAT_FACE,
      treatEyes: dashTypes.getters.TREAT_EYES,
      treatSectionPrice: dashTypes.getters.TREAT_SECTION_PRICE,
      createTreatmentType: dashTypes.getters.CREATE_TREATMENT_TYPE,
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      treatmentRecommendation: dashTypes.getters.TREATMENT_RECOMMENDATION,
      universkinHSectionSelection: dashTypes.getters.UNIVERSKIN_H_SECTION_SELECTION,
      moisturizeTreatment: dashTypes.getters.MOISTURIZE_TREATMENT
    }),
    treatSectionKey() {
      return `${this.createTreatmentType}${!!this.treatmentRecommendation}`;
    },
    isUniverskinHSelectedInTreatSection() {
      return (
        this.universkinHSectionSelection === CREATE_TREATMENT_SECTION_NAMES.TREAT &&
        this.moisturizeTreatment.includes(PRODUCT_REFERENCES.UNIVERSKIN_H)
      );
    },
    treatIconsConfig() {
      return [
        new SectionIcon('icon-universkin-serum-p', !!this.treatFace.compositionBottle1),
        new SectionIcon('icon-universkin-serum-p', !!this.treatFace.compositionBottle2),
        new SectionIcon('icon-universkin-serum-p', !!this.treatEyes.compositionBottle1),
        new SectionIcon('icon-universkin-h', this.isUniverskinHSelectedInTreatSection)
      ];
    },
    hasOneActiveIngridientWarning() {
      if (!this.treatmentRecommendation) {
        return;
      }

      const { onlyOneFaceActiveWarning } = this.treatmentRecommendation;

      return onlyOneFaceActiveWarning;
    }
  },
  methods: {
    toggleSection() {
      this.$emit('toggle-selection');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/treatment/create-treatment-shared';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/text';

.treatment-header {
  &__container.open {
    height: 70px;

    display: flex;
  }
}

.treatment {
  &__content {
    padding: 0;
  }
}

.treat {
  &__content {
    padding: 0 20px;
  }
}

.ing-wrapper {
  width: 100%;
  min-width: 280px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .treatment {
    &__container {
      margin-top: 0;
    }
  }

  .treatment-header {
    &__container.open {
      border-bottom: none;
      flex-direction: column;
      height: auto;
    }
  }

  .treat {
    &__content {
      padding: 0;
    }
  }
}
</style>
