<template>
  <default-template-message class="mb30">
    <div class="incomplete-questionnaire-email-template default-email-message-template">
      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__title"
          field-name="greeting"
          :value="templateData.greeting"
          :max-length="fieldsMaxLength.greeting"
          @field-input="onIncompleteQuestionnaireFieldInput"
          @field-change="onIncompleteQuestionnaireFieldChange"
        />

        <email-message-template-text-areas
          :textarea-bindings="textareaBindings"
          @field-input="onIncompleteQuestionnaireFieldInput"
          @field-change="onIncompleteQuestionnaireFieldChange"
        />
      </div>

      <email-message-template-link-section :link-name="templateData.linkName" />

      <div
        class="default-email-message-template__section default-email-message-template__section--with-top-border"
      >
        <email-message-template-text-area
          class="default-email-message-template__additional-info"
          field-name="bodyPartThree"
          :value="templateData.bodyPartThree"
          :max-length="fieldsMaxLength.bodyPartThree"
          @field-input="onIncompleteQuestionnaireFieldInput"
          @field-change="onIncompleteQuestionnaireFieldChange"
        />
      </div>

      <email-message-template-signature-section :template-data="templateData" />
    </div>
  </default-template-message>
</template>

<script>
import EmailMessageTemplateTextArea from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateTextArea';
import EmailMessageTemplateLinkSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateLinkSection';
import EmailMessageTemplateSignatureSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateSignatureSection';
import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';

import { getExistingFieldsTextareaBindings } from '@/modules/dashboard/utils';
import EmailMessageTemplateTextAreas from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateTextAreas';

export default {
  name: 'IncompleteQuestionnaireEmailTemplate',
  components: {
    EmailMessageTemplateTextAreas,
    DefaultTemplateMessage,
    EmailMessageTemplateTextArea,
    EmailMessageTemplateLinkSection,
    EmailMessageTemplateSignatureSection
  },
  props: {
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    }
  },
  computed: {
    textareaBindings() {
      return getExistingFieldsTextareaBindings({
        fields: ['bodyPartOne', 'bodyPartTwo'],
        templateData: this.templateData,
        fieldsMaxLength: this.fieldsMaxLength
      });
    }
  },
  methods: {
    onIncompleteQuestionnaireFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    onIncompleteQuestionnaireFieldChange(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';
</style>
