<template>
  <section>
    <mobile-header class="composition-section__header header">
      <span slot="header-title" class="header__label">
        {{ $t('dashboard.label.reviewOfferComposition') }}
      </span>
    </mobile-header>
    <main
      ref="composition-section"
      class="composition-section__content flex-column flex--align-center"
    >
      <section class="content__summary-info-section">
        <summary-info label-key="dashboard.label.treatmentOfferFor" use-active-promo-price />
      </section>
      <section class="content__treatment-section">
        <div class="treatment-section__header onlyXS">
          <div class="treatment-section__header-label inline-block">
            {{ $t('dashboard.summary.treatmentComposition') }}
          </div>
        </div>
        <div class="treatment-section__list">
          <treatment-composition :composition="treatmentComposition" />
        </div>
      </section>
    </main>
    <mobile-fixed-footer class="composition-section__mobile-footer">
      <footer slot="footer" class="composition-section__footer flex--center--center">
        <button class="cancel-button" @click="onBackClick">
          {{ $t('dashboard.action.back') }}
        </button>
      </footer>
    </mobile-fixed-footer>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import SummaryInfo from '@/modules/dashboard/components/create-treatment-tab/SummaryInfo';
import TreatmentComposition from '@/modules/dashboard/components/common/TreatmentComposition';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types } from '@/modules/dashboard/store/types';
import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'OfferComposition',
  components: { MobileHeader, SummaryInfo, TreatmentComposition, MobileFixedFooter },
  data() {
    return {
      scrollbar: null,
      treatmentComposition: []
    };
  },
  computed: {
    ...mapGetters({
      getSummaryTreatmentComposition: types.getters.SUMMARY_TREATMENT_COMPOSITION
    })
  },
  mounted() {
    if (!isMobileDevice()) {
      this.scrollbar = new PerfectScrollbar(
        this.$refs['composition-section'],
        DEFAULT_PERFECT_SCROLLBAR_OPTIONS
      );
    }

    this.treatmentComposition = this.getSummaryTreatmentComposition({ useActivePromoPrice: true });
  },
  methods: {
    onBackClick() {
      if (isMobileDevice()) {
        this.$router.go(-1);

        return;
      }

      this.$modal.show('send-offer-modal');
      this.$modal.hide('offer-composition-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/css/common/perfect-scrollbar-styles';

.composition-section {
  &__content {
    height: 570px;
    position: relative;
  }

  &__footer {
    height: 70px;
    border-top: 1px solid $dim-white;
  }
}

.content {
  &__summary-info-section {
    width: 400px;
    margin: 50px 0;
  }

  &__treatment-section {
    width: 350px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .composition-section {
    &__header {
      height: 45px;
      padding: 0 15px;

      &.header {
        background-color: $background;
      }
    }

    &__content {
      height: auto;
      padding: 60px 0 150px;
    }

    &__footer {
      height: 60px;
    }

    &__mobile-footer {
      background: $white;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .header {
    &__label {
      font-size: 13px;
      font-weight: 400;
      color: $text-color;
    }
  }

  .content {
    &__summary-info-section {
      width: 100%;

      margin: 0 0 30px;
    }

    &__treatment-section {
      width: 100%;
      max-width: 350px;
      padding-bottom: 0;
    }
  }

  .treatment-section {
    &__header {
      margin-bottom: 30px;
      text-align: center;
      border-bottom: 1px solid $border-light-grey-color;

      &-label {
        margin-bottom: -1px;
        padding: 0 10px 15px;

        font-size: 12px;
        font-weight: 600;
        color: $text-color;

        border-bottom: 1px solid $orange-color;
      }
    }

    &__list {
      padding: 0 20px;
    }
  }
}
</style>
