import { render, staticRenderFns } from "./DiagnosticRecommendationDetails.vue?vue&type=template&id=07ea4702&scoped=true&"
import script from "./DiagnosticRecommendationDetails.vue?vue&type=script&lang=js&"
export * from "./DiagnosticRecommendationDetails.vue?vue&type=script&lang=js&"
import style0 from "./DiagnosticRecommendationDetails.vue?vue&type=style&index=0&id=07ea4702&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ea4702",
  null
  
)

export default component.exports