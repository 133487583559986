<template>
  <default-template-message class="mb30">
    <div class="questionnaire-completed-email-template default-email-message-template">
      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__title"
          field-name="greeting"
          :value="templateData.greeting"
          :max-length="fieldsMaxLength.greeting"
          @field-input="onQuestionnaireCompletedFieldInput"
          @field-change="onQuestionnaireCompletedFieldChange"
        />

        <email-message-template-text-area
          class="default-email-message-template__paragraph"
          field-name="bodyPartOne"
          :value="templateData.bodyPartOne"
          :max-length="fieldsMaxLength.bodyPartOne"
          @field-input="onQuestionnaireCompletedFieldInput"
          @field-change="onQuestionnaireCompletedFieldChange"
        />
      </div>

      <div class="default-email-message-template__section flex--center--center">
        <div class="questionnaire-completed-email-template__spider-chart-container">
          <img
            class="questionnaire-completed-email-template__spider-chart"
            :src="spiderChartUrl"
            alt="spider chart example"
          />
        </div>
      </div>

      <div class="default-email-message-template__section">
        <email-message-template-text-area
          class="default-email-message-template__additional-info"
          field-name="bodyPartTwo"
          :value="templateData.bodyPartTwo"
          :max-length="fieldsMaxLength.bodyPartTwo"
          @field-input="onQuestionnaireCompletedFieldInput"
          @field-change="onQuestionnaireCompletedFieldChange"
        />
      </div>

      <div
        class="default-email-message-template__section default-email-message-template__section--with-top-border"
      >
        <email-message-template-text-area
          class="default-email-message-template__additional-info"
          field-name="bodyPartThree"
          :value="templateData.bodyPartThree"
          :max-length="fieldsMaxLength.bodyPartThree"
          @field-input="onQuestionnaireCompletedFieldInput"
          @field-change="onQuestionnaireCompletedFieldChange"
        />
      </div>

      <email-message-template-signature-section :template-data="templateData" />
    </div>
  </default-template-message>
</template>

<script>
import { get } from 'lodash';

import DefaultTemplateMessage from '@/modules/dashboard/components/message-templates/common/DefaultTemplateMessage';
import EmailMessageTemplateTextArea from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateTextArea';
import EmailMessageTemplateSignatureSection from '@/modules/dashboard/components/message-templates/common/EmailMessageTemplateSignatureSection';

import { DEFAULT_LOCALE } from '@/constants';

export default {
  name: 'QuestionnaireCompletedEmailTemplate',
  components: {
    DefaultTemplateMessage,
    EmailMessageTemplateTextArea,
    EmailMessageTemplateSignatureSection
  },
  props: {
    templateData: {
      type: Object,
      required: true
    },
    fieldsMaxLength: {
      type: Object,
      required: true
    }
  },
  computed: {
    spiderChartUrl() {
      const language = get(this.templateData, 'language') || DEFAULT_LOCALE;

      return require(`@/assets/images/dashboard/notification-templates/spider-chart-example/${language}.png`);
    }
  },
  methods: {
    onQuestionnaireCompletedFieldInput(eventValue) {
      this.$emit('field-input', eventValue);
    },
    onQuestionnaireCompletedFieldChange(eventValue) {
      this.$emit('field-change', eventValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';

.questionnaire-completed-email-template {
  &__spider-chart {
    max-width: 100%;
  }

  &__spider-chart-container {
    max-width: 500px;
  }
}
</style>
