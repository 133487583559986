<template>
  <warning-modal
    class="inform-modal"
    name="inaccessible-nexultra3-inform-modal"
    :title="modalTitle"
    large-screen-width="520"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <i18n
        v-if="shouldOfferReplaceProducts"
        path="dashboard.modal.unavailableProductReplace.mainText"
        tag="span"
        class="pre-wrap mb30"
      >
        <span>{{ $t('dashboard.settings.nexUltra3') }}</span>
        <span>{{ $t('dashboard.settings.nexUltraPRich') }}</span>
      </i18n>
      <i18n
        v-else
        path="dashboard.modal.unavailableProductInfo.mainText"
        tag="span"
        class="pre-wrap mb30"
      >
        <span>{{ $t('dashboard.settings.nexUltra3') }}</span>
      </i18n>
    </template>

    <template #secondary-content>
      <i18n
        v-if="shouldOfferReplaceProducts"
        path="dashboard.modal.unavailableProductReplace.secondaryText"
        tag="span"
        class="pre-wrap mb30"
      >
        <span class="bold">{{ $t('dashboard.settings.nexUltraPRich') }}</span>
      </i18n>
    </template>

    <template #actions>
      <confirmation-modal-actions
        v-if="shouldOfferReplaceProducts"
        :confirm-text="$t('dashboard.action.moisturizer.select')"
        @cancel="handleClose(false)"
        @confirm="handleClose(true)"
      />

      <button v-else class=" rounded-button-white" @click="handleClose(false)">
        {{ $t('dashboard.button.ok') }}
      </button>
    </template>
  </warning-modal>
</template>

<script>
import WarningModal from '@/modules/dashboard/components/common/warning-modal/WarningModal.vue';
import ConfirmationModalActions from '@/modules/dashboard/components/common/warning-modal/ConfirmationModalActions';

export default {
  name: 'InaccessibleNexultra3InformModal',
  components: { WarningModal, ConfirmationModalActions },
  data() {
    return { onClose: null, shouldOfferReplaceProducts: true };
  },
  computed: {
    modalTitle() {
      return this.$t('dashboard.modal.unavailableProduct.title', [
        this.$t('dashboard.settings.nexUltra3')
      ]);
    }
  },
  methods: {
    onBeforeOpen({ params: { onClose, shouldOfferReplaceProducts } }) {
      this.onClose = onClose;
      this.shouldOfferReplaceProducts = shouldOfferReplaceProducts;
    },
    handleClose(confirm) {
      this.onClose({ confirm });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons.scss';
</style>
