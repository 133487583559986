<template>
  <modal
    name="diagnostic-recommendation-details-modal"
    :classes="['scrollable-modal']"
    transition="scale"
    width="100%"
    height="100%"
    @before-open="beforeOpen"
  >
    <div v-if="diagnostic" class="diagnostic-recommendation">
      <mobile-header class="recommendation-mobile-header">
        <span slot="back-button" class="custom-icon icon-close mr15" @click="closeModal"></span>
        <diagnostic
          slot="header-title"
          class="full-width"
          :diagnostic="diagnostic"
          display-diagnostic-date
        ></diagnostic>
      </mobile-header>
      <diagnostic-recommendation-details
        class="diagnostic-recommendation__details"
        :diagnostic="diagnostic"
      />
    </div>
  </modal>
</template>

<script>
import DiagnosticRecommendationDetails from '@/modules/dashboard/components/create-treatment-tab/DiagnosticRecommendationDetails';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import Diagnostic from '@/modules/dashboard/components/diagnostic-tab/Diagnostic';

export default {
  name: 'DiagnosticRecommendationDetailsModal',
  components: { Diagnostic, MobileHeader, DiagnosticRecommendationDetails },
  data() {
    return {
      diagnostic: null
    };
  },
  methods: {
    beforeOpen({ params: { diagnostic } }) {
      this.diagnostic = diagnostic;
    },
    closeModal() {
      this.$modal.hide('diagnostic-recommendation-details-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';

.diagnostic-recommendation {
  min-height: 100vh;

  padding: 66px 0 20px;

  background: $white;
}

@media (max-width: 767px) {
  .diagnostic-recommendation {
    padding: 0 0 20px;
  }

  .recommendation-mobile-header {
    background: $background;
    position: sticky;
  }
}
</style>
