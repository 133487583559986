<template>
  <modal
    name="review-send-offer-message-modal"
    :classes="['modal-window', 'review-message-modal']"
    :click-to-close="false"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container">
      <component
        :is="modalHeaderComponent"
        class="modal-header-with-previous"
        current-modal-name="review-send-offer-message-modal"
        previous-modal-name="send-offer-modal"
      >
        <span slot="header-title">
          {{ $t('dashboard.label.reviewTreatmentOffer') }}
        </span>
      </component>
     <review-patient-invite-tabs :template-type="templateType" />
    </div>
  </modal>
</template>

<script>
import { get } from 'lodash';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ReviewPatientInviteTabs from '@/modules/dashboard/components/patient-card/invite/ReviewPatientInviteTabs';

import { REVIEW_TREATMENT_MESSAGE_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'ReviewSendOfferMessageModal',
  components: {
    ModalHeader,
    ReviewPatientInviteTabs,
    ModalHeaderWithPrevious
  },
  data() {
    return {
      modalHeaderComponent: ModalHeaderWithPrevious,
      templateType: ''
    };
  },
  methods: {
    onBeforeOpen({ params }) {
      const isModalWithPrevious = get(params, 'isModalWithPrevious', true);

      this.templateType = get(params, 'templateType', REVIEW_TREATMENT_MESSAGE_TYPE.SEND_OFFER);

      if (!isModalWithPrevious) {
        this.modalHeaderComponent = ModalHeader;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/modals/modal-header-with-previous-shared';

.modal-header-with-previous {
  margin-bottom: 0;
}
</style>
