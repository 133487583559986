<template>
  <div class="create-treatment-actions">
    <div
      class="create-treatment-actions__item create-treatment-actions__summary summary actions-item"
    >
      <div class="actions-item__title summary__title">
        {{ $t('createTreatment.createOfferActions.total') }}
      </div>

      <div class="summary__body summary-body">
        <div class="summary-body__item summary-body-item">
          <span class="summary-body-item__title">{{
            $t('createTreatment.createOfferActions.duration')
          }}</span>
          <span class="summary-body-item__value summary-body-item__value-days">
            {{ $t('dashboard.label.durationDays', [summaryTreatmentDuration]) }}
          </span>
        </div>
        <div class="summary-body__item summary-body-item">
          <span class="summary-body-item__title">{{
            $t('createTreatment.createOfferActions.total')
          }}</span>
          <span class="summary-body-item__value summary-body-item__value-price">
            {{ currency | currencyFormat }}{{ summaryTreatmentPrice | formatNumberTwoDecimalPart }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="hasWebshopAccess"
      class="create-treatment-actions__item create-treatment-actions__action actions-item action"
    >
      <div class="actions-item__title action__title">
        {{ $t('createTreatment.createOfferActions.sellOnlineTitle') }}
      </div>
      <div class="actions-item__body-text">
        {{ $t('createTreatment.createOfferActions.sellOnlineText') }}
      </div>
      <button
        class="action__button block rounded-button-orange rounded-button-orange--filled"
        :disabled="!summaryTreatmentPrice"
        @click="$emit('send-offer')"
      >
        {{ $t('createTreatment.createOfferActions.sendOffer') }}
      </button>
    </div>

    <div
      class="create-treatment-actions__item create-treatment-actions__action actions-item action"
    >
      <div class="actions-item__title action__title">
        {{ $t('createTreatment.createOfferActions.sellInOfficeTitle') }}
      </div>
      <div class="actions-item__body-text">
        {{ $t('createTreatment.createOfferActions.sellInOfficeText') }}
      </div>
      <button
        class="action__button block rounded-button-orange rounded-button-orange--with-black-text"
        :disabled="!summaryTreatmentPrice"
        @click="$emit('create-treatment')"
      >
        {{ $t('createTreatment.createOfferActions.recordTreatment') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { types as rootTypes } from '@/store/types';

export default {
  name: 'CreateTreatmentActions',
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      summaryTreatmentPrice: dashTypes.getters.SUMMARY_TREATMENT_TOTAL_PRICE,
      summaryTreatmentDuration: dashTypes.getters.SUMMARY_TREATMENT_TOTAL_DURATION,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS
    })
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/helpers/colors.scss';
@import '~@/assets/scss/common/buttons.scss';

.create-treatment-actions {
  font-family: var(--u-font-family-Gilroy);
  display: flex;
  flex-direction: column;
  padding: 12px 16px 32px 16px;
  background-color: $background;

  &__item {
    border-radius: 8px;
    padding: 20px;
    flex: 1 0 141px;
  }

  &__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.actions-item {
  background-color: white;

  &__title {
    font-size: 16px;
    line-height: 19px;
    color: $active-title-color;
  }

  &__body-text {
    font-size: 12px;
    line-height: 14px;
    color: $main-title-color;
    margin-bottom: 8px;
  }
}

.summary {
  &__title {
    margin-bottom: 35px;
  }
}

.action {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 8px;
  }

  &__button {
    margin-top: auto;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
  }
}

.summary-body-item {
  &__title {
    font-size: 14px;
    line-height: 16px;
    color: $grey-color;
    margin-bottom: 4px;
  }

  &__value {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.4px;
  }

  &__value-days {
    color: $text-color;
  }

  &__value-price {
    color: $orange-color;
  }
}

.summary-body {
  display: flex;

  &__item:first-child {
    margin-right: 32px;
  }
}

.summary-body-item {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .create-treatment-actions {
    flex-direction: row;
    padding: 18px 0 80px 0;

    &__item:not(:last-child) {
      margin-bottom: 0;
      margin-right: 16px;
    }

    &__summary {
      flex: 0 0 auto;
    }

    &__action {
      flex: 1 1 39%;
    }
  }

  .action {
    &__button {
      align-self: flex-start;
    }
  }
}
</style>
