import Ingredient from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Ingredient';

import { INGREDIENTS } from '@/modules/dashboard/api/constants';

export const EYE_CONTOUR_ACTIVE_INGREDIENT_OPTIONS = [
  new Ingredient(INGREDIENTS.FERULIC),
  new Ingredient(INGREDIENTS.SOD),
  new Ingredient(INGREDIENTS.DMAE),
  new Ingredient(INGREDIENTS.MADECA),
  new Ingredient(INGREDIENTS.ISO),
  new Ingredient(INGREDIENTS.RETINOL),
  new Ingredient(INGREDIENTS.ASCORBIC),
  new Ingredient(INGREDIENTS.AZELAIC),
  new Ingredient(INGREDIENTS.RUTIN),
  new Ingredient(INGREDIENTS.NIACINAMIDE),
  new Ingredient(INGREDIENTS.DPANTHENOL),
  new Ingredient(INGREDIENTS.ALOE),
  new Ingredient(INGREDIENTS.KOJIC),
  new Ingredient(INGREDIENTS.ARBUTIN)
];

export const ALL_ACTIVE_INGREDIENT_OPTIONS = [
  ...EYE_CONTOUR_ACTIVE_INGREDIENT_OPTIONS,
  new Ingredient(INGREDIENTS.ZINC),
  new Ingredient(INGREDIENTS.GLYCOLIC),
  new Ingredient(INGREDIENTS.PHYTIC),
  new Ingredient(INGREDIENTS.SALICYLIC),
  new Ingredient(INGREDIENTS.LACTO)
];
