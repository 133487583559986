<template>
  <section
    class="treatment-header treatment flex--space-between--center full-width pointer"
    @click="toggleSection"
  >
    <div class="flex--align-center">
      <span
        class="treatment-header__arrow-icon fas fa-chevron-down"
        :class="{ 'treatment-header__arrow-icon--opened': isOpened }"
      ></span>
      <div class="treatment-header__select flex--align-center">
        <span
          class="treatment-header__title"
          :class="{ 'treatment-header__title--selected': price }"
        >
          {{ title }}
        </span>
        <span v-if="price" class="treatment-header__price ml20">
          {{ currency | currencyFormat }}{{ price | formatNumberTwoDecimalPart }}
        </span>
      </div>
    </div>
    <div v-if="isComingSoon" class="treatment-header__coming-soon">
      {{ $t('dashboard.treatmentHeader.comingSoon') }}...
    </div>
    <div class="flex--align-center">
      <i
        v-if="!isOpened && hasRecommendation"
        class="treatment-header__recomended-products otherXS"
      >
        {{ $t('dashboard.treatment.label.seeRecommendedProducts') }}
      </i>
      <div class="icons__container flex otherXS">
        <template v-for="(icon, index) in icons">
          <div
            :key="index"
            class="treatment-header__icon custom-icon cursor-default"
            :class="`${icon.name}${icon.isActive ? '-active' : ''}`"
          ></div>
        </template>
      </div>
    </div>
    <div v-if="isOpened && hasOneActiveIngridientWarning">
      <div
        v-tooltip="{
          content: treatSectionHint,
          placement: 'right-start',
          trigger: tooltipTrigger,
          classes: 'treat-section-tooltip'
        }"
        class="icon-alert"
        @click="handleClick($event)"
      ></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'TreatmentHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    isOpened: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      default: 0
    },
    hasRecommendation: {
      type: Boolean,
      default: false
    },
    isComingSoon: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Array,
      default: () => []
    },
    hasOneActiveIngridientWarning: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY
    }),
    treatSectionHint() {
      return this.$t('dashboard.modal.sensitiveSkin.mainText', [
        this.$t('dashboard.modal.verySensitiveSkin')
      ]);
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  methods: {
    toggleSection() {
      this.$emit('section-toggle');
    },
    handleClick(event) {
      event.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/css/common/inputs';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/treatment/create-treatment-shared';
@import '../../../../../assets/css/common/tooltips.css';

.treatment-header {
  position: relative;
  padding: 0 20px;

  &__title,
  &__price {
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
    color: $grey-color;
  }

  &__arrow-icon {
    height: 11px;
    margin-right: 15px;

    font-size: 12px;
    color: $dim-grey-color;

    &--opened {
      transform: rotate(180deg);
    }
  }

  &__mobile-arrow-icon {
    margin-left: 20px;

    font-size: 12px;
    color: $dim-grey-color;
  }

  &__coming-soon {
    position: absolute;
    left: 0;

    width: 100%;

    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: $grey-color;
  }

  &__recomended-products {
    margin-right: 20px;

    font-size: 10px;
    color: $grey-color;
  }

  &__icon {
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }
}

.icons {
  &__container {
    align-items: flex-end;

    opacity: 0.5;
  }
}

@media (max-width: 767px) {
  .treatment-header {
    &__title,
    &__price {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }

    &__title {
      &--selected {
        color: $text-color;
      }
    }

    &__price {
      margin: 6px 0 0;
    }

    &__label {
      margin: 0;
    }

    &__select {
      flex-direction: column;
      align-items: flex-start;
    }

    &__arrow-icon {
      margin-right: 20px;
    }

    &__icon {
      &:not(:last-of-type) {
        margin-right: 11px;
      }
    }
  }
}
</style>
