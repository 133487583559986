import { mapGetters, mapActions } from 'vuex';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION } from '@/constants';

const saveTemplateMixin = {
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      currentMessageTemplate: types.getters.CURRENT_MESSAGE_TEMPLATE
    })
  },
  methods: {
    ...mapActions({
      updateStoreMessageTemplate: types.actions.UPDATE_STORE_MESSAGE_TEMPLATE,
      updateMarketingCampaign: types.actions.UPDATE_MARKETING_CAMPAIGN
    }),
    async updateTemplate() {
      await this.updateMarketingCampaign({
        userId: this.userId,
        templateData: this.currentMessageTemplate
      });

      this.$notify({
        group: 'dashboard-notification',
        title: this.$t('dashboard.label.templateUpdated'),
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });
    }
  }
};

export default saveTemplateMixin;
