<template>
  <div class="radio-button__container" :class="{ 'radio-button__container--disabled': disabled }">
    <input
      :id="inputId"
      v-validate="{ required: required }"
      class="radio-button"
      type="radio"
      :name="name"
      :checked="checked"
      :disabled="disabled"
      @click="checkRadioButton"
    />
    <label :for="inputId" class="radio-button-label" :class="{ 'error-message': hasError }">
      <slot name="label">{{ label }}</slot>
    </label>
  </div>
</template>

<script>
import { kebabCase } from 'lodash';

export default {
  name: 'RadioButtonWithLabel',
  inject: ['$validator'],
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    currentValue: {
      type: [String, Number, Boolean],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputId() {
      return kebabCase(`${this.name}-${this.value}`);
    },
    hasError() {
      return this.errors.has(this.name);
    },
    checked() {
      return this.value === this.currentValue;
    }
  },
  methods: {
    checkRadioButton() {
      this.$emit('input', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.radio-button {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  &__container {
    &--disabled {
      opacity: 0.5;
    }
  }
}

[type='radio']:not(:checked) + .radio-button-label,
[type='radio']:checked + .radio-button-label {
  display: inline-block;
  max-width: 100%;

  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  color: $grey-color;
  letter-spacing: 0.05em;

  padding-left: 33px;
  margin-bottom: 0;
}

.radio-button + .radio-button-label:before {
  content: '';
  border: 1px solid $light-blue;
  background-color: $white;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20px;
  height: 20px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: none;
}

.radio-button:disabled:not(:checked) + .radio-button-label:before,
.radio-button:disabled:checked + .radio-button-label:before {
  border-color: $light-blue;
  background-color: $white;
}

.radio-button + .radio-button-label:before {
  border-radius: 100%;
}

.radio-button:checked + .radio-button-label:before {
  border: 1px solid $light-blue;
  background-color: $orange-color;
  background-clip: content-box;
  box-shadow: inset 0 0 0 6px $white;
}

.radio-button:checked + .radio-button-label {
  color: $main-title-color;
}

.radio-button + .radio-button-label:after {
  display: none;
}

[type='radio']:not(:checked) + .radio-button-label.error-message,
[type='radio']:checked + .radio-button-label.error-message {
  color: #ed5158;
}

@media (max-width: 767px) {
  .radio-button-label {
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
