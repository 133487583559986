<template>
  <dropdown-select
    ref="activeIngredientDropdown"
    class="ingredients-dropdown pointer"
    :class="{ 'ingredients-dropdown--grouped': hasIngredientsWithSelection }"
    :placeholder="$t('dashboard.action.addAnIngredient')"
    :current-value="value"
    :select-values="dropdownOptions"
    :is-auto-positioned="false"
  >
    <template slot="placeholder" slot-scope="{ placeholder }">
      <div
        class="ingredient-mobile-placeholder flex--align-center"
        @click="openActiveIngredientModal"
      >
        <div class="ingredient-mobile-placeholder__icon"></div>
        <span class="ingredient-mobile-placeholder__text">{{ placeholder }}</span>
      </div>
      <ingredient-input-selector
        class="otherXSi"
        :placeholder="placeholder"
        :ingredient-options="activeIngredientOptions"
        @click.native="openActiveIngredientModal"
        @add-suggestion="addSuggestedElement"
      />
    </template>
    <template slot="picked-value" slot-scope="ingredient">
      <div
        class="flex--align-center space-between selected-ingredient__container"
        @click="openActiveIngredientModal"
      >
        <div class="flex--align-center full-width">
          <ingredient-icon
            :ingredient-color="ingredient.color"
            :number-of-capsules="ingredient.nbOfCapsules"
          />
          <span class="list-element__name">
            {{ $t(`dashboard.ingredients.${ingredient.name}`) }}
          </span>
          <concentrations-dropdown
            v-if="isAbleToChangeConcentration"
            :ingredient="ingredient"
            :options="numberOfCapsulesOptions"
            @select-number-of-capsules="selectNumberOfCapsules"
            @click.native.stop="openConcentrationDropdown(ingredient, numberOfCapsulesOptions)"
          />
          <div v-else class="list-element__concentration">
            {{ ingredient.nbOfCapsules | ingredientConcentration(ingredient.name) }}%
          </div>
        </div>
        <span
          class="list-element__icon-close custom-icon icon-close"
          @click.stop="resetActiveIngredient"
        ></span>
      </div>
    </template>
    <template slot="list-element" slot-scope="listElement">
      <ingredients-group
        v-if="hasIngredientsWithSelection"
        :group="listElement.selection"
        :grouped-ingredients="groupedIngredients"
        :disabled-ingredients="disabledIngredients"
        @show-ingredient-info="ingredientInfoModalShow"
        @select-ingredient="selectIngredient"
      />
      <ingredient-list-element
        v-else
        :ingredient="listElement"
        :disabled-ingredients="disabledIngredients"
        @show-ingredient-info="ingredientInfoModalShow"
        @click.native="selectIngredient(listElement)"
      />
    </template>
  </dropdown-select>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, groupBy } from 'lodash';

import DropdownSelect from '@/modules/dashboard/components/common/DropdownSelect';
import IngredientIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientIcon';
import ConcentrationsDropdown from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/ConcentrationsDropdown';
import IngredientListElement from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientListElement';
import IngredientsGroup from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientsGroup';
import IngredientInputSelector from '@/modules/dashboard/components/create-treatment-tab/IngredientInputSelector';

import { isMobileDevice } from '@/modules/dashboard/utils';

import {
  getHasMedicalConcentration,
  getIngredientFromArrayByName,
  getIngredientsWithSelection
} from '@/modules/dashboard/utils/create-treatment-utils';

import rootTypes from '@/store/types';
import { COUNTRY_ISO } from '@/api/places/COUNTRIES';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  TREATMENT_SECTION_NAME,
  DROPDOWN_TYPES,
  INGREDIENTS
} from '@/modules/dashboard/api/constants';

import {
  ALL_ACTIVE_INGREDIENT_OPTIONS,
  EYE_CONTOUR_ACTIVE_INGREDIENT_OPTIONS
} from '@/modules/dashboard/api/formulation';

const TWO_NUMBER_OF_CAPSULES_OPTIONS = [1, 2];
const DEFAULT_NUMBER_OF_CAPSULES_OPTIONS = [1, 2, 3];

const COUNTRY_RESTRICTED_INGREDIENTS_MAP = {
  [COUNTRY_ISO.SINGAPORE]: [INGREDIENTS.AZELAIC],
  [COUNTRY_ISO.INDIA]: [INGREDIENTS.SALICYLIC]
};

export default {
  name: 'ActiveIngredientsDropdown',
  components: {
    IngredientInputSelector,
    IngredientsGroup,
    IngredientListElement,
    IngredientIcon,
    ConcentrationsDropdown,
    DropdownSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabledIngredients: {
      type: Array,
      default: () => []
    },
    canChangeConcentration: {
      type: Boolean,
      default: true
    },
    section: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeIngredientOptions: ALL_ACTIVE_INGREDIENT_OPTIONS
    };
  },
  computed: {
    ...mapGetters({
      isMedicalConcentrationBlocked: rootTypes.getters.IS_MEDICAL_CONCENTRATION_BLOCKED,
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      treatFaceRecommendation: dashTypes.getters.TREAT_FACE_RECOMMENDATION,
      treatEyesRecommendation: dashTypes.getters.TREAT_EYES_RECOMMENDATION,
      doctor: rootTypes.getters.GET_USER
    }),
    isTreatFaceSection() {
      return this.section === TREATMENT_SECTION_NAME.TREAT_FACE;
    },
    treatmentRecommendations() {
      return this.isTreatFaceSection ? this.treatFaceRecommendation : this.treatEyesRecommendation;
    },
    activeSelection() {
      return get(this.treatmentRecommendations, 'activesSelection');
    },
    ingredientsWithSelection() {
      return this.activeSelection
        ? getIngredientsWithSelection(this.activeIngredientOptions, this.activeSelection)
        : this.activeIngredientOptions;
    },
    groupedIngredients() {
      return this.activeSelection ? groupBy(this.ingredientsWithSelection, 'selection') : {};
    },
    hasIngredientsWithSelection() {
      return this.hasTreatmentRecommendation && this.activeSelection;
    },
    activeIngredientsGroup() {
      return Object.keys(this.groupedIngredients)
        .map(group => ({ selection: group }))
        .reverse();
    },
    dropdownOptions() {
      return this.hasIngredientsWithSelection
        ? this.activeIngredientsGroup
        : this.activeIngredientOptions;
    },
    ingredientName() {
      return get(this.value, 'name');
    },
    isAbleToChangeConcentration() {
      if (!this.isMedicalConcentrationBlocked) {
        return this.canChangeConcentration;
      }

      const isTwoCapsulesMedicalConcentration = getHasMedicalConcentration(this.ingredientName, 2);

      return isTwoCapsulesMedicalConcentration ? false : this.canChangeConcentration;
    },
    numberOfCapsulesOptions() {
      if (!this.isMedicalConcentrationBlocked) {
        return DEFAULT_NUMBER_OF_CAPSULES_OPTIONS;
      }

      const isThreeCapsulesMedConcentration = getHasMedicalConcentration(this.ingredientName, 3);

      return isThreeCapsulesMedConcentration
        ? TWO_NUMBER_OF_CAPSULES_OPTIONS
        : DEFAULT_NUMBER_OF_CAPSULES_OPTIONS;
    }
  },
  mounted() {
    this.activeIngredientOptions = this.isTreatFaceSection
      ? [...ALL_ACTIVE_INGREDIENT_OPTIONS]
      : [...EYE_CONTOUR_ACTIVE_INGREDIENT_OPTIONS];

    const doctorCountry = this.doctor.country.toUpperCase();
    const restrictedIngredients = COUNTRY_RESTRICTED_INGREDIENTS_MAP[doctorCountry];

    if (!restrictedIngredients) {
      return;
    }

    this.activeIngredientOptions = this.activeIngredientOptions.filter(
      ({ name }) => !restrictedIngredients.includes(name)
    );
  },
  methods: {
    selectIngredient(ingredient) {
      if (this.isIngredientDisabled(ingredient)) {
        return;
      }

      this.$emit('update-ingredient', ingredient);
    },
    selectNumberOfCapsules(nbOfCapsules) {
      this.$emit('update-ingredient', { ...this.value, nbOfCapsules });
    },
    addSuggestedElement(suggestion) {
      const suggestedIngredient = getIngredientFromArrayByName(
        this.activeIngredientOptions,
        suggestion
      );

      this.selectIngredient(suggestedIngredient);
    },
    isIngredientDisabled({ name: ingredientName }) {
      return !!this.disabledIngredients.find(({ name }) => name === ingredientName);
    },
    async openConcentrationDropdown(ingredient, options) {
      if (!isMobileDevice()) {
        return;
      }

      const selectedOption = await this.showOptionsModalOnMobile(
        DROPDOWN_TYPES.CONCENTRATION,
        ingredient.nbOfCapsules,
        options,
        ingredient
      );

      this.selectNumberOfCapsules(selectedOption);
    },
    showOptionsModalOnMobile(type, value, options, additionalData) {
      return new Promise(resolve => {
        this.$modal.show('treatment-product-dropdown-modal', {
          type,
          value,
          options,
          optionResolver: resolve,
          additionalData
        });
      });
    },
    resetActiveIngredient() {
      this.$emit('update-ingredient', null);
    },
    ingredientInfoModalShow(ingredientName) {
      this.$modal.show('ingredient-info-modal', { sectionName: this.section, ingredientName });
    },
    async openActiveIngredientModal(event) {
      if (isMobileDevice()) {
        event.stopPropagation();

        const selectedIngredient = await this.showModalToSelectIngredientOnMobile();

        if (selectedIngredient) {
          this.selectIngredient(selectedIngredient);
        }
      }
    },
    showModalToSelectIngredientOnMobile() {
      return new Promise(resolve => {
        this.$modal.show('active-ingredients-mobile-selector-modal', {
          sectionName: this.section,
          ingredientSelectResolver: resolve,
          ingredientListOptions: this.dropdownOptions,
          hasIngredientsWithSelection: this.hasIngredientsWithSelection,
          groupedIngredients: this.groupedIngredients,
          disabledIngredients: this.disabledIngredients
        });
      });
    },
    // IE 11 lose focus after dropdown is opened. Workaround for this case
    async focusSearchInput({ target }) {
      await this.$nextTick();

      target.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/common/text';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs.css';

.list-element {
  width: 100%;
  max-width: 200px;

  &__concentration {
    margin: 1px 0 0 4px;

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    color: $grey-color;
  }

  &__icon-close {
    width: 8px;
    height: 8px;
    padding: 10px;
    background-size: 8px;
  }
}

.list-element__name {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;

  font-size: 13px;
  line-height: 15px;

  color: $text-color;

  &--disabled {
    color: $grey-color;
  }
}

.ingredient-mobile-placeholder {
  height: 28px;
  width: 100%;

  display: none;

  padding: 0 15px;

  &__icon {
    width: 20px;
    height: 20px;

    margin-right: 12px;

    background: $dim-white;
    border-radius: 3px;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;

    color: $grey-color;
  }
}

.ingredients-dropdown {
  outline: none;
}

.ingredients-dropdown /deep/ .dropdown-option {
  width: 100%;
  margin-bottom: 10px;
}

.ingredients-dropdown--grouped /deep/ .dropdown-option {
  margin-bottom: 15px;
}

.ingredients-dropdown /deep/ .select-input {
  height: 30px;
  min-height: 30px;
  padding: 0 7px 0 20px;

  background: $white;
  border: 1px solid $hint-text-color;

  box-sizing: border-box;
  border-radius: 15px;
}

.create-treatment-option {
  margin-bottom: 20px;
  text-wrap: none;
}

.manual-dropdown {
  display: block;
  padding-bottom: 0;
}

.color-block {
  width: 20px;
  height: 20px;
  background: $pale-brown-color;
  border-radius: 3px;
  margin-right: 5px;
}

/* TODO: refactor dropdown-select deep usage */
.ingredients-dropdown /deep/ .scrollable-dropdown {
  min-width: 120px;
  width: 680px;
  max-height: none;
  column-count: 3;
  column-gap: 10px;
  padding: 25px 20px 20px;

  z-index: 999;
}

/* TODO: refactor dropdown-select deep usage */
.ingredients-dropdown /deep/ .dropdown-option {
  padding: 0;

  &:hover {
    background: transparent;
  }
}

/* TODO: refactor dropdown-select deep usage */
.ingredients-dropdown--grouped /deep/ .scrollable-dropdown {
  column-count: 1;
  padding: 20px 10px 32px;
}

.ingredients-dropdown /deep/ .arrow-down-icon {
  display: none;
}

@media (max-width: 1490px) {
  .treat-face__evening-serum .ingredients-dropdown--grouped /deep/ .scrollable-dropdown,
  .treat-face__evening-serum .ingredients-dropdown /deep/ .scrollable-dropdown,
  .eye-contour__serum.justify-content--flex-end .ingredients-dropdown /deep/ .scrollable-dropdown {
    left: 140%;
    transform: translateX(-100%);
  }

  .treat-face__morning-serum .ingredients-dropdown--grouped /deep/ .scrollable-dropdown,
  .treat-face__morning-serum .ingredients-dropdown /deep/ .scrollable-dropdown,
  .eye-contour__serum.justify-content--flex-start
    .ingredients-dropdown
    /deep/
    .scrollable-dropdown {
    left: -20%;
    transform: translateX(0);
  }

  .eye-contour .ingredients-dropdown /deep/ .scrollable-dropdown,
  .ingredients-dropdown /deep/ .scrollable-dropdown {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .ingredient-mobile-placeholder {
    display: flex;
  }

  .ingredients-dropdown /deep/ .scrollable-dropdown {
    width: 450px;
    column-count: 2;
  }

  .ingredients-dropdown /deep/ .select-input {
    padding: 0;
  }

  .selected-ingredient {
    &__container {
      padding: 0 15px;
    }
  }

  .list-element {
    &__name {
      max-width: 66px;

      font-size: 12px;
      line-height: 14px;

      margin-right: 5px;
    }
  }
}

@media (max-width: 480px) {
  .ingredients-dropdown /deep/ .scrollable-dropdown {
    width: 100%;
    column-count: 1;
  }
}
</style>
