<template>
  <modal
    name="promo-actives-usage-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="280"
    height="auto"
    :click-to-close="false"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="promo-actives-modal flex-column--align-center">
        <div class="promo-actives-modal__description">
          {{ $t('dashboard.notification.priceActivePromo', [priceWithPromo]) }}
        </div>
        <button
          class="promo-actives-modal__confirm-button rounded-button-white"
          @click="onOkayClick"
        >
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { currencyFormat, formatNumberWithTwoDecimalPart } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

export default {
  name: 'PromoActivesUsageModal',
  data() {
    return {
      answerResolver: null
    };
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      treatFacePrice: dashTypes.getters.TREAT_FACE_PRICE,
      treatFacePriceWithPromo: dashTypes.getters.TREAT_FACE_PRICE_WITH_PROMO
    }),
    priceWithPromo() {
      const formattedPrice = formatNumberWithTwoDecimalPart(this.treatFacePriceWithPromo);
      const formattedCurrency = currencyFormat(this.currency);

      return `${formattedPrice}${formattedCurrency}`;
    }
  },
  methods: {
    beforeOpen({ params: { answerResolver } }) {
      this.answerResolver = answerResolver;
    },
    onOkayClick() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.YES);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('promo-actives-usage-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.promo-actives-modal {
  padding: 30px 22px;

  &__description {
    padding: 12px 15px;

    background: $beige;
    border-radius: 8px;

    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    color: $text-color;
  }

  &__confirm-button {
    margin-top: 30px;
  }
}
</style>
