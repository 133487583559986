<template>
  <modal
    name="treatment-product-dropdown-modal"
    :classes="['modal-window']"
    transition="scale"
    height="auto"
    :width="150"
    @before-open="beforeOpen"
  >
    <div class="modal-options modal-content flex-column space-between">
      <treatment-product-options
        :value="value"
        :options="options"
        @select-option="selectDropdownOption"
      >
        <template slot="option" slot-scope="{ option }">
          <template v-if="isUsageDropdownType">
            <span
              class="option__content custom-icon"
              :class="[usageIconClasses[option], { 'option__content--active': value === option }]"
            ></span>
          </template>
          <template v-else-if="isSizeDropdownType">
            <span class="option__content" :class="{ 'option__content--active': value === option }">
              {{ option }}
            </span>
          </template>
          <template v-else>
            <div
              class="option__content concentration flex--center--center"
              :class="{
                'option__content--active': value === option,
                'option__content--medical': getHasMedicalConcentration(option)
              }"
            >
              <ingredient-icon
                :ingredient-color="additionalData.color"
                :number-of-capsules="option"
              />
              <span
                class="concentration__text"
                :class="{ 'concentration__text--active': value === option }"
              >
                {{ option | ingredientConcentration(additionalData.name) }}%
              </span>
            </div>
          </template>
        </template>
      </treatment-product-options>
    </div>
  </modal>
</template>

<script>
import TreatmentProductOptions from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductOptions';
import IngredientIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/IngredientIcon';

import { getHasMedicalConcentration } from '@/modules/dashboard/utils/create-treatment-utils';

import {
  TREATMENT_USAGE_ICON_CLASSES,
  DROPDOWN_TYPES,
  UNSAVED_CHANGES_INPUTS
} from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentProductDropdownModal',
  components: { TreatmentProductOptions, IngredientIcon },
  data() {
    return {
      type: null,
      value: null,
      options: [],
      optionResolver: null,
      additionalData: null,
      usageIconClasses: TREATMENT_USAGE_ICON_CLASSES
    };
  },
  computed: {
    isUsageDropdownType() {
      return this.type === DROPDOWN_TYPES.USAGE;
    },
    isSizeDropdownType() {
      return this.type === DROPDOWN_TYPES.SIZE;
    },
    isConcentrationDropdownType() {
      return this.type === DROPDOWN_TYPES.CONCENTRATION;
    }
  },
  methods: {
    beforeOpen({ params: { type, value, options, optionResolver, additionalData } }) {
      this.type = type;
      this.value = value;
      this.options = options;
      this.optionResolver = optionResolver;
      this.additionalData = additionalData;
    },
    selectDropdownOption(value) {
      if (this.isConcentrationDropdownType) {
        this.selectConcentrationOption(value);

        return;
      }

      this.selectOption(value);
    },
    selectOption(value) {
      this.optionResolver(value);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('treatment-product-dropdown-modal');
    },
    async selectConcentrationOption(numberOfCapsules) {
      if (!this.getHasMedicalConcentration(numberOfCapsules)) {
        this.selectOption(numberOfCapsules);

        return;
      }

      const answer = await this.showModalToSetMedicalConcentration(numberOfCapsules);

      if (answer === UNSAVED_CHANGES_INPUTS.CANCEL) {
        return;
      }

      this.selectOption(numberOfCapsules);
    },
    getHasMedicalConcentration(numberOfCapsules) {
      return getHasMedicalConcentration(this.additionalData.name, numberOfCapsules);
    },
    showModalToSetMedicalConcentration(selectedConcentration) {
      return new Promise(resolve =>
        this.$modal.show('medical-concentration-modal', {
          ingredient: this.additionalData,
          concentrationResolver: resolve,
          selectedConcentration
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.option {
  &__content--medical {
    color: $medical-concentration-color;
  }
}
</style>
