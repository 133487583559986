import {
  INGREDIENT_ACTION_BLOCKSIZES,
  SKIN_DYSFUNCTIONS_COLOR
} from '@/modules/dashboard/api/constants';

export default class IngredientAction {
  constructor(name, blockSize = INGREDIENT_ACTION_BLOCKSIZES.BIG) {
    this.name = name;
    this.color = SKIN_DYSFUNCTIONS_COLOR[name];
    this.blockSize = blockSize;
  }
}
