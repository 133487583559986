var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('treatment-dropdown',{staticClass:"concentration-dropdown",scopedSlots:_vm._u([{key:"dropdown-icon",fn:function(ref){
var disableDropdownVisibility = ref.disableDropdownVisibility;
return [_c('span',{staticClass:"size-label size-label--active",attrs:{"tabindex":"-1"},on:{"blur":disableDropdownVisibility}},[_vm._v(" "+_vm._s(_vm.ingredientConcentration)+"% ")])]}}])},[_c('template',{slot:"dropdown-options"},[_c('treatment-product-options',{attrs:{"value":_vm.numberOfCapsules,"options":_vm.options},on:{"select-option":_vm.selectOption},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"option__content concentration flex--center--center",class:{
            'option__content--active': _vm.numberOfCapsules === option,
            'option__content--medical': _vm.getHasMedicalConcentration(option)
          }},[_c('ingredient-icon',{attrs:{"ingredient-color":_vm.ingredientColor,"number-of-capsules":option}}),_c('span',{staticClass:"concentration__text",class:{ 'concentration__text--active': _vm.numberOfCapsules === option }},[_vm._v(" "+_vm._s(_vm._f("ingredientConcentration")(option,_vm.ingredientName))+"% ")])],1)]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }